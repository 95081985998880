import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const AvatarMenuComponentStyled = styled.div`

    .account-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 1rem;

        .user-name {
            font-size: ${themeVariables.fontSizes.subtitle};
            font-weight: ${themeVariables.fontWeights.medium};
        }
    }

`;

export default AvatarMenuComponentStyled;

export const LinkStyled = styled.div`

    a {
        text-decoration: none;
        color: black;
    }

`
