import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useStore } from "../../hooks/store.hook";
import ButtonComponent from "../button/button.component";
import ActionToastComponentStyled from "./action-toast.component.styled";

const ActionToastComponent = observer(() => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');


    const applyAction = useCallback(
        (response: 'confirm' | 'dismiss') => {
            if (!uiStore.currentPersistentToast?.action) return;
            uiStore.currentPersistentToast.action(response);
            uiStore.dismissPersistentToast();
        },
        [uiStore]
    )


    /** define the return statement bellow */
    return (
        <ActionToastComponentStyled isVisible={uiStore.persistentToastVisible} sidebarExtended={uiStore.sideNavbarExtended}>
            <div className="toast-inner">
                <div className="toast-message">
                    { uiStore.currentPersistentToast?.message }
                </div>
                <div className="toast-actions">
                    <ButtonComponent
                        variant="text"
                        style={{marginRight: '1rem'}}
                        color="secondary"  
                        onClick={() => applyAction('dismiss')}
                        size="small"
                        >
                            Renunta
                    </ButtonComponent>

                    <ButtonComponent
                        variant="text"  
                        onClick={() => applyAction('confirm')}
                        size="small"
                        >
                            Confirma
                    </ButtonComponent>
                </div>      
            </div>
        </ActionToastComponentStyled>
    )

})

export default ActionToastComponent;