import { Button } from "@mui/material";
import React from "react";
import { NavigationItem } from "../side-navigation/side-navigation.component";
import NavbarItemComponentStyled from "./navbar-item.component.styled";

export type NavbarItemComponentPropsType = {
    isActive: boolean;
    onClick: (item: NavigationItem) => void;
    canDisplayName: boolean;
    item: NavigationItem,
    customStyle?: {
        marginLeft: string;
    }
}

const NavbarItemComponent = ({
    isActive = false,
    onClick = () => {},
    canDisplayName = false,
    item,
    customStyle
}: NavbarItemComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <NavbarItemComponentStyled isActive={isActive}>
            <Button fullWidth onClick={() => onClick(item)}>
                <div className="navbar-item">
                    <div className="navbar-item-icon" style={{marginLeft: customStyle?.marginLeft}}>
                        {item.icon}
                    </div>
                    {
                        canDisplayName ?
                        <div className="navbar-item-name">
                            {item.name}
                        </div> :
                        null
                    }
                    
                </div>       
            </Button>
        </NavbarItemComponentStyled>
    )

}

export default NavbarItemComponent;