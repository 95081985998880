import { observer } from "mobx-react-lite";
import DeliveryRoutesPageStyled from "./delivery-routes.page.styled";
import { useStore } from "../../../../hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelType } from "../../../../enums/panel-type.enum";
import DeliveryRoutePanel from "../../../../components/delivery-route-panel/delivery-route-panel.component";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { getAllDeliveryRoutes, getSalePointExportOrder, updateSalePointExportOrder } from "../../../../utils/requests";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import DraggableListComponent from "../../../../components/draggable-list/draggable-list.component";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';

const DeliveryRoutesPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllDeliveryRoutes({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateDeliveryRoute,
                component: <DeliveryRoutePanel />,
                panelWidth: '700px',
                title: `Creare rută de livrare`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'mondayDelivery',
                        label: 'Luni',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'tuesdayDelivery',
                        label: 'Marți',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'wednesdayDelivery',
                        label: 'Miercuri',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'thursdayDelivery',
                        label: 'Joi',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'fridayDelivery',
                        label: 'Vineri',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'saturdayDelivery',
                        label: 'Sâmbătă',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'sundayDelivery',
                        label: 'Duminică',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'isActive',
                        label: 'Activ',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza ruta",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditDeliveryRoute,
                            component: <DeliveryRoutePanel 
                                deliveryRouteId={row.id} 
                                deliveryRoute={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza ruta: ${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Sorteaza puncte de vanzare",
                    icon:  <FilterAltIcon />,
                    color: "black",
                    fOnClick: async (row: any) => {
                        let itemsExport = await getSalePointExportOrder({
                            deliveryRouteId: row.id
                        });

                        uiStore.openModal({
                            component: <DraggableListComponent
                                data={itemsExport}
                            />,
                            actionItems: [
                                {
                                    icon: <SaveIcon />,
                                    onClick: async () => {
                                        let newData = uiStore.sortOrderItmes.map((item, index) => ({
                                            ...item,
                                            order: index
                                        }));
                
                                        try {
                                            await updateSalePointExportOrder({
                                                salePoints: newData,
                                                deliveryRouteId: row.id
                                            });
                                            displayCustomToast("Datele s-au salvat cu succes!");
                                            uiStore.setSortOrderItems([]);
                                            uiStore.dismissModal();
                                        } catch (error: any) {
                                            displayCustomToast(`${error.content}`, true)
                                        }
                
                                    },
                                    tooltipText: "Salveaza",
                                }
                            ]
                        })
                    }

                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                mondayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.mondayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                tuesdayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.tuesdayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                wednesdayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.wednesdayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                thursdayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.thursdayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                fridayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.fridayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                saturdayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.saturdayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                sundayDelivery: (row: any) => {
                    return (
                        <>
                            {
                                row.sundayDelivery ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
            }
        },
        []
    )

    return (
        <DeliveryRoutesPageStyled>
            <PageTemplateComponent 
                title="Rute de livrare"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creează"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllDeliveryRoutes} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </DeliveryRoutesPageStyled>
    )
})

export default DeliveryRoutesPage;