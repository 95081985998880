import { observer } from "mobx-react-lite";
import { AddDoneQuantityModalStyled } from "./add-done-quantity-modal.component.styled";
import { useStore } from "../../hooks/store.hook";
import ButtonComponent from "../button/button.component";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import StockSelectComponent from "../stock-select/stock-select.component";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { addDoneQuantity, addDoneQuantityDeliveryRoutes } from "../../utils/requests";
import { themeVariables } from "../../theme/variables";

type AddDoneQuantityModalPropsType = {
    dailyProductionId: number,
    maxQuantity: number
    minusQuantity: boolean,
    isDailyProductionDelveryRoute?: boolean
}

const AddDoneQuantityModal = observer(({
    dailyProductionId,
    maxQuantity,
    minusQuantity,
    isDailyProductionDelveryRoute
}: AddDoneQuantityModalPropsType) => {

    const uiStore = useStore('uiStore');

    const [value, setValue] = useState<string>( minusQuantity ? ((-1) * maxQuantity).toString()  : maxQuantity.toString())

    const saveAction = async () => {

        if(parseInt(value) < 0 && !minusQuantity){
            displayCustomToast(`Cantitatea unui produs trebuie sa fie mai mare decât 0`, true);
            return;
        }

        if(parseInt(value) > 0 && minusQuantity){
            displayCustomToast(`Cantitatea unui produs trebuie sa fie mai mica decât 0`, true);
            return;
        }

        if(parseInt(value) === 0){
            displayCustomToast(`Cantitatea unui produs trebuie sa fie diferita de 0`, true);
            return;
        }

        if(parseInt(value) > maxQuantity && !minusQuantity){
            displayCustomToast(`Cantitatea unui produs nu poate fi mai mare decât numărul maxim care poate fi actualizat (${maxQuantity})`, true);
            return;
        }

        if(parseFloat(value) < ((-1) * maxQuantity) && minusQuantity){
            displayCustomToast(`Cantitatea unui produs nu poate fi mai mare decât numărul maxim care poate fi actualizat (${((-1) * maxQuantity)})`, true);
            return;
        }

        if(isDailyProductionDelveryRoute){
            try {
                await addDoneQuantityDeliveryRoutes({
                    dailyProductionDeliveryRouteId: dailyProductionId,
                    quantity: value
                });
                displayCustomToast("Datele au fost salvate cu succes!");
                uiStore.dismissModal();
                uiStore.setRefreshDeliveryQuantityTab(true);
            } catch (error: any) {
                displayCustomToast(error.content, true);
            }
           
        } else {
            try {
                await addDoneQuantity({
                    dailyProductionId: dailyProductionId,
                    quantity: value
                });
                
                displayCustomToast("Datele au fost salvate cu succes!");
                uiStore.dismissModal();
                uiStore.setRefreshProductionZone(true);

            } catch (error: any) {
                displayCustomToast(error.content, true);
            }
            
        }
    }

    return (
        <AddDoneQuantityModalStyled>
            <Box
                width="100%"
            >
                <Container
                    sx={{
                        padding: "0 !important"
                    }}
                >
                    <Typography
                        variant="h6"
                        fontWeight={themeVariables.fontWeights.bold}
                        marginBottom="0.5rem"
                    >
                        Adauga cantitate:
                    </Typography>
                </Container>
                <Container
                    sx={{
                        padding: "0 !important"
                    }}
                >
                    <StockSelectComponent
                        fullWidth
                        size="medium"
                        variant="outlined"
                        value={value}
                        minusQuantity={minusQuantity}
                        onTextChange={(value: string) => setValue(() => value)}
                    />
                </Container>

            </Box>

            <div className="save-button">
                <ButtonComponent 
                    onClick={saveAction}
                    sx={{
                        width: "5rem"
                    }}
                >
                    Salveaza
                </ButtonComponent>
            </div>
        </AddDoneQuantityModalStyled>
    )
})

export default AddDoneQuantityModal;