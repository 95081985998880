import { observer } from "mobx-react-lite";
import ProcessedOrdersTabStyled from "./processed-orders-tab.component.styled";
import { ProcessedOrdersTabPropsType } from "../../delivery-zone.page.types";
import { useEffect, useMemo, useState } from "react";
import { getDeliveryRoutes, getPartnerSalePoints, getPartners, getProcessedOrders } from "../../../../../../utils/requests";
import { isEmpty } from "lodash";
import { displayCustomToast } from "../../../../../../utils/display-custom-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../../../components/table/table.component";
import TooltipComponent from "../../../../../../components/tooltip/tooltip.component";
import { OrderStatusDisplay, OrderStatusEnum } from "../../../../../../enums/order-status.enum";
import { PanelType } from "../../../../../../enums/panel-type.enum";
import CreateOrderPanel from "../../../../../../components/create-order-panel/create-order-panel.component";
import { useStore } from "../../../../../../hooks/store.hook";
import { HeaderTypeEnum } from "../../../../../../enums/header-type.enum";
import { TableTypeEnum } from "../../../../../../enums/table-type.enum";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../../../components/text-field/text-field.component";
import { formatDate } from "../../../../../../utils/utils";

const ProcessedOrdersTab = observer(({
    selectedTabIndex
}: ProcessedOrdersTabPropsType) => {

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        thirdSelectFilterValue: null,
        searchText: null
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [partners, setPartners] = useState<any[]>();
    const [salePoints, setSalePoints] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const uiStore = useStore('uiStore');
    const [targetDate, setTargetDate] = useState<any>(new Date());

    useEffect(
        () => {
            getPartners()
            .then(data => {
                setPartners(() => data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
        },
        []
    )

    useEffect(
        () => {
            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error, true);
            })
        },
        []
    )

    useEffect(
        () => {
            setFilters((prev: any) => ({
                ...prev,
                secondSelectFilterValue: null
            }))
            setSalePoints(() => []);

            if(!filtersTable.selectFilterValue?.value) {
                return;
            } 

            getPartnerSalePoints(filtersTable.selectFilterValue?.value)
            .then(data => {
                setSalePoints(() => data);
                setRefreshData(() => true);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })

        },
        [filtersTable.selectFilterValue?.value]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)
                    let fetchData = await getProcessedOrders({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        partnerId: filtersTable.selectFilterValue?.value,
                        salePointId: isEmpty(filtersTable.selectFilterValue?.value?.toString())  ? null : filtersTable.secondSelectFilterValue?.value,
                        deliveryRouteId: filtersTable.thirdSelectFilterValue?.value,
                        targetDate: formatDate(targetDate)
                    });
                    setData(() => fetchData)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error?.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
            
        },
        [filtersTable, refreshData, targetDate]
    )

    useEffect(
        () => {
            if(selectedTabIndex === 1)
                setRefreshData(() => true);
        },
        [selectedTabIndex]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                       id: 'id',
                       label: 'Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'partner',
                        label: 'Partener',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'salePoint',
                        label: 'Punct de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'orderStatus',
                        label: 'Status comanda',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'orderDate',
                        label: 'Data comanda',
                        headerType: HeaderTypeEnum.Date,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'deliveryDate',
                        label: 'Data livrare',
                        headerType: HeaderTypeEnum.Date,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Vezi detalii comanda",
                    icon: <VisibilityIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItem,
                            component: <CreateOrderPanel 
                                orderId={row.id} 
                            />,
                            panelWidth: '700px',
                            title: `Vezi detalii comanda: #${row.id}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                }
            ].filter(f => f)
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                partner: (row: any) => {
                    return (
                        <>
                            {row.partner.name}
                        </>
                    )
                },
                salePoint: (row: any) => {
                    return (
                        <>
                            {row.salePoint.name}
                        </>
                    )
                },
                orderStatus: (row: any) => {
                    return (
                        <>
                            {OrderStatusDisplay[(row.orderStatus.id as OrderStatusEnum)]}
                        </>
                    )
                },
            }
        },
        []
    )

    return (
        <ProcessedOrdersTabStyled>
            <Box
                width="100%"
                marginBottom="1rem"
            >
                <DatePicker
                    value={targetDate}
                    renderInput={(params) => <TextFieldComponent 
                        {...params} 
                        fullWidth={true} 
                        variant="outlined" 
                        label="Selectează zi"
                    />}
                    onChange={newValue =>{ 
                        setTargetDate(() => newValue)
                        setRefreshData(() => true);
                    }}
                    mask="__.__.____"
                    
                />
            </Box>
            <TableComponent
                    tableKey={TableTypeEnum.ProcessedOrders} 
                    viewType="normal"
                    tableData={tableData}
                    dropdownPlaceholder="Partneri"
                    secondDropdownPlaceholder="Puncte de vanzare"
                    thirdDropdownPlaceholder="Rute de livrare"
                    dropdownOptions={partners?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    secondDropdownOptions={salePoints?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    thirdDropdownOptions={deliveryRoutes?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    // searchBarPlaceholder="Cauta dupa nume"
                    withoutSearchBar
                    withoutSelect
                    isLoading={isLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    actionItems={actionItems}
                /> 
        </ProcessedOrdersTabStyled>
    )
});

export default ProcessedOrdersTab;