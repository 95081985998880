import styled from "styled-components";
import { themeVariables } from "../../theme/variables";
import { SIDE_NAVIGATION_EXPANDED_WIDTH, SIDE_NAVIGATION_RETRACTED_WITH, TOP_NAVBAR_HEIGHT } from "../../utils/constants";

export type TopNavigationComponentStyledPropsType = {
    isExpanded: boolean;
}

const TopNavigationComponentStyled = styled.div<TopNavigationComponentStyledPropsType>`

    position: fixed;
    left: ${props => props.isExpanded ? SIDE_NAVIGATION_EXPANDED_WIDTH : SIDE_NAVIGATION_RETRACTED_WITH}rem;
    right: 0;
    top: 0;
    height: ${TOP_NAVBAR_HEIGHT}rem;
    transition: left 250ms;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 3rem;
    background-color: rgba(${themeVariables.colors.backgroundPrimaryRGB}, 0.85);
    backdrop-filter: blur(5px);
    z-index: 1;

`;

export default TopNavigationComponentStyled;