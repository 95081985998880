import { makeAutoObservable, observable } from "mobx";
import { StorageEnum } from "../enums/storage.enum";
import { UserType } from "../types/user.type";
import { getUser } from "../utils/get-user.util";
import { RootStore } from "./root.store";

export class UserStore {

    private _rootStore: RootStore;

    user: UserType | null = null;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        makeAutoObservable(this, {
            user: observable.ref
        });

        /** try to fetch the user from local storage, if any present */
        this.user = getUser();
    }

    setUser(newUserValue: UserType): void {
        this.user = newUserValue;
        localStorage.setItem(StorageEnum.UserData, JSON.stringify(this.user));
    }

}