import { observer } from "mobx-react-lite";
import ResetPasswordPartnerUsersPanelStyled from "./reset-password-partner-user-panel.component.styled";
import { StateFieldType } from "../../../../types/form.types";
import { useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { isEmpty } from "lodash";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { resetPasswordPartnerUserCommand } from "../../../../utils/requests";
import { Grid } from "@mui/material";
import TextFieldComponent from "../../../text-field/text-field.component";
import ButtonComponent from "../../../button/button.component";

type ResetPasswordPartnerUsersPanelPropsType = {
    userId?: number;
    isAdminUser?: boolean
}

export type StateType = {
    fields: {
        password: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

const ResetPasswordPartnerUsersPanel = observer(({
    userId,
    isAdminUser
}: ResetPasswordPartnerUsersPanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            password: {
                value: "",
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    });
    const uiStore = useStore('uiStore');

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            password: formGroup.fields.password.value,
            userId: userId
        }

        try {
            await resetPasswordPartnerUserCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <ResetPasswordPartnerUsersPanelStyled>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Parola noua"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.password?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.password?.isValid}
                        onTextChange={(value: any) => updateState('password', value)}
                        type="password"
                        autoComplete='new-password'
                    />
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "1rem"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>

            </Grid>
        </ResetPasswordPartnerUsersPanelStyled>
    )
})

export default ResetPasswordPartnerUsersPanel;