import { observer } from "mobx-react-lite";
import SalePointsPartnerPanelStyled from "./sale-points-partner-panel.component.styled";
import PanelTemplateComponent from "../panel-template/panel-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelType } from "../../enums/panel-type.enum";
import { useStore } from "../../hooks/store.hook";
import SalePointsPartnerPanelAction from "./sale-points-partner-panel-action/sale-points-partner-panel-action.component";
import { getAllPartnerSalePoints } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableTypeEnum } from "../../enums/table-type.enum";
import FavoriteIcon from '@mui/icons-material/Favorite';
import SelectOrderProductsComponent from "../create-order-panel/components/select-order-products/select-order-products.component";
import FavoritePartnerItemsPanel from "../favorite-partner-items-panel/favorite-partner-items-panel.component";

type SalePointsPartnerPanelPropsType = {
    partnerId?: number,
}

const SalePointsPartnerPanel = observer(({
    partnerId
}: SalePointsPartnerPanelPropsType) => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [data, setData] = useState<any>();
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    useEffect(
        () => {
            if(!refreshData) return;

            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllPartnerSalePoints({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        partnerId: partnerId
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
        },
        [refreshData, filtersTable]
    )

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateSalePoint,
                component: <SalePointsPartnerPanelAction
                    partnerId={partnerId}
                />,
                panelWidth: '700px',
                title: `Creare punct de vanzare`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'address',
                        label: 'Adresa',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'phone',
                        label: 'Numar de telefon',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'deliveryRoute',
                        label: 'Ruta de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                         id: 'isActive',
                         label: 'Activ',
                         headerType: HeaderTypeEnum.Boolean,
                         alignment: 'center',
                         sortable: false
                     }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza punct de vanzare",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditSalePoint,
                            component: <SalePointsPartnerPanelAction
                                partnerId={partnerId}
                                salePointId={row.id}
                                salePoinsDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza punctul de vanzare: ${row.name}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Produse favorite",
                    icon: <FavoriteIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.FavoriteItems,
                            title: "Produse favorite",
                            component: <FavoritePartnerItemsPanel 
                                salePointId={row.id}
                            />,
                            panelWidth: '750px',
                        })
                    }
                }
            ];
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                deliveryRoute: (row: any) => {
                    return (
                        <>
                            {row.deliveryRoute.name}
                        </>
                    )
                }
            }
        },
        []
    )

    return (
        <SalePointsPartnerPanelStyled>
            <PanelTemplateComponent 
                title="Puncte de vanzare"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Adaugă"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllPartnerSalePoints} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PanelTemplateComponent>
        </SalePointsPartnerPanelStyled>
    )
})

export default SalePointsPartnerPanel;