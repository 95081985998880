import { ToasterProps } from "react-hot-toast";

export const toastrConfig: ToasterProps = Object.freeze({
    containerClassName: "toastr-component",
    toastOptions: {
        success: {
            className: 'succes-toastr'
        },
        error: {
            className: 'error-toastr'
        }
    }
});