import { Add, Remove } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import TextFieldComponent, { TextFieldComponentProps } from "../text-field/text-field.component";

export type StockSelectComponentPropsType = TextFieldComponentProps & {
    maxWidth?: string
    minusQuantity?: boolean
};

const StockSelectComponent = ({
    value,
    maxWidth,
    minusQuantity,
    onTextChange = () => {},
    disabled,
    ...rest
}: StockSelectComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <TextFieldComponent
            variant="outlined"
            {...rest}
            disabled={disabled}
            type="number"
            value={value}
            style={{maxWidth: maxWidth ?? 'initial'}}
            onTextChange={newValue => onTextChange(newValue)}
            InputProps={{
                endAdornment: (
                    <IconButton
                        onClick={() => onTextChange((+(value as string) + 1).toString())}
                        size="small"
                        disabled={disabled}
                        tabIndex={-1}
                    >
                        <Add />                                
                    </IconButton>
                ),
                startAdornment: (
                    <IconButton
                        onClick={() => {
                            if(minusQuantity)
                                onTextChange((+(value as string) - 1).toString())
                            else 
                                onTextChange((Math.max(+(value as string) - 1, 0)).toString())
                            }}
                        size="small"
                        disabled={disabled}
                        tabIndex={-1}
                    >
                        <Remove />                                
                    </IconButton>
                ),
            }}
        />
    )

}

export default StockSelectComponent;