import React from "react"
import toast, { Toast } from "react-hot-toast"
import ToastWrapperComponent from "../components/toast-wrapper/toast-wrapper.component"

export const displayCustomToast = (data: string[] | string, isError?: boolean) => {
    
    if(isError)
        toast.error((t) => (
            <ToastWrapperComponent 
                isError 
                toastHandle={t} 
                data={data} 
            />
        ))
    else 
        toast.success((t) => (
            <ToastWrapperComponent  
                toastHandle={t} 
                data={data} />
            ))

}