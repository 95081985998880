import { createTheme } from "@mui/material";
import { themeVariables } from "./variables";
import { roRO } from '@mui/material/locale';

const muiTheme = createTheme({
    palette: {
        secondary: {
            main: '#83878d'
        },
        primary: {
            main: themeVariables.colors.primary,
        },
        error: {
            main: themeVariables.semanticColors.error
        },
        success: {
            main: themeVariables.semanticColors.success
        }
    },
    typography: {
        fontFamily: [
            '"Poppins"',
            'sans-serif'
        ].join(', ')
    }
}, roRO)

export default muiTheme;