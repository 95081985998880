import { observer } from "mobx-react-lite";
import InsideUserPanelStyled from "./inside-user-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, LinearProgress } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { themeVariables } from "../../theme/variables";
import ButtonComponent from "../button/button.component";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { getAdminUser, registerAdminUserCommand, updateAdminUserCommand } from "../../utils/requests";

type InsideUserPanelActionPropsType = {
    insideUserId?: number
}

export type StateType = {
    fields: {
        email: StateFieldType<string>;
        password: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        description: StateFieldType<string>;
        hasAdminRole: StateFieldType<boolean>;
        hasProductionRole: StateFieldType<boolean>;
        hasOrderRole: StateFieldType<boolean>;
        isActive?: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

const InsideUserPanel = observer(({
    insideUserId
}: InsideUserPanelActionPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            firstName: {
                value: "",
                isValid: false,
                noValidation: false
            },
            lastName: {
                value: "",
                isValid: false,
                noValidation: false
            },
            email: {
                value: "",
                isValid: false,
                noValidation: false
            },
            phoneNumber: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            password: {
                value: "",
                isValid: false,
                noValidation: false
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
            hasAdminRole: {
                value: false,
                isValid: true,
                noValidation: true
            },
            hasProductionRole: {
                value: false,
                isValid: true,
                noValidation: true
            },
            hasOrderRole: {
                value: false,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    });
    const uiStore = useStore('uiStore');

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false);
    
    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            if(!insideUserId) return;

            setLoadingUserInfo(() => true);

            getAdminUser(insideUserId)
            .then(data => {
                updateState('firstName', data.firstName);
                updateState('lastName', data.lastName);
                updateState('description', data.description);
                updateState('phoneNumber', data.phoneNumber);
                updateState('isActive', data.isActive);
                updateState('hasAdminRole', data.hasAdminRole);
                updateState('hasProductionRole', data.hasProductionRole);
                updateState('hasOrderRole', data.hasOrderRole);

                setFormGroup((state: any) => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        email: {
                            ...state.fields.email,
                            isValid: true,
                            value: ""
                        },
                        password: {
                            ...state.fields.password,
                            isValid: true,
                            value: ""
                        }
                    }
                }))

                setLoadingUserInfo(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingUserInfo(() => false);
            })
        },
        [insideUserId]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };
        
        try {
            if(insideUserId != undefined){
                let bodyUpdate = {
                    id: insideUserId,
                    isActive: formGroup.fields.isActive?.value,
                    firstName: formGroup.fields.firstName.value,
                    lastName: formGroup.fields.lastName.value,
                    phoneNumber: formGroup.fields.phoneNumber.value,
                    description: formGroup.fields.description.value,
                    hasAdminRole: formGroup.fields.hasAdminRole.value,
                    hasProductionRole: formGroup.fields.hasProductionRole.value,
                    hasOrderRole: formGroup.fields.hasOrderRole.value,
                }
                await updateAdminUserCommand(bodyUpdate)
            }
            else {
                let body = {
                    firstName: formGroup.fields.firstName.value,
                    lastName: formGroup.fields.lastName.value,
                    phoneNumber: formGroup.fields.phoneNumber.value,
                    description: formGroup.fields.description.value,
                    email: formGroup.fields.email.value,
                    password: formGroup.fields.password.value,
                    hasAdminRole: formGroup.fields.hasAdminRole.value,
                    hasProductionRole: formGroup.fields.hasProductionRole.value,
                    hasOrderRole: formGroup.fields.hasOrderRole.value,
                }

                await registerAdminUserCommand(body);
            }

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <InsideUserPanelStyled>
            {
                loadingUserInfo ?
                    <LinearProgress style={{borderRadius: '10px'}} />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Nume"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.firstName?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.firstName?.isValid}
                                onTextChange={(value: any) => updateState('firstName', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Prenume"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.lastName?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.lastName?.isValid}
                                onTextChange={(value: any) => updateState('lastName', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Numar de telefon"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.phoneNumber?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.phoneNumber?.isValid}
                                onTextChange={(value: any) => updateState('phoneNumber', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Descriere"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.description?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.description?.isValid}
                                onTextChange={(value: any) => updateState('description', value)}
                            />
                        </Grid>

                        {
                            !insideUserId ?
                                <Grid item xs={12}>
                                    <TextFieldComponent
                                        label="Email"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={formGroup.fields.email?.value}
                                        error={formGroup?.shouldDisplayError && !formGroup.fields.email?.isValid}
                                        onTextChange={(value: any) => updateState('email', value)}
                                        required
                                    />
                                </Grid>
                                :
                                <></>
                        }

                        {
                            !insideUserId ?
                                <Grid item xs={12}>
                                    <TextFieldComponent
                                        label="Parola"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={formGroup.fields.password?.value}
                                        error={formGroup?.shouldDisplayError && !formGroup.fields.password?.isValid}
                                        onTextChange={(value: any) => updateState('password', value)}
                                        type="password"
                                        autoComplete='new-password'
                                        required
                                    />
                                </Grid>
                                :
                                <></>
                        }

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={formGroup.fields.hasAdminRole?.value}
                                    onChange={e => updateState('hasAdminRole', e.target.checked)}
                                />}
                                label="Rol de admin"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={formGroup.fields.hasProductionRole?.value}
                                    onChange={e => updateState('hasProductionRole', e.target.checked)}
                                />}
                                label="Rol de productie"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={formGroup.fields.hasOrderRole?.value}
                                    onChange={e => updateState('hasOrderRole', e.target.checked)}
                                />}
                                label="Rol pentru comenzi"
                            />
                        </Grid>

                        {
                            !(insideUserId === undefined) ?
                                <Grid item xs={12}>
                                    <FormControl
                                        error={!formGroup.fields.isActive?.value}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formGroup.fields.isActive?.value}
                                                onChange={e => updateState('isActive', e.target.checked)}
                                            />}
                                            label="Activ"
                                        />
                                        {
                                            !formGroup.fields.isActive?.value ?
                                                <FormHelperText 
                                                    sx={{ 
                                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                                        marginLeft: 0
                                                    }}>
                                                        Utilizatorul nu o sa mai fie activ
                                                    </FormHelperText>
                                                :
                                                <></>
                                        }
                                    </FormControl>
                                </Grid>
                                :
                                <></>
                        }

                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "1rem"
                                }}
                            >
                                <ButtonComponent 
                                    onClick={createAction} 
                                    isLoading={isSaving}
                                    sx={{
                                        width: "5rem"
                                    }}
                                >
                                    Salveaza
                                </ButtonComponent>
                            </div>
                        </Grid>

                    </Grid>
            }
        </InsideUserPanelStyled>
    )
})

export default InsideUserPanel;