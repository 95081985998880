import React from "react";
import SuccessMarkComponent, { SuccessMarkComponentPropsType } from "../success-mark/success-mark.component";
import SuccessMarkContainerComponentStyled from "./success-mark-container.component.styled";

export type SuccessMarkContainerComponentPropsType = {
    message?: string;
} & SuccessMarkComponentPropsType;

const SuccessMarkContainerComponent = ({
    message = '',
    ...rest
}: SuccessMarkContainerComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <SuccessMarkContainerComponentStyled>
            <SuccessMarkComponent {...rest}/>
            <div className="message-wrapper">
                {message}
            </div>
        </SuccessMarkContainerComponentStyled>
    )

}

export default SuccessMarkContainerComponent;