import { observer } from "mobx-react-lite";
import ItemIngredientsPanelActionStyled from "./Item-ingredients-panel-action.component.styled";
import { useEffect, useState } from "react";
import { StateFieldType } from "../../../../types/form.types";
import { createItemIngredientCommand, getIngredientMeasurementUnit, getIngredients, getItemingredient, getMeasurementUnit, updateItemIngredientCommand } from "../../../../utils/requests";
import { isEmpty } from "lodash";
import { Grid, LinearProgress } from "@mui/material";
import TextFieldComponent from "../../../text-field/text-field.component";
import BasicSelect from "../../../dropdown-table-component/dropdown-table-component.component";
import ButtonComponent from "../../../button/button.component";
import toast from "react-hot-toast";
import { useStore } from "../../../../hooks/store.hook";
import { displayCustomToast } from "../../../../utils/display-custom-toast";

export type ItemIngredientsPanelActionPropsTypes = {
    itemId?: number
    itemIngredientId?: number
}

export type StateType = {
    fields: {
        quantity: StateFieldType<number>;
        ingredient: StateFieldType<any>;
        measurementUnit: StateFieldType<any>;

    };
    shouldDisplayError: boolean;
}

const ItemIngredientsPanelAction = observer(({
    itemId,
    itemIngredientId
}: ItemIngredientsPanelActionPropsTypes) => {

    const uiStore = useStore('uiStore');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [ingredients, setIngredients] = useState<any[]>([])
    const [measurementUnits, setMeasurementUnits] = useState<any[]>([])
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [loadingMU, setLoadingMU] = useState<boolean>(false);
    const [defaultValues, setDefaultValues] = useState<any>()
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            quantity: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            measurementUnit: {
                value: null,
                isValid: false,
                noValidation: false
            },
            ingredient: {
                value: null,
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    })

    useEffect(
        () => {
            if(itemIngredientId === undefined) return;

            setDataLoading(() => true)
            getItemingredient(itemIngredientId)
            .then(data => {
                
                updateState('ingredient', {
                    label: data.ingredient.name,
                    value: data.ingredient.id
                });
                setDefaultValues(() => data)
                setFormGroup(
                    (prev) => ({
                        fields: {
                            ...prev.fields,
                            quantity: {
                                ...prev.fields.quantity,
                                isValid: true,
                                value: parseFloat(data.quantity)
                            }
                        },
                        shouldDisplayError: false
                    })
                )
                setDataLoading(() => false)
            })
            .catch(e => {
                displayCustomToast(e.content, true);
                setDataLoading(() => false)
            })
        },
        []
    )

    const getData = async () => {

        try {
            setDataLoading(() => true);

            let ingredients = await getIngredients();
            setIngredients(() => ingredients)
            setDataLoading(() => false);

        } catch (error: any) {
            displayCustomToast(error.content, true);
            setDataLoading(() => false);
        }
    }

    useEffect(
        () => {
            getData()
        },
        []
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {

        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createDataAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            itemId: itemId,
            quantity: formGroup.fields.quantity.value,
            measurementUnitId: formGroup.fields.measurementUnit.value.value,
            ingredientId: formGroup.fields.ingredient.value.value,
        }

        try {
            if(itemIngredientId != undefined){
                let bodyUpdate = {
                    id: itemIngredientId,
                    ...body
                }
                await updateItemIngredientCommand(itemIngredientId, bodyUpdate)
            }
            else
                await createItemIngredientCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    useEffect(
        () => {
            if(!formGroup.fields.ingredient.value?.value) return;

            setLoadingMU(() => true);
            updateState('measurementUnit', null);
            getIngredientMeasurementUnit(formGroup.fields.ingredient.value?.value)
            .then(data => {
                setMeasurementUnits(() => data);
                setLoadingMU(() => false);
            }).catch(error => {
                displayCustomToast(error.content, true);
                setLoadingMU(() => false);
            })
        },
        [formGroup.fields.ingredient.value?.value]
    )

    useEffect(
        () => {
            if(defaultValues?.measurementUnit?.id == null) return;

            if(itemIngredientId != undefined && 
                !!measurementUnits?.find((f: any) => f.id == defaultValues?.measurementUnit?.id) &&
                formGroup.fields.ingredient.value.value === defaultValues?.ingredient?.id
            ){
                updateState('measurementUnit', {
                    label: defaultValues?.measurementUnit?.name,
                    value: defaultValues?.measurementUnit?.id
                });
            }
        },
        [measurementUnits, defaultValues]
    )

    return (
        <ItemIngredientsPanelActionStyled>
            {
                dataLoading ? (
                    <LinearProgress style={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Ingredient"
                            options={ingredients?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.ingredient?.value}
                            onValueChange={e => updateState('ingredient', e)}
                            loading={dataLoading}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.ingredient?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Unitate de masura"
                            options={measurementUnits?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            })) ?? []}
                            selectFilterValue={formGroup.fields.measurementUnit?.value}
                            onValueChange={e => updateState('measurementUnit', e)}
                            loading={loadingMU}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.measurementUnit?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Cantitate"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.quantity?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.quantity?.isValid}
                            onTextChange={(value: any) => updateState('quantity', value)}
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createDataAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>
                </Grid>
            }
        </ItemIngredientsPanelActionStyled>
    )
})

export default ItemIngredientsPanelAction;