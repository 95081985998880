import { observer } from "mobx-react-lite";
import PartnersPageStyled from "./partners.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import PartnerPanel from "../../../../components/partner-panel/partner-panel.component";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { getAllPartners } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PersonIcon from '@mui/icons-material/Person';
import SalePointsPartnerPanel from "../../../../components/sale-points-partner-panel/sale-points-partner-panel.component";
import PartnerUsersPanel from "../../../../components/partner-users-panel/partner-users-panel.component";

const PartnersPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [data, setData] = useState<any>();
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreatePartner,
                component: <PartnerPanel />,
                panelWidth: '700px',
                title: `Creare partener`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'vatNumber',
                        label: 'Numar TVA',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'regNumber',
                        label: 'Numar de inregistrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'email',
                        label: 'Email',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'phoneNumber',
                        label: 'Numar de telefon',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                         id: 'hasSpoilageOption',
                         label: 'Permite rebuturi',
                         headerType: HeaderTypeEnum.Boolean,
                         alignment: 'center',
                         sortable: false
                     },
                    {
                         id: 'isActive',
                         label: 'Activ',
                         headerType: HeaderTypeEnum.Boolean,
                         alignment: 'center',
                         sortable: false
                     }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Utilizatori partener",
                    icon: <PersonIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.PartnerUsers,
                            component: <PartnerUsersPanel
                                partnerId={row.id}
                            />,
                            panelWidth: '900px',
                            title: `${row.name}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Puncte de vanzare partener",
                    icon: <FmdGoodIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.SalePoints,
                            component: <SalePointsPartnerPanel 
                                partnerId={row.id}
                            />,
                            panelWidth: '900px',
                            title: `${row.name}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Editeaza partener",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditPartner,
                            component: <PartnerPanel 
                                partnerId={row.id}
                                partnerDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza produsul: ${row.name}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
            ];
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                hasSpoilageOption: (row: any) => {
                    return (
                        <>
                            {
                                row.hasSpoilageOption ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    useEffect(
        () => {
            if(!refreshData) return;

            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllPartners({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
        },
        [refreshData, filtersTable]
    )

    return (
        <PartnersPageStyled>
            <PageTemplateComponent 
                title="Parteneri"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllPartners} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </PartnersPageStyled>
    )
})

export default PartnersPage;