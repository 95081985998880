import { makeAutoObservable } from "mobx";
import { TableTypeEnum } from "../enums/table-type.enum";
import { RootStore } from "./root.store";
import { v4 as uuid } from 'uuid';

type DependencyTable = {
    [key in TableTypeEnum]: string | undefined;
};

const constructDependencyTable = (): DependencyTable => {
    return Object.values(TableTypeEnum).reduce((prev, current) => {
        return {
            ...prev,
            [current]: undefined
        }
    }, {} as DependencyTable);
}

export class TableStore {

    dependencyTable: DependencyTable = constructDependencyTable();
    updatedData: any[] | undefined
    oldUpdatedData: {
        [key: string] : string[]
    } = {}
    refreshTableState: boolean = false;
    filtersTable: any = undefined;
    
    private _rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        makeAutoObservable(this);
    }

    updateTable(key: TableTypeEnum) {
        this.dependencyTable[key] = uuid();
    }

    resetTable(key: TableTypeEnum) {
        this.dependencyTable[key] = undefined;
    }

    setUpdatedData(updatedData: any[]) {
        this.updatedData = updatedData
    }

    pushOldUpdatedData(updatedData: {rowId: string, stockLineId: string}){
        if(this.oldUpdatedData[updatedData.rowId] === undefined)
            this.oldUpdatedData[updatedData.rowId] = []
        this.oldUpdatedData[updatedData.rowId].push(updatedData.stockLineId)
    }

    resetOldUpdatedData(){
        this.oldUpdatedData = {}
    }

    setRefreshTableState(value: any) {
        this.refreshTableState = value;
    }

    setFiltersTable(value: any) {
        this.filtersTable = value;
    }

}