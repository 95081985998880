import { observer } from "mobx-react-lite";
import OrdersPageStyled from "./orders.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useStore } from "../../../../hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelType } from "../../../../enums/panel-type.enum";
import CreateOrderPanel from "../../../../components/create-order-panel/create-order-panel.component";
import { deleteOrderCommand, getAllOrders, getPartnerSalePoints, getPartners, cancelOrderCommand, updateOrderStatusCommand, getDeliveryRoutes, confirmOrderBulk } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { DeleteOutlined, EditOutlined, DoNotDisturbOutlined, SyncAltOutlined } from "@mui/icons-material";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { isEmpty } from "lodash";
import { OrderStatusDisplay, OrderStatusEnum } from "../../../../enums/order-status.enum";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { formatDate } from "../../../../utils/utils";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReportExcelModal from "./components/report-excel-modal/report-excel-modal.component";

const OrdersPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        thirdSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const [partners, setPartners] = useState<any[]>();
    const [salePoints, setSalePoints] = useState<any[]>([]);
    const [orderDate, setOrderDate] = useState<any>(null)
    const [deliveryDate, setDeliveryDate] = useState<any>(null)
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);

    useEffect(
        () => {
            getPartners()
            .then(data => {
                setPartners(() => data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
        },
        []
    )

    useEffect(
        () => {

            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
        },
        []
    )

    useEffect(
        () => {
            setFilters((prev: any) => ({
                ...prev,
                secondSelectFilterValue: null
            }))
            setSalePoints(() => []);

            if(!filtersTable.selectFilterValue?.value) {
                return;
            } 

            getPartnerSalePoints(filtersTable.selectFilterValue?.value)
            .then(data => {
                setSalePoints(() => data);
                setRefreshData(() => true);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })

        },
        [filtersTable.selectFilterValue?.value]
    )

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateOrder,
                component: <CreateOrderPanel />,
                panelWidth: '700px',
                title: `Creare comanda`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const confirmOrderBulkAction = useCallback(
        async () => {
            const response = await uiStore.openDialog({
                title: "Proceseaza toate comenzile noi",
                message: "Esti sigur ca vrei sa treci toate comenzile din statusul Noua in Procesata?"
            })

            if(response.value === DialogResponseTypeEnum.Confirm) {
                try{
                    setDataLoading(() => true);
                    await confirmOrderBulk();
                    displayCustomToast("Statusul comenzii a fost actualizat");
                    setDataLoading(() => false);
                    setRefreshData(() => true);
                }catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false);
                }
            }
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllOrders({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        partnerId: filtersTable.selectFilterValue?.value,
                        salePointId: isEmpty(filtersTable.selectFilterValue?.value?.toString())  ? null : filtersTable.secondSelectFilterValue?.value,
                        deliveryRouteId: filtersTable.thirdSelectFilterValue?.value,
                        orderStatusId: filtersTable.tabFilterValue,
                        orderDate: isEmpty(orderDate) ? null : formatDate(orderDate),
                        deliveryDate: isEmpty(deliveryDate) ? null : formatDate(deliveryDate)
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error?.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                       id: 'id',
                       label: 'Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'partner',
                        label: 'Partener',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'salePoint',
                        label: 'Punct de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'orderStatus',
                        label: 'Status comanda',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'orderDate',
                        label: 'Data comanda',
                        headerType: HeaderTypeEnum.Date,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'deliveryDate',
                        label: 'Data livrare',
                        headerType: HeaderTypeEnum.Date,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'hasSpoilageQuantity',
                        label: 'Are rebuturi',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza comanda",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItem,
                            component: <CreateOrderPanel 
                                orderId={row.id} 
                            />,
                            panelWidth: '700px',
                            title: `Editeaza comanda: #${row.id}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                actionItemsClick?.nextOrderStatus?.id === 0 ? undefined : {
                    text: actionItemsClick?.nextOrderStatus?.name,
                    icon: <SyncAltOutlined />,
                    color: "black",
                    async fOnClick(row: any){
                        if(row){
                            const response = await uiStore.openDialog({
                                title: "Schimba status comanda...",
                                message: "Esti sigur ca vrei sa schimbi statusul comenzii?"
                            })

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try{
                                    setDataLoading(() => true);
                                    await updateOrderStatusCommand({
                                        orderId: row.id,
                                        orderStatusId: row.nextOrderStatus.id
                                    });
                                    displayCustomToast("Statusul comenzii a fost actualizat");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false);
                                }
                            }
                        }
                    }
                },
                actionItemsClick?.orderStatus?.id === OrderStatusEnum.Cancelled ? undefined : {
                    text: "Anuleaza comanda",
                    icon: <DoNotDisturbOutlined />,
                    color: "green",
                    async fOnClick(row: any){
                        if(row){
                            const response = await uiStore.openDialog({
                                title: "Anuleaza comanda...",
                                message: "Esti sigur ca vrei sa anulezi comanda?"
                            })

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try{
                                    setDataLoading(() => true);
                                    await cancelOrderCommand({
                                        orderId: row.id
                                    });
                                    displayCustomToast("Comanda a fost anulata");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false);
                                }
                            }
                        }
                    }
                },
                {
                    text: "Sterge comanda",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi comanda...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteOrderCommand({
                                        orderId: row.id
                                    });
                                    displayCustomToast("Comanda a fost stearsa");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }
                                
                        } 
                    }
                }
            ].filter(f => f)
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                partner: (row: any) => {
                    return (
                        <>
                            {row.partner.name}
                        </>
                    )
                },
                salePoint: (row: any) => {
                    return (
                        <>
                            {row.salePoint.name}
                        </>
                    )
                },
                orderStatus: (row: any) => {
                    return (
                        <>
                            {OrderStatusDisplay[(row.orderStatus.id as OrderStatusEnum)]}
                        </>
                    )
                },
                hasSpoilageQuantity: (row: any) => {
                    return (
                        <>
                            {
                                row.hasSpoilageQuantity ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    var tabsOptions = useMemo(
        () => {
            return [
                {
                    label: "Toate",
                    value: null,
                },
                {
                    label: OrderStatusDisplay[OrderStatusEnum.New],
                    value: OrderStatusEnum.New,
                },
                {
                    label: OrderStatusDisplay[OrderStatusEnum.Confirmed],
                    value: OrderStatusEnum.Confirmed,
                },
                {
                    label: OrderStatusDisplay[OrderStatusEnum.Processed],
                    value: OrderStatusEnum.Processed,
                },
                {
                    label: OrderStatusDisplay[OrderStatusEnum.Submitted],
                    value: OrderStatusEnum.Submitted
                },
                {
                    label: OrderStatusDisplay[OrderStatusEnum.Cancelled],
                    value: OrderStatusEnum.Cancelled
                },
            ]
        },
        []
    )

    const downloadRaport = async () => {

        uiStore.openModal({
            title: "Descarcă raport",
            component: <ReportExcelModal />
        })
    }

    return (
        <OrdersPageStyled>
            <PageTemplateComponent 
                title="Comenzi"
                buttons={[
                    {
                        fOnClick: () => {
                            downloadRaport()
                        },
                        text: "Raport livrare"
                    },
                    {
                        fOnClick: () => {
                            confirmOrderBulkAction()
                        },
                        text: "Confirmare comenzi",
                    },
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creează"
                    }
                ]}
            >
                <div
                    style={{
                        display: "flex",
                        marginBottom: "1rem"
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            padding: "0.5rem"
                        }}
                    >
                        <DatePicker
                            value={orderDate}
                            renderInput={(params) => <TextFieldComponent 
                                {...params} 
                                fullWidth={true} 
                                variant="outlined" 
                                label="Data comanda"
                            />}
                            onChange={newValue =>{ 
                                setOrderDate(() => newValue)
                                setRefreshData(() => true);
                            }}
                            mask="__.__.____"
                            
                        />
                    </div>
                    
                    <div
                        style={{
                            width: "100%",
                            padding: "0.5rem"
                        }}
                    >
                        <DatePicker
                            value={deliveryDate}
                            renderInput={(params) => <TextFieldComponent 
                                {...params} 
                                fullWidth={true} 
                                variant="outlined" 
                                label="Data livrare" 
                            />}
                            onChange={newValue => {
                                setDeliveryDate(() => newValue)
                                setRefreshData(() => true);
                            }}
                            mask="__.__.____"
                        />
                    </div>
                </div>

                <TableComponent
                    tableKey={TableTypeEnum.AllOrders} 
                    viewType="normal"
                    tableData={tableData}
                    dropdownPlaceholder="Partneri"
                    secondDropdownPlaceholder="Puncte de vanzare"
                    thirdDropdownPlaceholder="Rute de livrare"
                    dropdownOptions={partners?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    secondDropdownOptions={salePoints?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    thirdDropdownOptions={deliveryRoutes?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    // searchBarPlaceholder="Cauta dupa nume"
                    withoutSearchBar
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    actionItems={actionItems}
                    tabs={tabsOptions}
                />  
            </PageTemplateComponent>
        </OrdersPageStyled>
    )
})

export default OrdersPage;