import React, { useCallback, useEffect } from "react";
import SuccessMarkComponentStyled from "./success-mark.component.styled";

export type SuccessMarkComponentPropsType = {
    onAnimationEnd: () => void,
    animationDuration?: number
}

const debounceFactory = () => {
    let timeoutId: any = 0;
    return (animationDuration: number, onAnimationEnd: () => void) => {
        if (timeoutId > 0) {
            clearTimeout(timeoutId);
            timeoutId = 0;
        }

        timeoutId = setTimeout(() => {
            onAnimationEnd();
        }, animationDuration);
    }
}

const SuccessMarkComponent = ({
    onAnimationEnd = () => {},
    animationDuration = 2000
}: SuccessMarkComponentPropsType) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounce = useCallback(
        debounceFactory(),
        []
    )

    useEffect(
        () => {
            debounce(animationDuration, onAnimationEnd);
        },
        [onAnimationEnd, animationDuration, debounce]
    )

    /** define the return statement bellow */
    return (
        <SuccessMarkComponentStyled>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg> 
        </SuccessMarkComponentStyled>
    )

}

export default SuccessMarkComponent;