import styled from "styled-components";

const SuccessMarkContainerComponentStyled = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .message-wrapper {
        margin-top: 1.5rem;
        color: gray;
    }

`;

export default SuccessMarkContainerComponentStyled;