import { observer } from "mobx-react-lite";
import ItemIngredientsPanelStyled from "./Item-ingredients-panel.styled";
import PanelTemplateComponent from "../panel-template/panel-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelType } from "../../enums/panel-type.enum";
import { useStore } from "../../hooks/store.hook";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { EditOutlined } from "@mui/icons-material";
import ItemIngredientsPanelAction from "./components/Item-ingredients-panel-action/Item-ingredients-panel-action.component";
import { StateFieldType } from "../../types/form.types";
import { getAllItemingredients } from "../../utils/requests";
import { HeaderTypeEnum } from "../../enums/header-type.enum";

type ItemIgredientPanelPropsType = {
    itemId: number
}

const ItemIngredientPanel = observer(({
    itemId
}: ItemIgredientPanelPropsType) => {

    const uiStore = useStore('uiStore');
    const [data, setData] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    

    useEffect(
        () => {
            setDataLoading(() => true);
        },
        []
    )

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateItemIngredients,
                component: <ItemIngredientsPanelAction itemId={itemId} />,
                panelWidth: '700px',
                title: `Adăugare produs`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza ingredientul produsului",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItemIngredients,
                            component: <ItemIngredientsPanelAction 
                                itemId={itemId} 
                                itemIngredientId={row.id}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza ingredientul produsului: ${row.ingredient.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
            ]
        },
        [actionItemsClick]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'quantity',
                        label: 'Cantitate',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'ingredient',
                        label: 'Ingredient',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'measurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllItemingredients({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        itemId: itemId
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error) {
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                ingredient: (row: any) => {
                    return (
                        <>
                            {row.ingredient.name}
                        </>
                    )
                },
                measurementUnit: (row: any) => {
                    return (
                        <>
                            {row.measurementUnit.name}
                        </>
                    )
                },
            }
        },
        []
    )

    return (
        <ItemIngredientsPanelStyled>
            <PanelTemplateComponent 
                title="Ingrediente produs"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Adaugă"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllItemIngredients} 
                    viewType="panel"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />
            </PanelTemplateComponent>
        </ItemIngredientsPanelStyled>
    )
})

export default ItemIngredientPanel;