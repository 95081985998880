import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import TextFieldComponent from '../text-field/text-field.component';
import * as React from 'react';

interface SearchAppBarPropsType  { 
    placeholder: string;
    searchText: string; 
    onValueChange: (newValue: string) => void, 
    delayText?: number
} 

const debounceFactory = () => {
    let timeoutId: any = 0;
    return (delayText: number, onChangeText: (newValue: string) => void, text : string) => {
        if (timeoutId > 0) {
            clearTimeout(timeoutId);
            timeoutId = 0;
        }

        timeoutId = setTimeout(() => {
            onChangeText(text);
        }, delayText);
    }
}


const SearchAppBar = ({
    placeholder, 
    searchText, 
    onValueChange, 
    delayText = 300
}:SearchAppBarPropsType) => {

    const [valueOnChnage, setValueOnChange] = React.useState<string>(searchText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounce = React.useCallback(
        debounceFactory(),
        []
    )

    const onTextFieldChange = React.useCallback(
        (e: string) => {
            setValueOnChange(e)
            debounce(delayText, onValueChange, e);
        },
        [debounce, delayText, onValueChange]
    )

    return (   
        <Box 
            sx={{
                display: "flex",
                flex: 2, 
            }}
            component="form" noValidate autoComplete="off"
        >
                <TextFieldComponent
                    fullWidth
                    id="outlined-start-adornment"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment> ,
                    }}
                    placeholder={placeholder}
                    sx={{ 
                        display: "flex",
                        flexBasis: "100%", 
                    }}
                    value = {valueOnChnage}
                    onTextChange={onTextFieldChange}
                />
        </Box>
  );
}

export default SearchAppBar;