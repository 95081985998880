import { observer } from "mobx-react-lite";
import FavoritePartnerItemsPanelStyled from "./favorite-partner-items-panel.styled";
import { useStore } from "../../hooks/store.hook";
import { useEffect, useMemo, useState } from "react";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { addSalePointFavoriteItems, deleteFavoriteItem, getFavoriteItems } from "../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PanelTemplateComponent from "../panel-template/panel-template.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { PanelType } from "../../enums/panel-type.enum";
import SelectOrderProductsComponent from "../create-order-panel/components/select-order-products/select-order-products.component";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";

type FavoritePartnerItemsPropsType = {
    salePointId: number
}

const FavoritePartnerItemsPanel = observer(({
    salePointId
}: FavoritePartnerItemsPropsType) => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        searchText: null
    });
    const [data, setData] = useState<any>();
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const [newItems, setNewItems] = useState<any[]>([]);
    const [addNewItems, setAddNewItems] = useState<boolean>(false);

    useEffect(
        () => {
            if(!refreshData) return;

            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getFavoriteItems({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        salePointId: salePointId
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
        },
        [refreshData, filtersTable, salePointId]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'itemName',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Elimina produsul",
                    icon: <CloseIcon color="error"  />,
                    color: "red",
                    fOnClick: async (row: any) => {
                        const response = await uiStore.openDialog({
                            title: "Ștergi produsul din listă",
                            message: "Ești sigur că vrei să ștergi produsul din listă?"
                        })
            
                        if(response.value === DialogResponseTypeEnum.Confirm) {
                            try{
                                setDataLoading(() => true);
                                await deleteFavoriteItem({
                                    salePointFavoriteItemId: row.salePointFavoriteItemId
                                });
                                displayCustomToast("Produs eliminat cu succes!");
                                setDataLoading(() => false);
                                setRefreshData(() => true);
                            }catch (error: any) {
                                displayCustomToast(error.content, true);
                                setDataLoading(() => false);
                            }
                        }
                    },
                }
            ]
        },
        [actionItemsClick]
    );

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
            }
        },
        []
    );

    const addFavoriteItems = () => {
        uiStore.openPanel({
            key: PanelType.AddProductOrder,
            title: "Selecteaza produsele",
            panelWidth: '800px',
            component: <SelectOrderProductsComponent
                selectedItems={data.data.map((m: any) => ({
                    id: m.itemId
                }))}
                setSelectedItems={setNewItems}
            />,
            onDismiss: (data) => {
                if (!data) return;

                if(data.dismissed) {
                    setAddNewItems(() => true);
                }
            }
        })
    }

    useEffect(
        () => {
            if(newItems.length <= 0 || !addNewItems) {
                setAddNewItems(() => false);
                return;
            };

            addSalePointFavoriteItems({
                salePointId: salePointId,
                itemIds: newItems.map(m => m.id)
            })
            .then(() => {
                displayCustomToast("Datele au fost salvate cu succes!");
                setAddNewItems(() => false);
                setNewItems(() => []);
                setRefreshData(() => true);
            })
            .catch(error => {
                displayCustomToast(error, true);
                setAddNewItems(() => false);
                setNewItems(() => []);
            })
        },
        [newItems, addNewItems, salePointId]
    )

    return (
        <FavoritePartnerItemsPanelStyled>
            <PanelTemplateComponent 
                title="Produse favorite"
                buttons={[
                    {
                        fOnClick: () => {
                            addFavoriteItems()
                        },
                        text: "Adaugă"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.FavoritePartnerItems} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PanelTemplateComponent>
        </FavoritePartnerItemsPanelStyled>
    )
});

export default FavoritePartnerItemsPanel;