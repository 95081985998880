import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, Divider, IconButton, Paper } from "@mui/material";
import React, { useCallback, useState } from "react";
import FormSectionComponentStyled from "./form-section.component.styled";

export type FormSectionComponentPropsType = {
    title: string;
    children: React.ReactNode;
    variant?: 'normal' | 'panel';
    closedByDefault?: boolean
}

const FormSectionComponent = ({
    title,
    children,
    variant = 'normal',
    closedByDefault = false
}: FormSectionComponentPropsType) => {

    const [appearState, setAppearState] = useState<boolean>(!closedByDefault);

    const onIconButtonClick = useCallback(
        () => {
            if (variant === 'panel')
                setAppearState(current => !current);
        },
        [variant]
    )

    /** define the return statement bellow */
    return (
        <FormSectionComponentStyled variant={variant}>
            <Paper className="form-section-paper">
                <div className="section-header">
                    <div className="section-title" onClick={onIconButtonClick}>
                        <div className="title">
                            {title}
                        </div>
                        {
                            variant === 'panel' ?
                                <IconButton>
                                    {
                                        appearState ?
                                            <KeyboardArrowDown /> :
                                            <KeyboardArrowUp />
                                        }
                                </IconButton> :
                                ''
                        }
                    </div>

                    { variant === 'panel' ? <Divider /> : '' }
                </div>

                {
                    variant === 'panel' ?
                        <Collapse in={appearState}>
                            <div className="section-body">
                                {children}
                            </div>
                        </Collapse> :
                        <div className="section-body">
                            {children}
                        </div>
                }      
            </Paper>   
        </FormSectionComponentStyled>
    )

}

export default FormSectionComponent;