import { observer } from "mobx-react-lite";
import ReportExcelModalStyled from "./report-excel-modal.component.styled";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useEffect, useMemo, useRef, useState } from "react";
import { getDeliveryRoutes, getExcelReportData } from "../../../../../../utils/requests";
import { displayCustomToast } from "../../../../../../utils/display-custom-toast";
import { Box, LinearProgress } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../../../components/text-field/text-field.component";
import BasicSelect from "../../../../../../components/dropdown-table-component/dropdown-table-component.component";
import TableComponent from "../../../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../../../enums/table-type.enum";
import ButtonComponent from "../../../../../../components/button/button.component";
import PanelTemplateComponent from "../../../../../../components/panel-template/panel-template.component";
import { formatDate } from "../../../../../../utils/utils";
import { HeaderTypeEnum } from "../../../../../../enums/header-type.enum";

const ReportExcelModal = observer(() => {

    const [loading, setLoading] = useState<boolean>(false);

    const [targetDate, setTargetDate] = useState<any>(new Date());
    const [selectedDeliveryRoute, setSelectedDeliveryRoute] = useState<any>(null);

    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any>();
    const tableRef = useRef(null);
    
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Comenzi livrare',
        sheet: 'Comenzi livrare',
    })

    useEffect(
        () => {
            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
        },
        []
    )

    useEffect(
        () => {
            if(!selectedDeliveryRoute?.value && !targetDate) return;

            getExcelReportData({
                deliveryRouteId: selectedDeliveryRoute?.value,
                deliveryDate: formatDate(targetDate)
            })
            .then(data => {
                if(!data) {

                    setTableData(() => ({
                        data: [],
                        headers: []
                    }));

                    return; 
                } 
    
                setTableData(() => ({
                    data: data?.data ?? [],
                    headers: data?.headers?.map((header: any) => ({
                        id: header.value,
                        label: header.label,
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                    })),
                    totalElements: data?.data?.length ?? 0
                }))
            })
        },
        [selectedDeliveryRoute?.value, targetDate]
    )

    const downloadRaport = async () => {
        onDownload();
    }

    return (
        <ReportExcelModalStyled>
            <PanelTemplateComponent title="">
                {
                    loading ? 
                        <LinearProgress style={{borderRadius: '10px'}} />
                        : 
                        <>
                            <Box
                                width="100%"
                                marginBottom="0.5rem"
                            >
                                <DatePicker
                                    value={targetDate}
                                    renderInput={(params) => <TextFieldComponent 
                                        {...params} 
                                        fullWidth={true} 
                                        variant="outlined" 
                                        label="Selectează zi"
                                    />}
                                    onChange={newValue =>{ 
                                        setTargetDate(() => newValue)
                                    }}
                                    mask="__.__.____"
                                    
                                />
                            </Box>
                            <Box
                                width="100%"
                                marginBottom="2rem"
                            >
                                <BasicSelect
                                    placeholder='Rută de livrare'
                                    options={deliveryRoutes?.map(p => ({
                                        label: p.name,
                                        value: p.id
                                    }))}
                                    selectFilterValue={selectedDeliveryRoute ?? null}
                                    onValueChange={setSelectedDeliveryRoute}
                                    customStyle={{
                                        marginRight: "0"
                                    }}
                                />
                            </Box>

                            <ButtonComponent
                                onClick={downloadRaport}
                                disabled={!!(tableData?.data?.length === 0)}
                            >
                                Descarcă Raport
                            </ButtonComponent>
                            <div
                                style={{
                                    display:"none"
                                }}
                            >
                                <TableComponent
                                    viewType="normal"
                                    tableData={tableData}
                                    withoutSelect
                                    tableRef={tableRef}
                                    tableKey={TableTypeEnum.ReportExcel}
                                    tableState={{
                                        pageIndex: 0,
                                        pageSize: tableData?.data.length ?? 0,
                                    }}      
                                />
                            </div>
                        </>
                }
            </PanelTemplateComponent>
        </ReportExcelModalStyled>
    )
});

export default ReportExcelModal;