import styled from "styled-components";
import { themeVariables } from "../../../../theme/variables";
import { PROFILE_AVATAR_SIZE } from "../../../../utils/constants";

const ProfilePageStyled = styled.div`

    .profile-pannel {
        border-radius: 20px;
        padding: 0;

        &.inner-panel {
            padding: 1.5rem 3rem;
        }

        box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    }

    .banner-top {
        width: 100%;
        height: 20vh;
        position: relative;
        border-radius: 20px 20px 0 0;

        .background-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 20px 20px 0 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .user-section {
            position: absolute;
            left: 1.5rem;
            display: flex;
            align-items: center;
            bottom: 0;
            transform: translateY(20%);

            .user-description {
                margin-left: 1rem;      
                display: flex;
                align-items: center;

                .user-name {
                    color: #fff; 
                    font-weight: ${themeVariables.fontWeights.bold};
                    font-size: ${themeVariables.fontSizes.medium};
                }

                .user-account-status {
                    margin-left: 1rem;
                    font-weight: ${themeVariables.fontWeights.semibold}
                }
            }

        }

    }

    .banner-bottom {
        margin-top: 0;
        position: relative;
        margin-left: calc(${PROFILE_AVATAR_SIZE}rem + 1rem + 1.5rem);
        /* display: flex; */

        .toggle-status-button {
            position: absolute;
            right: 3rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .tab-panel-wrapper {
        margin-top: 3rem;
    }

`;

export default ProfilePageStyled;