import React, { useCallback, useState } from "react";
import TabComponentStyled from "./tab.component.styled";
import { Tab, Tabs } from "@mui/material";
import { RoleEnum } from "../../enums/role.enum";
import { getUser } from "../../utils/get-user.util";

export type TabComponentPropsType = {
    tabs: TabElementType[];
    panelProps?: any;
    noPanels?: boolean;
    onTabChange?: (newTab: number) => void;
    tabIndex?: number;
}

export type TabElementType = {
    headerTab: {
        label: string;
        icon?: React.ReactNode;
    },
    roles?: RoleEnum[];
    panel?: (props?: any) => React.ReactNode;
}

const TabComponent = ({
    tabs = [],
    panelProps,
    noPanels = false,
    onTabChange = () => {},
    tabIndex
}: TabComponentPropsType) => {

    const [stateSelectedTab, setStateSelectedTab] = useState(0);
    
    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            if(!tabIndex)
                setStateSelectedTab(() => newValue);
            onTabChange(newValue);
        },
        [onTabChange, tabIndex]
    )

    const getAuthorizedTabs = useCallback(
        (): TabElementType[] => {
            const user = getUser();
            if (!user) return [];

            return tabs.filter((tab) => (tab.roles ?? []).every(role => user?.roles?.includes(role)) );
        },
        [tabs]
    )

    /** define the render logic bellow */
    return (
        <TabComponentStyled>
            <Tabs
                onChange={handleChange}
                value={tabIndex ?? stateSelectedTab}
                aria-label="profile-tabs"
            >
                {
                    getAuthorizedTabs().map(({ headerTab }, index) => (
                        <Tab 
                            key={index}
                            label={
                                <div className="tab-component">
                                    { 
                                        headerTab.icon ?
                                        <div className="tab-image">
                                            {headerTab.icon}
                                        </div> : null 
                                    }
                                    <span>{headerTab.label}</span>
                                </div>
                            } 
                            id={`profile-tab-${index}`} 
                            aria-controls={`simple-tabpanel-${index}`}
                            disableRipple
                        />
                    ))
                }
            </Tabs>

            {
                noPanels ?
                    '' :
                    <div className="panels-wrapper">
                        {
                            getAuthorizedTabs().map(({ panel }, index) => (
                                <div
                                    key={index} 
                                    className="panel-wrapper-item" 
                                    hidden={index !== stateSelectedTab}
                                >
                                    {panel?.(panelProps)}
                                </div>
                            ))
                        }
                    </div>
            }
           

                
        </TabComponentStyled>
    )

}

export default TabComponent;