import { deleteRequest, formDataPostRequest, getRequest, postRequest, putRequest } from "./http-requests"
import { ContentTypeEnum } from "../enums/content-type.enum";
import { DictionaryTypeEnum } from "../enums/dictionary-type-enum";
import axios from "axios";
import envVars from "../config/env.config";
import { StorageEnum } from "../enums/storage.enum";

export type BaseDto<T> = {
    id: T;
};

export type DictionaryDto = {
    name: string;
    tag?: string;
    isActive?: boolean;
    isDefault?: boolean;
} & BaseDto<number>;

export type PhoneNumberDto = {
    prefixCode: string;
    content: string;
    prefixId: number;
}

export type TemplateQuestionDto = {
    question: string;
    tag: string;
    order: number;
    isStandard: boolean;
    saveTamplate?: boolean;
    languageTag?: string
} & BaseDto<number>;

export type AddJobQuestionDto = {
    jobId?: number;
    question: string;
    saveTemplate: boolean;
    tag?: string;
    languageTag?: string;
}

export type CountyDto = DictionaryDto & { countryId: number; }

// ----------------------------- GRIGOTEC -----------------------------------------

export const createCategoryCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Category`,
        body: body,
    });
}

export const updateCategoryCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Category/${id}`,
        body: body,
    });
}

export const createIngredientCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Ingredients`,
        body: body,
    });
}

export const updateIngredientCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Ingredients/${id}`,
        body: body,
    });
}

export const getAllCategories = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Category/GetListCategories`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAllIngredients = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Ingredients/GetListIngredients`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createItemCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Items`,
        body: body,
    });
}

export const getAllItems = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Items/GetListItems`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAllDeliveryRoutes = async (filters: any) => {
    const { data } = await getRequest({
        url: `/DeliveryRoutes/GetListDeliveryRoute`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createDeliveryRouteCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/DeliveryRoutes`,
        body: body,
    });
}

export const updateDeliveryRouteCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/DeliveryRoutes/${id}`,
        body: body,
    });
}

export async function getMeasurementUnitType(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.MeasurementUnitTypes);
    
    return data;
}

export async function getMeasurementUnit(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.MeasurementUnits, { idConditional });
    
    return data;
}

export async function getCategories(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Categories);
    
    return data;
}

export async function getIngredients(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Ingredients);
    
    return data;
}

export async function getOrderPartners(): Promise<any[]> {
    
    const { data } = await getRequest({
        url: `/Partner/GetNewOrderPartners`,
    })

    return data;
}

export async function getOrderPartnerSalePoints(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.AdminNewOrderSalePoints, { idConditional });
    
    return data;
}

export async function getIngredientMeasurementUnit(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.IngredientMeasurementUnit, { idConditional });
    
    return data;
}

export const getItem = async (itemId: any) => {
    const { data } = await getRequest({
        url: `/Items/GetItem`,
        queryParams: {
            id: itemId
        }
    })

    return data;
}

export const updateItemCommand = async (id: any, body: any): Promise<void> => {
    await putRequest({
        url: `/Items/${id}`,
        body: body,
    });
}


export const createItemIngredientCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ItemIngredients`,
        body: body,
    });
}

export const getAllItemingredients = async (filters: any) => {
    const { data } = await getRequest({
        url: `/ItemIngredients/GetListItemIngredients`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getItemingredient = async (itemIngredientId: any) => {
    const { data } = await getRequest({
        url: `/ItemIngredients/GetItemIngredient`,
        queryParams: {
            id: itemIngredientId
        }
    })

    return data;
}

export const updateItemIngredientCommand = async (id: any, body: any): Promise<void> => {
    await putRequest({
        url: `/ItemIngredients/${id}`,
        body: body,
    });
}

export async function  saveImageItem(formData: any): Promise<any> {
    const token = window.localStorage.getItem(StorageEnum.Token);

    try {
        const { data } =  await axios.post(
            `${envVars.apiBaseUrl}/Items/UpdateItemImage`, 
            formData, 
            {
              headers: { 
                "Content-Type": ContentTypeEnum.FormData,
                "Authorization": `Bearer ${token}`
              }
            }
        );

        return data;
    } catch (error) {
        return []
    }
}

export async function getImageItem(itemId: any): Promise<any> {
    const { data } = await getRequest({
        url: "/Items/GetItemImage",
        queryParams: {
            itemId: itemId
        }
    })

    return data;
}

export const deleteImageItem = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Items/RemoveItemImage`,
        body: body,
    });
}

export const createPartnerCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Partner`,
        body: body,
    });
}

export const updatePartnerCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Partner/${id}`,
        body: body,
    });
}

export const getAllPartners = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetAllPartners`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createPartnerSalePointCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/SalePoints`,
        body: body,
    });
}

export const updatePartnerSalePointCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/SalePoints/${id}`,
        body: body,
    });
}

export async function getDeliveryRoutes(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.DeliveryRoutes);
    
    return data;
}

export const getAllPartnerSalePoints = async (filters: any) => {
    const { data } = await getRequest({
        url: `/SalePoints/GetPartnerSalePoints`,
        queryParams: {
            ...filters
        }
    })

    return data;
}


export const getAllPartnerUsers = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetPartnerUsers`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const registerPartnerUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/RegisterPartnerUser`,
        body: body,
    });
}

export const resetPasswordPartnerUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/ResetPartnerUserPassword`,
        body: body,
    });
}

export const updatePartnerUserCommand = async (body: any): Promise<void> => {
    await putRequest({
        url: `/Partner/UpdatePartnerUser`,
        body: body,
    });
}

export const getPartnerUser = async (userId: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetPartnerUser`,
        queryParams: {
            userId: userId
        }
    })

    return data;
}

export const createOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/CreateOrderAdmin`,
        body: body,
    });
}

export const getAllOrders = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetListOrders`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getOrderDetails = async (orderId: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetOrderDetails`,
        queryParams: {
            orderId: orderId
        }
    })

    return data;
}

export const updateOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/UpdateOrderAdmin`,
        body: body,
    });
}

export const deleteOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/DeleteOrderAdmin`,
        body: body,
    });
}

export const updateOrderStatusCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/UpdateOrderStatus`,
        body: body,
    })
}

export const cancelOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/CancelOrder`,
        body: body,
    })
}

export const updateAdminUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Users/UpdateAdminUser`,
        body: body,
    });
}

export const confirmOrderBulk = async (): Promise<void> => {
    await getRequest({
        url: `/Orders/ConfirmOrderBulk`
    });
}

export async function getPartners(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Partners);
    
    return data;
}

export const getLicense = async () => {
    const { data } = await getRequest({
        url: `/Auth/GetLicense`
    })

    return data;
}

export const getCurrentDayOrdersReport = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetCurrentDayOrdersReport`,
        queryParams: {
            ...filters
        }  
    })

    return data;
}

export async function getPartnerSalePoints(idConditional: any): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.PartnerSalesPoints, { idConditional });
    
    return data;
}

export const registerAdminUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/Register`,
        body: body,
    });
}

export const getAllAdminUsers = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Users/GetAdminUsers`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAdminUser = async (userId: any) => {
    const { data } = await getRequest({
        url: `/Users/GetAdminUserDetails`,
        queryParams: {
            id: userId
        }
    })

    return data;
}

export const checkAuthorizationAdmin = async () => {
    await getRequest({
        url: `/Auth/CheckAuthorizationAdmin`
    })
}

export const getDailyProduction = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyProduction`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getItemObservations = async (itemId: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetItemObservations`,
        queryParams: {
            itemId
        }
    })

    return data;
}

export const processOrders = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/ProcessOrders`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const addDoneQuantity = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDoneQuantity`,
        body
    })
}

export const getDailyProductionDeliveryRoute = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyProductionDeliveryRoute`,
        queryParams: {
            ...filers
        }
    })

    return data;
}

export const getDailyObservationDeliveryRoute = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyObservationDeliveryRoute`,
        queryParams: {
            ...filers
        }
    })

    return data;
}


export const addDoneQuantityDeliveryRoutes = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDoneQuantityDeliveryRoutes`,
        body
    })
}

export const submittedOrders = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/SubmittedOrders`,
        queryParams: {
            ...filers
        }
    })

    return data;
}

export const getProcessedOrders = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetProcessedOrders`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const getDailyObservationsDeliveryRoutes = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyObservationsDeliveryRoutes`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const addDailyObservationDeliveryRoute = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDailyObservationDeliveryRoute`,
        body
    })
}

export async function getItems(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Items);
    
    return data;
}

export const getItemExportOrder = async () => {
    let { data } = await getRequest({
        url: `/Items/GetItemExportOrder`
    });

    return data;
}

export const updateItemExportOrder = async (body: any) => {

    await postRequest({
        url: `/Items/UpdateItemExportOrder`,
        body
    })
}

export const getSalePointExportOrder = async (filters: any) => {
    let { data } = await getRequest({
        url: `/SalePoints/GetSalePointExportOrder`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const updateSalePointExportOrder = async (body: any) => {

    await postRequest({
        url: `/SalePoints/UpdateSalePointExportOrder`,
        body
    })
}

export const getExcelReportData = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetOrdersForDelivery`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const getFavoriteItems = async (filters: any) => {
    let { data } = await getRequest({
        url: `/SalePoints/GetFavoriteItems`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const addSalePointFavoriteItems = async (body: any) => {

    await postRequest({
        url: `/SalePoints/AddSalePointFavoriteItems`,
        body
    })
}

export const deleteFavoriteItem = async (body: any) => {
    await postRequest({
        url: `/SalePoints/DeleteFavoriteItem`,
        body
    })
}

export const getLogs =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/Logs/GetLogs`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

/** make call to db to get dictionary entities specified in the params of the function */
export async function getDictionaryEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {

    const { data } = await getRequest({
        url: `/Dictionary/Get`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

/** make call to db to get enum entities specified in the params of the function */
export async function getEnumEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {
    const { data } = await getRequest({
        url: `/Dictionary/GetEnum`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

export const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };


