import { StorageEnum } from "../enums/storage.enum";
import { UserType } from "../types/user.type";

export const getUser = (): UserType | null => {

    var user = localStorage.getItem(StorageEnum.UserData);

    if (!user) return null;

    var parsedUser: UserType = JSON.parse(user);

    return parsedUser;
    
}