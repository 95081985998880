import { Navigate, useRoutes } from "react-router-dom"
import LogoutPage from "./pages/logout/logout.page";
import ForgotPasswordPage from "./pages/forgot-password/forgot-password.page";
import LoginPage from "./pages/login/login.page";

const AuthRouter = () => {
    let element = useRoutes([
        {
            element: <LoginPage />,
            path: 'login'
        },
        {
            element: <ForgotPasswordPage />,
            path: 'forgot-password',
        },
        {
            path: 'logout',
            element: <LogoutPage />
        },
        {
            element: <Navigate to='login' />,
            path: '*'
        }
    ]);

    return element;
}

export default AuthRouter;