import styled from "styled-components";

const AuthModuleStyled = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-content-wrapper {
        /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px; */
        border-radius: 20px;
        height: 70%;
        border: 1px solid lightgray;
        padding: 1rem;
        width: 55%;
        background: #fff;
        display: flex;
        overflow: hidden;

        .section {
            flex: 1;
            height: 100%;

            &.content-section {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.image-section {
                display: flex;
                align-items: center;
                justify-content: center;

                .image-section-inner {
                    width: 95%;
                    height: 95%;
                    border-radius: 20px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                }
               
            }
        }
    }
`;

export default AuthModuleStyled;