import styled, { keyframes } from "styled-components";
import { themeVariables } from "../../theme/variables";

export type PanelComponentStyledPropsType = {
    isOpen: boolean;
    panelWidth?: string;
    tabs?: boolean;
    subtitle?: boolean;
}

const enterAnimation = keyframes`
    0% { transform: translateX(200px); opacity: 0 }
    100% { transform: translateX(0), opacity: 1 }
`;

const PanelComponentStyled = styled.div<PanelComponentStyledPropsType>`

    position: fixed;
    top: 0;
    right: 0;
    left: ${props => props.isOpen ? 0 : 'unset'};
    bottom: 0;
    z-index: 3;
    background-color: ${props => props.isOpen ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
    backdrop-filter: blur(1px);
    transition: background-color 200ms;

    .panel-paper {
        position: absolute;
        right: 1rem;
        top: 1rem;
        bottom: 1rem;
        border-radius: 12px;
        background: #fff;
        width: ${props => props.isOpen ? (props.panelWidth ?? '31.25rem') : 0};
        box-shadow: rgb(145 158 171 / 16%) -24px 12px 32px -4px;
        transition: width 200ms;
        overflow-y: auto;
        overflow-x: hidden;

        .inner-content {
            display: flex;
            flex-direction: column;

            &.hide-content {
                opacity: 0;
                transition-property: opacity;
                transition-duration: 50ms;
            }

            &.show-content {
                opacity: 1;
                transition-delay: 200ms;
                transition-property: opacity;
                transition-duration: 125ms;
            }

            .tabs-wrapper {
                padding: 0 2rem;
                position: fixed;
                display: flex;
                flex-direction: row;
                margin-top: ${props => props.tabs ? '3.75rem' : '0px'};
                background-color: white;
                z-index: 4;
                width: ${props => props?.panelWidth ? `${Number(props?.panelWidth?.replace(/[^0-9]/g, ''))/16 - 4.8}rem` : '26.45rem'};
            }

            .panel-title {
                position: fixed;
                display: flex;
                flex-direction: row;
                padding: 1rem 2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                z-index: 4;
                background-color: white;
                width: ${props => props?.panelWidth ? `${Number(props?.panelWidth?.replace(/[^0-9]/g, ''))/16 - 4.8}rem` : '26.45rem'};
                border-top-left-radius: 1rem;

                .panel-title-cluster {
                    display: flex;
                    align-items: center;
                    flex: 1;
                }

                .icons-section {
                    display: flex;
                    align-items: center;

                    .props-icons {
                        margin-right: 0.5rem;
                        display: flex;
                        align-items: center;
                    
                        .props-icons-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 0.4rem;
                            margin-left: 0.4rem;
                        }
                    }
                }

                .prev-button {
                    margin-right: 0.5rem;
                    margin-left: -1rem;
                }

                .panel-title-inner {
                    position: relative;
                    width: 100%;
                
                    .subtitle {
                        position: absolute;
                        /* left: 2rem; */
                        top: 50%;
                        transform: translateY(calc(-50% - 0.5rem));
                        color: gray;
                        font-size: ${themeVariables.fontSizes.smaller};

                        &.with-subtitle {
                            transform: translateY(calc(-50% - 0.5rem));
                            opacity: 1;
                            transition: all 250ms;
                        }

                        &.without-subtitle {
                            transform: translateY(100%);
                            opacity: 0;
                            transition: all 250ms;
                        }
                    }

                    .title {
                        position: absolute;
                        /* left: 2rem; */
                        top: 50%;
                        display: flex;
                        align-items: center;
                        
                        .additional-subtitle {
                            margin-left: 1rem;
                        }

                        .title-text {
                            font-size: ${themeVariables.fontSizes.medium};
                            font-weight: ${themeVariables.fontWeights.semibold};
                        }

                        &.with-subtitle {
                            transform: translateY(calc(-50% + 0.5rem));
                            transition: all 250ms;
                        }

                        &.without-subtitle {
                            transform: translateY(calc(-50%));
                            transition: all 250ms;
                        }
                    }
                }
            }
            
            .panel-subtitle {
                padding: 1rem 2rem;
                width: 100%;
                box-sizing: border-box;
                margin-top: ${props => props.subtitle && props.tabs ? 
                                            '6.75rem'
                                            :
                                            props.subtitle || props.tabs ?
                                                '3.5rem' 
                                                : 
                                                '0'
                            };
                position: fixed;
                display: flex;
                flex-direction: row;
                z-index: 4;
                background-color: white;
                width: ${props => props?.panelWidth ? `${Number(props?.panelWidth?.replace(/[^0-9]/g, ''))/16 - 1}rem` : '29rem'};
            }

            .panel-body {
                position: relative;
                width: 100%;
                flex: 1;
                box-sizing: border-box;
                margin-top: ${props => props.subtitle && props.tabs ? 
                                            '9.2rem'
                                            :
                                            props.subtitle || props.tabs ?
                                                '6rem' 
                                                : 
                                                '3rem'
                            };
                .inner-panel {
                    position: absolute;
                    top: 1.5rem;
                    left: 2rem;
                    right: 2rem;
                    transition: all 250ms;
                    opacity: 0; 
                    transform: translateX(200px);
                    transition: all 250ms;

                    &.non-visible-panel {
                        transform: translateX(-200px);
                        opacity: 0;
                    }

                    &.pushed-panel {
                        opacity: 1;
                        animation-name: ${enterAnimation};
                        animation-duration: 250ms;
                        transform: translateX(0);
                    }

                    &.poped-panel {
                        opacity: 1;
                        transform: translateX(0);
                    }

                }
            }
            
        }
    }

`;

export default PanelComponentStyled;