import { GlobalStore } from "./global.store";
import { TableStore } from "./table.store";
import { UiStore } from "./ui.store";
import { UserStore } from "./user.store";

export class RootStore {
    uiStore: UiStore;
    userStore: UserStore;
    tableStore: TableStore;
    globalStore: GlobalStore;
    
    constructor() {
        this.uiStore = new UiStore(this);
        this.userStore = new UserStore(this);
        this.tableStore = new TableStore(this);
        this.globalStore = new GlobalStore(this);
    }
}