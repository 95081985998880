import { observer } from "mobx-react-lite";
import ItemPanelStyled from "./item-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, LinearProgress, TextareaAutosize } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { createItemCommand, getCategories, getItem, updateItemCommand } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import { useStore } from "../../hooks/store.hook";
import { themeVariables } from "../../theme/variables";
import { displayCustomToast } from "../../utils/display-custom-toast";

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        description: StateFieldType<string>;
        ingredientDescription: StateFieldType<string>;
        expirationDescription: StateFieldType<string>;
        nutritionalValuesDescription: StateFieldType<string>;
        weightDescription: StateFieldType<string>;
        priceWithoutVAT: StateFieldType<number>;
        priceWithVAT: StateFieldType<number>;
        vAT: StateFieldType<number>;
        category: StateFieldType<any>;
        isActive?: StateFieldType<any>;

    };
    shouldDisplayError: boolean;
}

type ItemPanelPropsType = {
    itemId?: number
}

const ItemPanel = observer(({
    itemId
}: ItemPanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            ingredientDescription: {
                value: "",
                isValid: true,
                noValidation: true
            },
            expirationDescription: {
                value: "",
                isValid: true,
                noValidation: true
            },
            nutritionalValuesDescription: {
                value: "",
                isValid: true,
                noValidation: true
            },
            weightDescription: {
                value: "",
                isValid: true,
                noValidation: true
            },
            priceWithoutVAT: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            priceWithVAT: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            vAT: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            category: {
                value: null,
                isValid: false,
                noValidation: false
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })
    const [categories, setCategories] = useState<any>();
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const uiStore = useStore('uiStore');

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            if(itemId === undefined) return;

            setLoadingData(() => true);

            getItem(itemId)
            .then(data => {
                updateState('category', {
                    label: data.category.name,
                    value: data.category.id
                });
                updateState('name', data.name);
                updateState('description', data.description);
                updateState('expirationDescription', data.expirationDescription);
                updateState('ingredientDescription', data.ingredientDescription);
                updateState('nutritionalValuesDescription', data.nutritionalValuesDescription);
                updateState('priceWithVAT', data.priceWithVAT);
                updateState('priceWithoutVAT', data.priceWithoutVAT);
                updateState('vAT', data.vat);
                updateState('weightDescription', data.weightDescription);
                updateState('isActive', data.isActive);
                setLoadingData(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingData(() => false);
            })
        },
        [itemId]
    )

    useEffect(
        () => {
            setLoadingData(() => true)

            getCategories()
            .then(data => {
                setCategories(() => data) 
                setLoadingData(() => false)
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingData(() => false)
            })
        },
        []
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            description: formGroup.fields.description.value,
            ingredientDescription: formGroup.fields.ingredientDescription.value,
            expirationDescription: formGroup.fields.expirationDescription.value,
            nutritionalValuesDescription: formGroup.fields.nutritionalValuesDescription.value,
            weightDescription: formGroup.fields.weightDescription.value,
            priceWithVAT: formGroup.fields.priceWithVAT.value,
            priceWithoutVAT: formGroup.fields.priceWithoutVAT.value,
            vAT: formGroup.fields.vAT.value,
            categoryId: formGroup.fields.category.value.value,
        }

        try {
            if(itemId != undefined){
                let bodyUpdate = {
                    id: itemId,
                    isActive: formGroup.fields.isActive?.value,
                    ...body
                }
                await updateItemCommand(itemId, bodyUpdate)
            }
            else
                await createItemCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <ItemPanelStyled>
            {
                loadingData ? (
                    <LinearProgress style={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Categorie *"
                            options={categories?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.category?.value}
                            onValueChange={e => updateState('category', e)}
                            loading={loadingData}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.category?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Nume"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.name?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                            onTextChange={(value: any) => updateState('name', value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="description">Descriere:</label>
                        <textarea
                            id="description"
                            className="text-area"
                            rows={3}
                            placeholder="Descriere"
                            value={formGroup.fields.description?.value ?? ""}
                            onChange={e => updateState('description', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="ingredientDescription">Ingredient descriere:</label>
                        <textarea
                            id="ingredientDescription"
                            className="text-area"
                            rows={3}
                            placeholder="Ingredient descriere"
                            value={formGroup.fields.ingredientDescription?.value ?? ""}
                            onChange={e => updateState('ingredientDescription', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="expirationDescription">Termen de valabilitate:</label>
                        <textarea
                            id="expirationDescription"
                            className="text-area"
                            rows={3}
                            placeholder="Termen de valabilitate"
                            value={formGroup.fields.expirationDescription?.value ?? ""}
                            onChange={e => updateState('expirationDescription', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="nutritionalValuesDescription">Valori nutritionale:</label>
                        <textarea
                            id="nutritionalValuesDescription"
                            className="text-area"
                            rows={3}
                            placeholder="Valori nutritionale"
                            value={formGroup.fields.nutritionalValuesDescription?.value ?? ""}
                            onChange={e => updateState('nutritionalValuesDescription', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="weightDescription">Gramaj:</label>
                        <textarea
                            id="weightDescription"
                            className="text-area"
                            rows={3}
                            placeholder="Gramaj"
                            value={formGroup.fields.weightDescription?.value ?? ""}
                            onChange={e => updateState('weightDescription', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Pret fara TVA"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.priceWithoutVAT?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.priceWithoutVAT?.isValid}
                            onTextChange={(value: any) => updateState('priceWithoutVAT', value)}
                            type="number"
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Pret cu TVA"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.priceWithVAT?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.priceWithVAT?.isValid}
                            onTextChange={(value: any) => updateState('priceWithVAT', value)}
                            type="number"
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="TVA"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.vAT?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.vAT?.isValid}
                            onTextChange={(value: any) => updateState('vAT', value)}
                            type="number"
                            required
                        />
                    </Grid>

                    {
                        !(itemId === undefined) ?
                            <Grid item xs={12}>
                                <FormControl
                                    error={!formGroup.fields.isActive?.value}
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formGroup.fields.isActive?.value}
                                            onChange={e => updateState('isActive', e.target.checked)}
                                        />}
                                        label="Activ"
                                    />
                                    {
                                        !formGroup.fields.isActive?.value ?
                                            <FormHelperText 
                                                sx={{ 
                                                    fontSize: `${themeVariables.fontSizes.normal}`,
                                                    marginLeft: 0
                                                }}>
                                                    Produsul nu o sa mai apara in lista unui partener
                                                </FormHelperText>
                                            :
                                            <></>
                                    }
                                </FormControl>
                            </Grid>
                            :
                            <></>
                    }

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>
                </Grid>
            }
        </ItemPanelStyled>
    )
})

export default ItemPanel;