import styled from "styled-components";

export const AddDoneQuantityModalStyled = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    padding: 2rem;
    box-sizing: border-box;
    position: relative;

    .save-button {
        position: absolute;
        bottom: 2rem;
        right: 2rem;        
    }
    
    .MuiOutlinedInput-input {
        text-align: center !important;
    }
`