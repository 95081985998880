export enum OrderStatusEnum {
    New = 1,
    Confirmed = 2,
    Processed = 3,
    Submitted = 4,
    Cancelled = 5
}

export let OrderStatusDisplay: {[key in OrderStatusEnum] : string} = {
    [OrderStatusEnum.New]: "Noua",
    [OrderStatusEnum.Confirmed]: "Confirmata",
    [OrderStatusEnum.Processed]: "Procesata",
    [OrderStatusEnum.Submitted]: "Finalizată",
    [OrderStatusEnum.Cancelled]: "Anulata",
};