import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoresContext, stores } from './stores/stores';
import AppRouter from './app.router';
import GlobalStyle from './global-styles';
import { ThemeProvider } from '@mui/material';
import muiTheme from './theme/mui-theme';
import { toastrConfig } from './config/toastr.config';
import { Toaster } from 'react-hot-toast';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { observer } from 'mobx-react-lite';
import { checkAuthorizationAdmin } from './utils/requests';
import { isEmpty } from 'lodash';

const App = observer(() => {

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        await checkAuthorizationAdmin()
      } catch (error) {
        console.error(error);
      }
    };

    if(!isEmpty(window.localStorage?.getItem("token")))
      checkAuthorization();
  }, []);

  return (
    <>
      <GlobalStyle />
      <Toaster position="top-right" {...toastrConfig} />
      <StoresContext.Provider value={stores}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router>
              <AppRouter />
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </StoresContext.Provider>
    </>
  );
})

export default App;


