export type EnvVarsType = {
    apiBaseUrl: string;
    port: string;
}

const envVars: EnvVarsType = {
    apiBaseUrl: process.env.REACT_APP_API_URL || '',
    port: process.env.REACT_APP_PORT || '8091'
}

export default envVars;