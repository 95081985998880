import styled from "styled-components";
import { themeVariables } from "../../../../theme/variables";

const LoginPageStyled = styled.div`
    width: 75%;

    .page-header {
        margin-bottom: 3rem;

        .title {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: ${() => themeVariables.fontWeights.bold};
            font-size: ${() => themeVariables.fontSizes.large}
        }

        .subtitle {
            margin: 0;
            margin-top: 0.5rem;
            color: gray;
        }
    }

    .input-elements {
        margin-bottom: 2rem;
    }

    .bottom-area {

    }
`;

export default LoginPageStyled;