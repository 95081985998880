export enum ContentTypeEnum {
    XWwwFormUrlencoded = 'application/x-www-form-urlencoded',
    FormData = 'multipart/form-data',
    ApplicationJson = 'application/json',
    Pdf = 'application/pdf',
    OctetStream = 'application/octet-stream',
    Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Xls = 'application/vnd.ms-excel',
    JPEG = "image/jpeg",
    PNG = "image/png"
}