import { observer } from "mobx-react-lite";
import DraggableListStyled from "./draggable-list.component.styled";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DraggableListComponentPropsType, SortOrderDto } from "./draggable-list.component.types";
import { useStore } from "../../hooks/store.hook";

const DraggableListComponent = observer(({
    data
}: DraggableListComponentPropsType) => {

    const dragItem = useRef<any>(null);
    const dragOverItem = useRef<any>(null);
    const [tableData, setTableData] = useState<SortOrderDto[]>(data);
    const uiStore = useStore("uiStore");

    function allowDrop(e: any) {
        e.preventDefault();
      }

    const dragStart = (e: any) => {
        dragItem.current = e.target.id;
    }

    const dragEnter = (e: any) => {
        dragOverItem.current = e.currentTarget.id;
    }

    const drop = () => {
        const copyListItems = [...tableData];
        const dragItemContent = copyListItems[+dragItem.current];
        copyListItems.splice(+dragItem.current , 1);
        copyListItems.splice(+dragOverItem.current , 0, dragItemContent as any);
        dragItem.current = null;
        dragOverItem.current = null;
        setTableData(() => copyListItems);
    }

    const row = tableData.map((item, index) => {
        return (
            <TableRow
                onDragStart={(e) => dragStart(e)} 
                onDragEnter={(e) => dragEnter(e)}
                onDragEnd={drop}
                onDragOver={allowDrop}
                draggable
                id={`${index}`}
                sx={{
                    cursor: "move"
                }}
            >   
                <TableCell>{item?.name}</TableCell>
                <TableCell align="right">
                    <DragIndicatorIcon />
                </TableCell>
            </TableRow>
        )
    })

    useEffect(
        () => {
            uiStore.setSortOrderItems(tableData);
        },
        [tableData]
    )
    
    return (
        <DraggableListStyled>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nume</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {React.Children.toArray(row)}
                </TableBody>
            </Table>
        </DraggableListStyled>
    )
});

export default DraggableListComponent;

function useEffet(arg0: () => void, arg1: never[]) {
    throw new Error("Function not implemented.");
}
