import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import SelectOrderProductsStyled from "./select-order-products.component.styled";
import { LinearProgress } from "@mui/material";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { getAllItems, getCategories } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, SelectedStatePropsType, TableDataPropsType } from "../../../table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from "../../../button/button.component";
import TooltipComponent from "../../../tooltip/tooltip.component";

export type SelectOrderProductsComponentPropsType = {
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
}

const SelectOrderProductsComponent = ({
    selectedItems,
    setSelectedItems
}: SelectOrderProductsComponentPropsType) => {

    const uiStore = useStore('uiStore');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [categories, setCategories] = useState<any[]>()

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)

                    let fetchData = await getAllItems({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        categoryId: filtersTable.tabFilterValue
                    });
                    setData(() => fetchData)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    useEffect(
        () => {
            setIsLoading(() => true)
            getCategories()
            .then(data => {
                setCategories(() => [
                    {
                        label: "Toate",
                        value: null
                    },
                    ...data.map(m => ({
                        label: m.name,
                        value: m.id
                    }))
                ])
                setIsLoading(() => false)
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setIsLoading(() => false)
            })
                    
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                       id: 'id',
                       label: 'Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    );

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                }
            }
        },
        []
    )

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectOrderProductsStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllItems} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={isLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                preselectedItems={selectedItems}
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                tabs={categories}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectOrderProductsStyled>
    )

}

export default SelectOrderProductsComponent;