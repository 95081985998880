import styled, { keyframes } from "styled-components";
import { themeVariables } from "../../theme/variables";

const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export type LoaderComponentPropsType = {
    loaderSize?: number;
}

const LoaderComponent = styled.div<LoaderComponentPropsType>`
    border: ${props => (props.loaderSize ?? 20) / 6}px solid ${() => themeVariables.colors.backgroundGray}; /* Light grey */
    border-top: ${props => (props.loaderSize ?? 20) / 6}px solid ${() => themeVariables.colors.backgroundGrayDarker}; /* Blue */
    border-radius: 50%;
    width: ${props => props.loaderSize ?? 20}px;
    height: ${props => props.loaderSize ?? 20}px;
    animation-name: ${spinAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
`;

export default LoaderComponent;