import React from "react";
import TopNavigationComponentStyled from "./top-navigation.component.styled";
import AvatarMenuComponent from "../avatar-menu/avatar-menu.component";

export type TopNavigationComponentPropsType = {
    isExpanded: boolean;
}

const TopNavigationComponent =  ({
    isExpanded = false
}: TopNavigationComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <TopNavigationComponentStyled isExpanded={isExpanded}>
            <AvatarMenuComponent/>
        </TopNavigationComponentStyled>
    )

}

export default TopNavigationComponent;