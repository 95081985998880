import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/store.hook';
import { DialogResponseTypeEnum } from '../../enums/dialog-response-type.enum';
import ButtonComponent from '../button/button.component';
import { DialogComponentStyled } from './dialog-component.styled';
import { themeVariables } from '../../theme/variables';
import { ClickAwayListener, Grid } from '@mui/material';
import TextFieldComponent from '../text-field/text-field.component';
import { isEmpty } from 'lodash';

const AlertDialog =  observer(
    () => {

    const uiStore = useStore("uiStore");

    const [reason, setReason] = React.useState<string>('');
    const [reasonError, setReasonError] = React.useState<boolean>(false);

    const onClickAwayHandler = React.useCallback(
        () => {
            if (uiStore.dialogActive && uiStore.dialogResFunction) {
                uiStore.dialogResFunction({
                    value: DialogResponseTypeEnum.Dismiss
                }) 
                uiStore.dissmissDialog();
            }
        },
        [uiStore]
    )

    return (
        <Dialog
            sx={{
                backgroundColor: "rgba(0,0,0,0.1)",
                backdropFilter: "blur(1px)",
            }}
            open={uiStore.dialogActive}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                uiStore.dialogActive ?
                    <ClickAwayListener onClickAway={onClickAwayHandler} mouseEvent='onMouseDown'>
                        <DialogComponentStyled>
                            <DialogTitle sx={{ fontSize: themeVariables.fontSizes.medium}} id="alert-dialog-title">
                                {uiStore.currentDialog?.title}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{ fontSize: themeVariables.fontSizes.subtitle}} id="alert-dialog-description">
                                    {uiStore.currentDialog?.message}
                                </DialogContentText>

                                {
                                    uiStore.currentDialog?.withReason ?
                                    <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                label="Motiv" 
                                                variant="outlined" 
                                                fullWidth={true}
                                                value={reason}
                                                error={reasonError}
                                                helperText={
                                                    reasonError ?
                                                    "Motivul trebuie completat" :
                                                    "Care este motivul pentru care doresti sa realizezi aceasta actiune?"
                                                }
                                                onTextChange={newValue => setReason(() => newValue)}
                                            />
                                        </Grid>
                                    </Grid> :
                                    ''
                                }
                            </DialogContent>
                            <DialogActions>
                                <ButtonComponent size='large' variant="text" onClick={ 
                                    () => {
                                            if(uiStore.dialogResFunction)
                                                uiStore.dialogResFunction({
                                                    value: DialogResponseTypeEnum.Dismiss
                                                }) 
                                            uiStore.dissmissDialog()
                                        }
                                    }
                                >
                                    Nu
                                </ButtonComponent>
                                <ButtonComponent size='large' variant="text" onClick={ 
                                    () => {
                                            if (uiStore.currentDialog?.withReason && isEmpty(reason)) {
                                                setReasonError(() => true);
                                                return;
                                            }

                                            if(uiStore.dialogResFunction)
                                                uiStore.dialogResFunction({
                                                    value: DialogResponseTypeEnum.Confirm,
                                                    reason: uiStore.currentDialog?.withReason ? reason : undefined
                                                }) 
                                            uiStore.dissmissDialog()
                                        }
                                    }
                                >
                                    Da
                                </ButtonComponent>
                            </DialogActions>
                        </DialogComponentStyled>
                    </ClickAwayListener> :
                    ''
            }
        </Dialog>
    );
})

export default AlertDialog;
