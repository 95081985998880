import { Close, FilePresent } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { ContentTypeEnum } from "../../enums/content-type.enum";
import DropzoneUploadComponentStyled from "./dropzone-upload.component.styled";
import { blobToBase64 } from "../../utils/requests";
import LoaderComponent from "../loader/loader.component";
import { displayCustomToast } from "../../utils/display-custom-toast";

export type DropzoneUploadComponentPropsType = {
    value: any[];
    isLoading?: any,
    setIsLoading?: (value: any) => void;
    setBlobImage: (value: any) => void;
    deleteImage: () => void;
    inputRef: any;
}

const DropzoneUploadComponent = ({
    value,
    isLoading,
    setIsLoading,
    setBlobImage,
    deleteImage,
    inputRef
}: DropzoneUploadComponentPropsType) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const _isExtensionValid = (file: File): boolean => {
        return [ContentTypeEnum.PNG, ContentTypeEnum.JPEG].includes(file.type as any);
    }

    const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return;
        const file = event.target.files[0];
        if (!_isExtensionValid(file)) {
            return;
        }

        setSelectedFile(() => file);
        if(!setIsLoading) return;

        try {

            setIsLoading(() => true);
            var reader: any = await blobToBase64(new Blob([file]))
            let varPhoto = await fetch(reader);
            let blobPhoto = await varPhoto.blob();
            setBlobImage(() => blobPhoto);

        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
        
    }

    const onWrapperClick = useCallback(
        () => {
            if (!inputRef.current) return;
            (inputRef.current as any).click?.();
        },
        []
    )


    const fileSize = useMemo(
        (): string => {
            if (!selectedFile) return '';

            if (selectedFile.size > 1000 * 1024)
                return `${(selectedFile.size/(1000*1024)).toFixed(2)} MB`;
            else if (selectedFile.size > 1024)
                return `${(selectedFile.size/1024).toFixed(2)} KB`;
            
            return `${(selectedFile.size).toFixed(2)} bytes`;
        },
        [selectedFile]
    )


    /** define the return statement bellow */
    return (
        <DropzoneUploadComponentStyled>
            <div className="dropzone-inner" onClick={onWrapperClick}>
                {
                    isLoading ?
                        <LoaderComponent loaderSize={17} />
                        :
                        <>
                            {
                                !value.length ?
                                <>
                                    <input 
                                        type="file" 
                                        className="image-upload-input" 
                                        ref={inputRef} 
                                        onChange={onInputChange} 
                                        accept={`${ContentTypeEnum.PNG}, ${ContentTypeEnum.JPEG}`} 
                                    />

                                    <FilePresent className="message-icon" />
                                    <div className="message-title">Click pentru a adauga o imagine</div>
                                </>
                                :
                                <img
                                    src={value[0]?.fullPath}
                                    alt="thumbnail"
                                    width="fit-container"
                                    height="200px"
                                />
                            }
                        </>
                }
            </div>

            {
                !!value.length ?
                    <div className="file-selected-wrapper">
                        <FilePresent />
                        <div className="file-selected-content">
                            <div className="file-selected-content-title">
                                {value[0]?.fileName}
                            </div>
                            <div className="file-selected-content-subtitle">
                                {fileSize}
                            </div>
                        </div>
                        <div className="file-selected-action">
                            <IconButton onClick={deleteImage}>
                                <Close />
                            </IconButton>
                        </div>
                    </div> :
                    ''
            }

        </DropzoneUploadComponentStyled>
    )

}

export default DropzoneUploadComponent;