import { makeAutoObservable } from "mobx";
import { RootStore } from "./root.store";

export class GlobalStore {

    translationsText: any = null;
    license: any = null;

    private _rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        makeAutoObservable(this);
    }

    setTranslationsText(value: any) {
        this.translationsText = value;
    }

    setLicense(value: any) {
        this.license = value;
    }
}