import { Grid } from "@mui/material";
import { ModalDialogStyled } from "../../create-order-panel.component.styled";
import ButtonComponent from "../../../button/button.component";
import { useStore } from "../../../../hooks/store.hook";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { useState } from "react";
import TextFieldComponent from "../../../text-field/text-field.component";

type AddDescriptionModalPropsType = {
    description: string;
    setDescription: (value : any) => void;
    action?: (value: any) => void;
    placeholder?: string,
    viewMode?: boolean
}

const AddDescriptionModal = ({
    description,
    setDescription,
    action,
    placeholder,
    viewMode
}: AddDescriptionModalPropsType) => {

    const uiStore = useStore('uiStore');
    const [value, setValue] = useState<string>(description);

    return (
        <ModalDialogStyled>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextFieldComponent
                        fullWidth
                        multiline
                        minRows={5}
                        placeholder={placeholder}
                        value={value}
                        onTextChange={value => setValue(value)}
                    />
                </Grid>

            </Grid>

            {
                viewMode ?
                    <></>
                    :
                    <div className="save-button">
                        <ButtonComponent 
                            onClick={() => {
                                setDescription(value);

                                if(action) {
                                    action(value);
                                }

                                if(!action) {
                                    displayCustomToast("Descriere salvata!")
                                    uiStore.dismissModal();
                                }
                            }}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
            }
        </ModalDialogStyled>
    )
}

export default AddDescriptionModal;