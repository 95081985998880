import styled from "styled-components";
import { SIDE_NAVIGATION_EXPANDED_WIDTH, SIDE_NAVIGATION_RETRACTED_WITH } from "../../utils/constants";

export type ActionToastComponentStyledPropsType = {
    isVisible: boolean;
    sidebarExtended: boolean;
}

const ActionToastComponentStyled = styled.div<ActionToastComponentStyledPropsType>`

    position: fixed;
    z-index: 2;
    bottom: ${props => props.isVisible ? '1rem' : '-3rem'};
    left: ${props => props.sidebarExtended ? SIDE_NAVIGATION_EXPANDED_WIDTH : SIDE_NAVIGATION_RETRACTED_WITH}rem;
    right: 0;
    transition: all 250ms;
    display: flex;
    justify-content: center;

    .toast-inner {
        background: #fff;
        font-family: 'Poppins',sans-serif;
        font-size: 0.8rem;
        font-weight: 600;
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        color: #363636;
        line-height: 1.3;

        .toast-message {
            margin-right: 5rem;
        }

        .toast-actions {
            display: flex;
            align-items: center;
        }
    }

`;

export default ActionToastComponentStyled;