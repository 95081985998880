import styled from "styled-components";
import { themeVariables } from "../../theme/variables";
import { NAVBAR_ITEM_ICON_WIDTH, NAVBAR_ITEM_PADDING_HORIZONTAL, NAVBAR_ITEM_PADDING_VERTICAL } from "../../utils/constants";

export type NavbarItemComponentStyledPropsType = {
    isActive: boolean;
}

const NavbarItemComponentStyled = styled.div<NavbarItemComponentStyledPropsType>`

    width: 100%;
    
    .navbar-item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0;

        .navbar-item-icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: ${NAVBAR_ITEM_ICON_WIDTH}rem;
        }

        .navbar-item-name {
            margin-left: 0.7rem;
            white-space: nowrap;
        }
    }

    .MuiButton-root {
        text-transform: none; 
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        color: ${props => props.isActive ? themeVariables.colors.primary : themeVariables.textColors.gray};
        background: ${props => props.isActive ? themeVariables.colors.primaryLighter : '#fff'};
        font-weight: ${props => props.isActive ? themeVariables.fontWeights.medium : themeVariables.fontWeights.regular};
        padding: ${NAVBAR_ITEM_PADDING_VERTICAL}rem ${NAVBAR_ITEM_PADDING_HORIZONTAL}rem;
        border-radius: 0;
    }

`;

export default NavbarItemComponentStyled;