import styled from 'styled-components';
import { themeVariables } from '../../theme/variables';

const ProfileGeneralDetailsComponentStyled = styled.div`

    .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .section-header {
        margin-bottom: 1.5rem;
    }

    .roles-wrapper {
        display: flex;
        align-items: center;
    }

    .role-chip {
        margin-right: 0.8rem;

        font-size: ${themeVariables.fontSizes.normal};
    }

`;

export default ProfileGeneralDetailsComponentStyled