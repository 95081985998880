import { observer } from "mobx-react-lite";
import PartnerUsersPanelActionStyled from "./partner-users-panel-action.component.styled";
import { StateFieldType } from "../../../../types/form.types";
import { useEffect, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, LinearProgress } from "@mui/material";
import TextFieldComponent from "../../../text-field/text-field.component";
import { isEmpty } from "lodash";
import { themeVariables } from "../../../../theme/variables";
import ButtonComponent from "../../../button/button.component";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { getPartnerUser, registerPartnerUserCommand, updatePartnerUserCommand } from "../../../../utils/requests";

type PartnerUsersPanelActionPropsType = {
    partnerId?: number
    partnerUserId?: number
}

export type StateType = {
    fields: {
        email: StateFieldType<string>;
        password: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        description: StateFieldType<string>;
        isActive?: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

const PartnerUsersPanelAction = observer(({
    partnerId,
    partnerUserId
}: PartnerUsersPanelActionPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            firstName: {
                value: "",
                isValid: false,
                noValidation: false
            },
            lastName: {
                value: "",
                isValid: false,
                noValidation: false
            },
            email: {
                value: "",
                isValid: false,
                noValidation: false
            },
            phoneNumber: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            password: {
                value: "",
                isValid: false,
                noValidation: false
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    });
    const uiStore = useStore('uiStore');

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false);

    useEffect(
        () => {
            if(!partnerUserId) return;

            setLoadingUserInfo(() => true);

            getPartnerUser(partnerUserId)
            .then(data => {
                updateState('firstName', data.firstName);
                updateState('lastName', data.lastName);
                updateState('description', data.description);
                updateState('phoneNumber', data.phoneNumber);
                updateState('isActive', data.isActive);

                setFormGroup((state: any) => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        email: {
                            ...state.fields.email,
                            isValid: true,
                            value: ""
                        },
                        password: {
                            ...state.fields.password,
                            isValid: true,
                            value: ""
                        }
                    }
                }))

                setLoadingUserInfo(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingUserInfo(() => false);
            })
        },
        [partnerUserId]
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            firstName: formGroup.fields.firstName.value,
            lastName: formGroup.fields.lastName.value,
            phoneNumber: formGroup.fields.phoneNumber.value,
            description: formGroup.fields.description.value,
            email: formGroup.fields.email.value,
            password: formGroup.fields.password.value,
            partnerId: partnerId
        }

        try {
            if(partnerUserId != undefined){
                let bodyUpdate = {
                    partnerUserId: partnerUserId,
                    isActive: formGroup.fields.isActive?.value,
                    firstName: formGroup.fields.firstName.value,
                    lastName: formGroup.fields.lastName.value,
                    phoneNumber: formGroup.fields.phoneNumber.value,
                    description: formGroup.fields.description.value,
                }
                await updatePartnerUserCommand(bodyUpdate)
            }
            else
                await registerPartnerUserCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <PartnerUsersPanelActionStyled>
            {
                loadingUserInfo ?
                    <LinearProgress style={{borderRadius: '10px'}} />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Nume"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.firstName?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.firstName?.isValid}
                                onTextChange={(value: any) => updateState('firstName', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Prenume"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.lastName?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.lastName?.isValid}
                                onTextChange={(value: any) => updateState('lastName', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Numar de telefon"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.phoneNumber?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.phoneNumber?.isValid}
                                onTextChange={(value: any) => updateState('phoneNumber', value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Descriere:</label>
                            <textarea
                                className="text-area"
                                id="description"
                                rows={3}
                                placeholder="Descriere"
                                value={formGroup.fields.description?.value ?? ""}
                                onChange={e => updateState('description', e.target.value)}
                            />
                        </Grid>

                        {
                            !partnerUserId ?
                                <Grid item xs={12}>
                                    <TextFieldComponent
                                        label="Email"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={formGroup.fields.email?.value}
                                        error={formGroup?.shouldDisplayError && !formGroup.fields.email?.isValid}
                                        onTextChange={(value: any) => updateState('email', value)}
                                        required
                                    />
                                </Grid>
                                :
                                <></>
                        }

                        {
                            !partnerUserId ?
                                <Grid item xs={12}>
                                    <TextFieldComponent
                                        label="Parola"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={formGroup.fields.password?.value}
                                        error={formGroup?.shouldDisplayError && !formGroup.fields.password?.isValid}
                                        onTextChange={(value: any) => updateState('password', value)}
                                        type="password"
                                        autoComplete='new-password'
                                        required
                                    />
                                </Grid>
                                :
                                <></>
                        }


                        {
                            !(partnerUserId === undefined) ?
                                <Grid item xs={12}>
                                    <FormControl
                                        error={!formGroup.fields.isActive?.value}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formGroup.fields.isActive?.value}
                                                onChange={e => updateState('isActive', e.target.checked)}
                                            />}
                                            label="Activ"
                                        />
                                        {
                                            !formGroup.fields.isActive?.value ?
                                                <FormHelperText 
                                                    sx={{ 
                                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                                        marginLeft: 0
                                                    }}>
                                                        Utilizatorul nu o sa mai fie activ
                                                    </FormHelperText>
                                                :
                                                <></>
                                        }
                                    </FormControl>
                                </Grid>
                                :
                                <></>
                        }

                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "1rem"
                                }}
                            >
                                <ButtonComponent 
                                    onClick={createAction} 
                                    isLoading={isSaving}
                                    sx={{
                                        width: "5rem"
                                    }}
                                >
                                    Salveaza
                                </ButtonComponent>
                            </div>
                        </Grid>

                    </Grid>
            }
        </PartnerUsersPanelActionStyled>
    )
})

export default PartnerUsersPanelAction;