import axios, { AxiosInstance } from 'axios';
import { requestInterceptors, responseInterceptors } from '../utils/http-interceptors';
import qs from 'qs';

const getAxiosInstance = (): AxiosInstance => {
    /** serialize the arrays that are sent on queryString as spread fields */
    const axiosInstace: AxiosInstance = axios.create({
        paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });

    /** define the configuration for sending large files to avoid timeouts */
    axiosInstace.defaults.timeout = 3000000;

    /** attach the interceptors to the configured object */
    axiosInstace.interceptors.request.use(...requestInterceptors);
    axiosInstace.interceptors.response.use(...responseInterceptors);

    /** return the instance of the configured axios object */
    return axiosInstace;
}

export default getAxiosInstance();