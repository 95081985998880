import { observer } from "mobx-react-lite";
import { GetItemObservationsModalStyled } from "./get-item-observations-modal.component.styled";
import { useEffect, useState } from "react";
import { getItemObservations } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { Box, LinearProgress, Link } from "@mui/material";
import { GetItemObservationsDto } from "../../modules/main/pages/production-zone/production-zone.page.types";
import { themeVariables } from "../../theme/variables";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PanelType } from "../../enums/panel-type.enum";
import CreateOrderPanel from "../create-order-panel/create-order-panel.component";
import { useStore } from "../../hooks/store.hook";

type GetItemObservationsModalPropsType = {
    itemId?: number;
}

const GetItemObservationsModal = observer(({
    itemId
}: GetItemObservationsModalPropsType) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [observations, setObservations] = useState<GetItemObservationsDto[]>([]);
    const uiStore = useStore('uiStore');

    useEffect(
        () => {
            setIsLoading(() => true);

            getItemObservations(itemId)
            .then(data => {
                setObservations(() => data);
                setIsLoading(() => false)
            })
            .catch(error => {
                displayCustomToast(error, true);
            })
        },
        []
    )

    const openEditOrder = (orderId: number) => {
        uiStore.openPanel({
            key: PanelType.EditItem,
            component: <CreateOrderPanel 
                orderId={orderId} 
            />,
            panelWidth: '700px',
            title: `Editeaza comanda: #${orderId}`
        });
    }

    return (
        <GetItemObservationsModalStyled>
            {
                isLoading ?
                    <LinearProgress style={{ borderRadius: "10px" }} />
                :
                observations.map(observation => (
                    <Box>
                        <Box
                            fontWeight={themeVariables.fontWeights.bold}
                            fontSize={themeVariables.fontSizes.medium}
                        >
                            <Link
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#000000 !important",
                                    textDecoration: "none",

                                    '&:hover': {
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => openEditOrder(observation.orderId)}
                            >
                                <span
                                    style={{
                                        display: "flex"
                                    }}
                                >
                                    <FiberManualRecordIcon 
                                        sx={{
                                            fontSize: `${themeVariables.fontSizes.xSmall}`
                                        }}
                                    />
                                </span>
                                <span>
                                    Pentru comanda #{observation.orderId} - {observation.partnerName} - {observation.salePointName}:
                                </span>
                            </Link>
                        </Box>
                        <Box
                            padding="0.75rem"
                        >
                            {observation.itemObservations}
                        </Box>
                    </Box>
                ))
            }
        </GetItemObservationsModalStyled>
    )
})

export default GetItemObservationsModal;