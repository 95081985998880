import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const TabComponentStyled = styled.div`

    .tab-component {

        display: flex;
        align-items: center;

        .tab-image {
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .MuiTab-root {
        text-transform: none;
        font-family: 'Poppins', sans-serif;
        padding: 12px 0;
        margin-right: 2rem;
        color: ${themeVariables.textColors.gray} !important;
    }

    .Mui-selected {
        color: ${themeVariables.textColors.primary} !important;
    }

    .panels-wrapper {
        margin-top: 2rem;
    }

`;

export default TabComponentStyled;