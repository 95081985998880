import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

export const DialogComponentStyled = styled.div`
    padding: 1rem 1rem 1rem 1rem;
    

    .MuiTypography-root-MuiDialogTitle-root{
        font-size: ${themeVariables.fontSizes.xLarge} !important;
    }
`
