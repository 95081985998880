import { observer } from "mobx-react-lite";
import DeliveryRoutePanelStyled from "./delivery-route-panel.component.styled";
import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, LinearProgress } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { useEffect, useState } from "react";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { isEmpty } from "lodash";
import { themeVariables } from "../../theme/variables";
import ButtonComponent from "../button/button.component";
import toast from "react-hot-toast";
import { createDeliveryRouteCommand, updateDeliveryRouteCommand } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        description: StateFieldType<string>;
        mondayDelivery: StateFieldType<boolean>;
        tuesdayDelivery: StateFieldType<boolean>;
        wednesdayDelivery: StateFieldType<boolean>;
        thursdayDelivery: StateFieldType<boolean>;
        fridayDelivery: StateFieldType<boolean>;
        saturdayDelivery: StateFieldType<boolean>;
        sundayDelivery: StateFieldType<boolean>;
        isActive?: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

type DeliveryRoutePanelPropsType = {
    deliveryRouteId?: number,
    deliveryRoute?: any
}

const DeliveryRoutePanel = observer(({
    deliveryRouteId,
    deliveryRoute
}: DeliveryRoutePanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            mondayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            tuesdayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            wednesdayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            thursdayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            fridayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            saturdayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            sundayDelivery: {
                value: false,
                isValid: true,
                noValidation: true
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const uiStore = useStore('uiStore');
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            if(
                formGroup.fields.mondayDelivery.value &&
                formGroup.fields.tuesdayDelivery.value &&
                formGroup.fields.wednesdayDelivery.value &&
                formGroup.fields.thursdayDelivery.value &&
                formGroup.fields.fridayDelivery.value &&
                formGroup.fields.saturdayDelivery.value &&
                formGroup.fields.sundayDelivery.value
            ) {
                setAllSelected(() => true)
            } else {
                setAllSelected(() => false)
            }
        },
        [formGroup]
    )

    useEffect(
        () => {
            if(deliveryRoute === undefined) return;

            updateState('mondayDelivery', deliveryRoute.mondayDelivery);
            updateState('tuesdayDelivery', deliveryRoute.tuesdayDelivery);
            updateState('wednesdayDelivery', deliveryRoute.wednesdayDelivery);
            updateState('thursdayDelivery', deliveryRoute.thursdayDelivery);
            updateState('fridayDelivery', deliveryRoute.fridayDelivery);
            updateState('saturdayDelivery', deliveryRoute.saturdayDelivery);
            updateState('sundayDelivery', deliveryRoute.sundayDelivery);
            updateState('isActive', deliveryRoute.isActive);
            updateState('name', deliveryRoute.name);
            updateState('description', deliveryRoute.description);
        },
        [deliveryRoute]
    )

    const selectAllAction = (value: any) => {
        setAllSelected(() => value)
        updateState('mondayDelivery', value);
        updateState('tuesdayDelivery', value);
        updateState('wednesdayDelivery', value);
        updateState('thursdayDelivery', value);
        updateState('fridayDelivery', value);
        updateState('saturdayDelivery', value);
        updateState('sundayDelivery', value);
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            description: formGroup.fields.description.value,
            mondayDelivery: formGroup.fields.mondayDelivery.value,
            tuesdayDelivery: formGroup.fields.tuesdayDelivery.value,
            wednesdayDelivery: formGroup.fields.wednesdayDelivery.value,
            thursdayDelivery: formGroup.fields.thursdayDelivery.value,
            fridayDelivery: formGroup.fields.fridayDelivery.value,
            saturdayDelivery: formGroup.fields.saturdayDelivery.value,
            sundayDelivery: formGroup.fields.sundayDelivery.value
        }

        try {
            if(deliveryRouteId != undefined){
                let bodyUpdate = {
                    id: deliveryRouteId,
                    isActive: formGroup.fields.isActive?.value,
                    ...body
                }
                await updateDeliveryRouteCommand(deliveryRouteId, bodyUpdate)
            }
            else
                await createDeliveryRouteCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <DeliveryRoutePanelStyled>
            {
                loadingData ? (
                    <LinearProgress style={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Nume"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.name?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                            onTextChange={(value: any) => updateState('name', value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="description">Descriere:</label>
                        <textarea
                            className="text-area"
                            id="description"
                            rows={3}
                            placeholder="Descriere"
                            value={formGroup.fields.description?.value ?? ""}
                            onChange={e => updateState('description', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormHelperText 
                            component="div" 
                            sx={{ 
                                fontSize: `${themeVariables.fontSizes.normal}`,
                                marginLeft: 0
                            }}>
                                <div>
                                    <Divider />
                                </div>
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <FormHelperText
                            component="div" 
                            sx={{ 
                                fontSize: `${themeVariables.fontSizes.normal}`,
                                marginLeft: 0
                            }}>
                                Selecteaza zilele de livrare:
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={allSelected}
                                onChange={e => selectAllAction(e.target.checked)}
                            />}
                            label="Selecteaza tot"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.mondayDelivery?.value}
                                onChange={e => updateState('mondayDelivery', e.target.checked)}
                            />}
                            label="Luni"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.tuesdayDelivery?.value}
                                onChange={e => updateState('tuesdayDelivery', e.target.checked)}
                            />}
                            label="Marți"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.wednesdayDelivery?.value}
                                onChange={e => updateState('wednesdayDelivery', e.target.checked)}
                            />}
                            label="Miercuri"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.thursdayDelivery?.value}
                                onChange={e => updateState('thursdayDelivery', e.target.checked)}
                            />}
                            label="Joi"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.fridayDelivery?.value}
                                onChange={e => updateState('fridayDelivery', e.target.checked)}
                            />}
                            label="Vineri"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.saturdayDelivery?.value}
                                onChange={e => updateState('saturdayDelivery', e.target.checked)}
                            />}
                            label="Sâmbătă"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.sundayDelivery?.value}
                                onChange={e => updateState('sundayDelivery', e.target.checked)}
                            />}
                            label="Duminică"
                        />
                    </Grid>

                    {
                        !(deliveryRouteId === undefined) ?
                            <Grid item xs={12}>
                                <FormHelperText 
                                    component="div" 
                                    sx={{ 
                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                        marginLeft: 0
                                    }}>
                                        <div>
                                            <Divider />
                                        </div>
                                </FormHelperText>
                            </Grid>
                            :
                            <></>
                    }

                    {
                        !(deliveryRouteId === undefined) ?
                            <Grid item xs={12}>
                                <FormControl
                                    error={!formGroup.fields.isActive?.value}
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formGroup.fields.isActive?.value}
                                            onChange={e => updateState('isActive', e.target.checked)}
                                        />}
                                        label="Activ"
                                    />
                                    {
                                        !formGroup.fields.isActive?.value ?
                                            <FormHelperText 
                                                sx={{ 
                                                    fontSize: `${themeVariables.fontSizes.normal}`,
                                                    marginLeft: 0
                                                }}>
                                                    Ruta nu o sa mai apara in lista unui partener
                                                </FormHelperText>
                                            :
                                            <></>
                                    }
                                </FormControl>
                            </Grid>
                            :
                            <></>
                    }

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>

                </Grid>
            }
        </DeliveryRoutePanelStyled>
    )
})

export default DeliveryRoutePanel;