import React from "react";
import NoDataComponentStyled, { FontStyled, ImgStyled } from "./no-data.component.styled";
import notFoundSrc from '../../assets/images/no-results.png';

export type NoDataComponentPropsType = {
    message?: string;
}

const NoDataComponent = ({
    message = 'Niciun rezultat'
}: NoDataComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <NoDataComponentStyled>
            <ImgStyled src={notFoundSrc} alt="empty" />
            <FontStyled>{message}</FontStyled>
        </NoDataComponentStyled>
    )

}

export default NoDataComponent;