import styled from "styled-components"
import { themeVariables } from "../../theme/variables";

const DropzoneUploadComponentStyled = styled.div`

    width: 100%;
    

    .dropzone-inner {
        border: 2px dashed rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        box-sizing: border-box;
        color: gray;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 0.5rem;
        cursor: pointer;
        transition: all 200ms;

        .image-upload-input {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
        }

        .message-icon {
            transition: all 200ms;
            font-size: 3.8rem;
        }

        .message-title {
            transition: all 200ms;
            margin-top: 0.4rem;
            font-size: ${themeVariables.fontSizes.normal};
            color: ${themeVariables.textColors.primary};
            font-weight: ${themeVariables.fontWeights.semibold};
        }

        .message-subtitle {
            font-size: ${themeVariables.fontSizes.smaller};
            margin-top: 0.3rem;
        }

        &:hover {
            border-color: rgba(12, 83, 183, 0.5);

            .message-title {
                color: rgb(12, 83, 183);
            }

            .message-icon {
                color: rgba(12, 83, 183, 0.5);
            }
        }
    }

    .file-selected-wrapper {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        padding: 0 0.3rem;

        .file-selected-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex: 1;
            margin-left: 0.5rem;
            margin-right: 1rem;

            .file-selected-content-title {
                font-size: ${themeVariables.fontSizes.subtitle};
                font-weight: ${themeVariables.fontWeights.semibold};
            }

            .file-selected-content-subtitle {
                color: gray;
                font-size: ${themeVariables.fontSizes.smaller};
            }

        }

        .file-selected-action {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

`;

export default DropzoneUploadComponentStyled;