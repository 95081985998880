import styled from "styled-components";
import {themeVariables} from '../../theme/variables' 
export const DropdownSearchBarStyled = styled.div`

    display: flex;
    padding: 20px 24px 20px 24px;

`;

export type TableComponentStyledPropsType = {
  viewType: 'normal' | 'panel' | 'read-only' | 'minimal';
}

export const TableComponentStyled = styled.div<TableComponentStyledPropsType>`

  border-radius: 16px;

  .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: 'Poppins', sans-serif !important;
  }

  .MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem {
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiChip-root {
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 5px;
  }

  .MuiInputBase-root-MuiTablePagination-select{
    font-family: 'Poppins', sans-serif !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root{
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiButtonBase-root-MuiTab-root{
      text-transform: none !important;
  }

  .MuiTablePagination-displayedRows{
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiTablePagination-selectLabel{
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiTypography-root {
      font-family: 'Poppins', sans-serif !important;
  }

  .MuiTableCell-root {
      font-family: 'Poppins', sans-serif !important;
      border-bottom: 0px !important;      
  }

  .MuiTableRow-root.Mui-selected {
    background-color: #f5f5f5 !important;
  }
  
  .MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #00ab55 !important;
  }
  
  .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #00ab55 !important;
  }
  
  .MuiButtonBase-root-MuiTab-root {
    font-family: 'Poppins', sans-serif !important;
  }
  
  .MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: #f5f5f5 !important;
    color: black !important;
  }
  
  .MuiTabs-indicator {
    background-color: #00ab55 !important;
  }
  .MuiTabs-scroller {
    background-color: #f4f6f8 !important;
  }

  .MuiPaper-root {
    border-radius: 16px !important;
    box-shadow: ${props => props.viewType !== 'normal' ? 'none' : 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'};
    border: ${props => props.viewType !== 'panel' ? 'none' : '1px solid rgba(0, 0, 0, 0.25)'};
    margin: ${props => props.viewType !== 'minimal' ? 'inherit' : '0'};
  }

  .MuiFormControlLabel-root .MuiTypography-root {
    font-size: ${themeVariables.fontSizes.subtitle};
  }

  .MuiTableContainer-root {
    padding: ${props => (props.viewType === 'read-only' || props.viewType === 'minimal') ? 0 : '0.8rem'};
  }

  .css-1jf5wdm-MuiTableRow-root {
    background-color: ${props => props.viewType !== 'read-only' ? '#f4f6f8' : '#fff'};
    border-bottom: ${props => props.viewType !== 'read-only' ? 'none' : '1px solid rgba(0, 0, 0, 0.25)'};

    .MuiTableCell-root {
      color: ${props => props.viewType !== 'read-only' ? 'inherit' : 'gray'};
    }

  }

  .inner-footer {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  tbody .css-i4rxve-MuiTableRow-root {
    border-bottom: ${props => props.viewType !== 'read-only' ? 'none' : '1px solid rgba(0, 0, 0, 0.25)'};
  }
  
  .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    background-color: #00ab55 !important;
  }
`
export type FooterTableStyledPropsType = {
  isPagaination?: boolean;
  isDense?: boolean;
}


export const FooterTableStyled = styled.div<FooterTableStyledPropsType>`

  display: flex;
  justify-content: ${props => props.isDense && props.isPagaination ?
                                            'space-between'
                                            :
                                            props.isDense || props.isPagaination ?
                                                          props.isDense ? 'flex-start' : 'flex-end' 
                                                          :
                                                          ''                                            
  };
  padding: 10px;
`