import { Add } from "@mui/icons-material";
import { Chip, Menu, MenuItem } from "@mui/material";
import { RoleEnum } from "../../enums/role.enum";
import ButtonComponent from "../button/button.component";

type RoleComponentPropsType = {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void,
    anchorEl: null | HTMLElement,
    handleClose: () => void
    getAvailableOptions: () => RoleEnum[],
    onRoleAdd: (role: RoleEnum) => void,
    roles: RoleEnum[],
    onRoleDelete: (role: RoleEnum) => void,
    isSelf: boolean
}

const RoleComponent = ({
    handleClick,
    anchorEl,
    handleClose,
    getAvailableOptions,
    onRoleAdd,
    roles,
    onRoleDelete,
    isSelf
}: RoleComponentPropsType) => {


    return (
        <>
            {
                roles.map(role => (
                    <Chip
                        className="role-chip" 
                        label={role} 
                        key={role} 
                        onDelete={!isSelf ? () => onRoleDelete(role): undefined} 
                    />
                ))
            }

            {
                !isSelf ?
                    <ButtonComponent
                        variant="text"  
                        onClick={handleClick}
                        aria-controls={!!anchorEl ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        size="medium"
                        aria-expanded={!!anchorEl ? 'true' : undefined}
                        startIcon={<Add />}
                        >
                            Adauga un nou rol
                    </ButtonComponent>
                :
                ''
            }

            <Menu
                onClick={handleClose}
                id="demo-positioned-menu"
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                aria-labelledby="demo-positioned-button"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                { getAvailableOptions().length > 0 ?
                    getAvailableOptions().map(role => (
                        <MenuItem key={role} onClick={() => onRoleAdd(role)}>
                            { role }
                        </MenuItem>
                    )) :
                    <MenuItem disabled>
                        Nu exista roluri neasignate
                    </MenuItem>
                }
            </Menu> 
        </>
    )
}

export default RoleComponent;