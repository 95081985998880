import { observer } from "mobx-react-lite";
import PartnerUsersPanelStyled from "./partner-users-panel.component.styled";
import { useStore } from "../../hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import PanelTemplateComponent from "../panel-template/panel-template.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { getAllPartnerUsers } from "../../utils/requests";
import { PanelType } from "../../enums/panel-type.enum";
import PartnerUsersPanelAction from "./components/partner-users-panel-action/partner-users-panel-action.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ResetPasswordPartnerUsersPanel from "./components/reset-password-partner-user-panel/reset-password-partner-user-panel.component";
import LockResetIcon from '@mui/icons-material/LockReset';

type PartnerUsersPanelPropsType = {
    partnerId?: number
}

const PartnerUsersPanel = observer(({
    partnerId
}: PartnerUsersPanelPropsType) => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [data, setData] = useState<any>();
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    useEffect(
        () => {
            if(!refreshData) return;

            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllPartnerUsers({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        partnerId: partnerId
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
        },
        [refreshData, filtersTable]
    )

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreatePartnerUsers,
                component: <PartnerUsersPanelAction
                    partnerId={partnerId}
                />,
                panelWidth: '700px',
                title: `Creare utilizator partener`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'firstName',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'lastName',
                        label: 'Prenume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'email',
                        label: 'Email',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'phoneNumber',
                        label: 'Numar de telefon',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                         id: 'isActive',
                         label: 'Activ',
                         headerType: HeaderTypeEnum.Boolean,
                         alignment: 'center',
                         sortable: false
                     }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Resetare parola",
                    icon: <LockResetIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.ResetPasswordPartnerUsers,
                            component: <ResetPasswordPartnerUsersPanel
                                userId={row.id}
                            />,
                            panelWidth: '700px',
                            title: `Resetare parola utilizator: ${row.firstName} ${row.lastName}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Editeaza utilizator",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditPartnerUser,
                            component: <PartnerUsersPanelAction 
                                partnerId={partnerId}
                                partnerUserId={row.id}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza utilizatorul: ${row.firstName} ${row.lastName}`,
                            onDismiss: (data: any) => {
                                if (!data) return;

                                if (data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
            ];
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    return (
        <PartnerUsersPanelStyled>
            <PanelTemplateComponent 
                title="Utilizatorii partenerului"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Adaugă"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllPartnerUsers} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PanelTemplateComponent>
        </PartnerUsersPanelStyled>
    )
})

export default PartnerUsersPanel;