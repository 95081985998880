import moment from 'moment';
import { RoleEnum } from '../enums/role.enum';

export type BaseDto<T> = {
    id: T;
};


export type PrefixDto = {
    flag: string,
    code: string,
    name: string,
    prefixCode: string,
    mask: string
} & BaseDto<number>;

export type LanguageDto = {
    name: string;
    code: string;
    id: number;
    shortName: string;
}

const numeLuni = [
    "Ian.",
    "Feb.",
    "Mar.",
    "Apr.",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Sept.",
    "Oct.",
    "Noi.",
    "Dec."
  ];

export const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

export const shortenString = (text: string, limit: number): string => {
    if (!text) return '';
    return text.length < limit ? text : text.substring(0, limit) + '...'; 
}

export const formatDate = (date: Date): string => {
    return moment(date).format('YYYY-MM-DD');
}

export const formatDateRO = (date: string): string => {
    let dataCurenta = new Date(date);

    const zi = dataCurenta.getDate();
    const luna = numeLuni[dataCurenta.getMonth()];
    const an = dataCurenta.getFullYear();

    const dataFormata = `${zi} ${luna} ${an}`;

    return dataFormata;
}

export const formatNumber = (value: number) => {
    if(!value) return 0

    var valueString = value.toString()
    const indexDot = valueString.indexOf('.')
    var valueNoDot
    if(indexDot > 0){
        valueString = Number(valueString).toFixed(2)
        valueNoDot = valueString.slice(0, indexDot)
    }
    else 
        valueNoDot = valueString

    const valueLen  = valueNoDot.toString().length
    var formattedNumber = valueNoDot
    var c = 1

    if(valueLen > 3){
        for(let i = valueLen - 1; i >= 0; i = i - 1){
            if( c % 3 === 0)
                formattedNumber = `${valueNoDot.slice(0,i)}.${formattedNumber.slice(i, formattedNumber.length)}`
            c = c + 1
        }
    }

    if( indexDot > 0 )
        formattedNumber = `${formattedNumber},${valueString.slice(indexDot + 1, valueString.length)} `

    if(formattedNumber[0] === '.')
        formattedNumber = formattedNumber.slice(1, formattedNumber.length)

    return `${formattedNumber}`
}

export const isAdmin = (roles: RoleEnum[] | undefined, f?: any) => {

    if(f)
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return f
        else 
            return undefined
    else 
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return true
        else 
            return false
}

export const formatDateString = (input: any) => {
    var date = new Date(input);
    if (!isNaN(date.getTime())) {
      return date.toString().split('T')[0];
    } else {
      return input;
    }
}