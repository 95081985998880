import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const SectionTitleComponent = styled.div`
    margin-bottom: 0.5rem;
    font-weight: ${themeVariables.fontWeights.semibold};
    font-size: ${themeVariables.fontSizes.smaller};
    text-transform: uppercase;
`;

export default SectionTitleComponent;