import { useEffect, useState } from "react";
import ButtonComponent from "../button/button.component";
import CategoryPanelStyled from "./ingredient-panel.component.styled";
import { observer } from 'mobx-react-lite';
import TextFieldComponent from "../text-field/text-field.component";
import { isEmpty } from "lodash";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { createIngredientCommand, getMeasurementUnit, getMeasurementUnitType, updateIngredientCommand } from "../../utils/requests";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import IngredientPanelStyled from "./ingredient-panel.component.styled";
import { displayCustomToast } from "../../utils/display-custom-toast";

export type IngredientPanelPropsTypes = {

    ingredientId?: number,
    ingredientDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        measurementUnitType: StateFieldType<any>;
        measurementUnit: StateFieldType<any>;

    };
    shouldDisplayError: boolean;
}

const IngredientPanel = observer(({
    ingredientId,
    ingredientDetails
}: IngredientPanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMU, setIsLoadingMU] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            measurementUnitType: {
                value: null,
                isValid: false,
                noValidation: false
            },
            measurementUnit: {
                value: null,
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    const [measurementUnitTypes, setMeasurementUnitTypes] = useState<any>()
    const [measurementUnits, setMeasurementUnits] = useState<any[]>()

    // Editare
    useEffect(
        () => {
            if(ingredientId === undefined) return;
            
            setFormGroup(
                () => ({
                    fields: {
                        name: {
                            value: ingredientDetails?.name,
                            isValid: true,
                            noValidation: false
                        },
                        measurementUnit: {
                            value: {
                                label: ingredientDetails?.measurementUnit.name,
                                value: ingredientDetails?.measurementUnit.id 
                            },
                            isValid: true,
                            noValidation: false
                        },
                        measurementUnitType: {
                            value: {
                                label: ingredientDetails?.measurementUnitType.name,
                                value: ingredientDetails?.measurementUnitType.id 
                            },
                            isValid: true,
                            noValidation: false
                        }
                    },
                    shouldDisplayError: false
                })
            )
        },
        [ingredientDetails, ingredientId]
    )

    useEffect(
        () => {
            setIsLoading(() => true);

            getMeasurementUnitType()
            .then(data => {
                setMeasurementUnitTypes(() => data)
                setIsLoading(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setIsLoading(() => false);
            })
        },
        []
    )

    useEffect(
        () => {
            if(formGroup.fields.measurementUnitType?.value === null) return

            setIsLoadingMU(() => true)

            updateState('measurementUnit', null);

            getMeasurementUnit(formGroup.fields.measurementUnitType?.value.value)
            .then((data: any[]) => {
                setMeasurementUnits(() => data);
                setIsLoadingMU(() => false);
            })
        },
        [formGroup.fields.measurementUnitType?.value]
    )

    useEffect(
        () => {
            if(ingredientDetails?.measurementUnit.id == null) return;

            if(ingredientId != undefined && !!measurementUnits?.find((f: any) => f.id == ingredientDetails?.measurementUnit.id)){
                updateState('measurementUnit', {
                    label: ingredientDetails?.measurementUnit.name,
                    value: ingredientDetails?.measurementUnit.id 
                });
            }
        },
        [measurementUnits, ingredientDetails]
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createIngredientAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            measurementUnitTypeId: formGroup.fields.measurementUnitType.value.value,
            measurementUnitId: formGroup.fields.measurementUnit.value.value,
        }

        try {
            if(ingredientId != undefined){
                let bodyUpdate = {
                    id: ingredientId,
                    name: formGroup.fields.name.value,
                    measurementUnitTypeId: formGroup.fields.measurementUnitType.value.value,
                    measurementUnitId: formGroup.fields.measurementUnit.value.value,
                }
                await updateIngredientCommand(ingredientId, bodyUpdate)
            }
            else
                await createIngredientCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <IngredientPanelStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        style={{}}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Tip unitate de masura *"
                        options={measurementUnitTypes?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        }))}
                        selectFilterValue={formGroup.fields.measurementUnitType?.value}
                        onValueChange={e => updateState('measurementUnitType', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.measurementUnitType?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Unitate de masura *"
                        options={measurementUnits?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        })) ?? []}
                        selectFilterValue={formGroup.fields.measurementUnit?.value}
                        onValueChange={e => updateState('measurementUnit', e)}
                        loading={isLoadingMU}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.measurementUnit?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createIngredientAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </IngredientPanelStyled>
    )

    }
)

export default IngredientPanel;