import { Tooltip } from "@mui/material"
import { useCallback } from "react"
import toast, { Toast } from "react-hot-toast"
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { ToastWrapperComponentTypes } from "./toast-wrapper.component.types";
import { ToastWrapperComponentStyled } from "./toast-wrapper.component.styled";

const ToastWrapperComponent = ({
    isError,
    data,
    toastHandle
}: ToastWrapperComponentTypes) => {
    const customToast = (t: Toast, data: string[] | string) => {
        
        return (
            <div>
                <div
                    className={`${
                        t.visible ? 'animate-enter' : 'animate-leave'
                        }`}
                >
                    {

                        typeof data === 'string' ?
                            <>
                                {data}
                            </>
                            :
                            <>
                                {
                                    (data as string[]).map( (m, index) => {
                                        return (
                                                <ul key={index}>
                                                    <li>{m}</li>
                                                </ul>
                                            )
                                        })
                                }
                            </>
                    }
                </div>
                <Tooltip 
                    title="Inchide"
                >
                    <div
                        style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer'
                        }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <CloseIcon />
                    </div>
                </Tooltip>
            </div> 
        )
    }

    return (
        <ToastWrapperComponentStyled>
            {
                customToast(toastHandle, data)
            }
        </ToastWrapperComponentStyled>
    )
}

export default ToastWrapperComponent;
