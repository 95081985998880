import { AxiosRequestConfig, AxiosResponse } from "axios";
import httpStatus from "http-status";
import { StorageEnum } from "../enums/storage.enum";

const responseSuccess = (response: AxiosResponse<any, any>): AxiosResponse<any, any> => {
    if (!response) throw new Error('Raspunsul lipseste');
    if (!response.status)
        throw new Error('Statusul lipsește din răspuns');
    
    if (response.status === httpStatus.UNAUTHORIZED) {
        window.localStorage.clear();

        window.location.href = '/auth/login';
        throw new Error('Acces neautorizat');
    }

    return response;
}

const responseError = async (error: any) => {
    
    if(error?.toString().includes("401")){
        window.localStorage.clear();
        window.location.href = '/auth/login';
    }

    if (!error?.response?.data) {
        const thrownError: any = new Error();
        thrownError.content = "Eroare interna a server-ului. Te rugam sa incerci din nou";
        throw thrownError;
    }

    const { data } = error.response;
   
    if (data.status === httpStatus.FORBIDDEN || data.status === httpStatus.UNAUTHORIZED) {
        window.localStorage.clear();

        if (data.status === httpStatus.FORBIDDEN) {
            window.localStorage.setItem(StorageEnum.AccountDisabledMessage, "eroare");
        }
        window.location.href = '/auth/login';
    }

    if(typeof data === "string"){
        const thrownError: any = new Error();
        thrownError.content = data;
        throw thrownError;
    }

    if(data.message) {
        const thrownError: any = new Error();
        thrownError.content = data.message;
        throw thrownError;
    }

    if(data.errors){
        const thrownError: any = new Error();
        thrownError.content = []
        Object.values(data.errors).map(errors => {
            (errors as string[]).map( err => {
                (thrownError.content as string[]).push(err)
            })
        })
        throw thrownError;
    }

    if(data.error){
        const thrownError: any = new Error();
        thrownError.content = data.error;
        throw thrownError;
    }

    const thrownError: any = new Error();
    thrownError.content = "Eroare netratată";
    throw thrownError;

}

const requestInterceptor = (request: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
    request.maxBodyLength = Infinity;
    request.maxContentLength = Infinity;

    const token = window.localStorage.getItem(StorageEnum.Token);
    if (token && request.headers) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
}

export const requestInterceptors = [requestInterceptor];
export const responseInterceptors = [responseSuccess, responseError];