import { observer } from "mobx-react-lite";
import DeliveryZonePageStyled from "./delivery-zone.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import TabComponent, { TabElementType } from "../../../../components/tab/tab.component";
import DeliveryQuantityTab from "./tabs/delivery-quantity-tab/delivery-quantity-tab.component";
import ProcessedOrdersTab from "./tabs/processed-orders-tab/processed-orders-tab.component";

const DeliveryZonePage = observer(() => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const tabs = useMemo(
        (): TabElementType[] => {
            return [
                {
                    headerTab: {
                        label: 'Comenzi în curs de finalizare',
                        // icon: <Settings />
                    },
                    panel: (
                        () => (
                            <DeliveryQuantityTab
                                selectedTabIndex={selectedTabIndex}
                            />
                        )
                    )
                  
                },
                {
                    headerTab: {
                        label: 'Comenzi procesate',
                        // icon: <Settings />
                    },
                    panel: (
                        () => (
                            <ProcessedOrdersTab 
                                selectedTabIndex={selectedTabIndex}
                            />
                        )
                    )
                  
                }
            ]
        },
        [selectedTabIndex]
    );

    const onTabChangeHandler = useCallback(
        (newTabIndex: number) => {
            setSelectedTabIndex(newTabIndex);
        },
        []
    )

    return (
        <DeliveryZonePageStyled>
            <PageTemplateComponent 
                title="Pregătire comenzi livrare"
            >
                <TabComponent
                    tabs={tabs} 
                    onTabChange={onTabChangeHandler} 
                />
            </PageTemplateComponent>
        </DeliveryZonePageStyled>
    )
})

export default DeliveryZonePage;