import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from '../config/axios.config';
import envVars from '../config/env.config';
import { ContentTypeEnum } from '../enums/content-type.enum';

type RequestParamsType = {
    url: string;
    contentType?: string;
    queryParams?: any;
    ignoreDefaultBaseUrl?: boolean;
    otherSettings?: AxiosRequestConfig<any>
}

export type GetRequestParamsType = RequestParamsType;

export type PostRequestParamsType = {
    body?: any;
} & RequestParamsType;

export type DeleteRequestParamsType = RequestParamsType;

export type PutRequestParamsType = {
    body?: any;
} & RequestParamsType;

export const getRequest = async (requestParams: GetRequestParamsType): Promise<AxiosResponse> => {
    if (!requestParams.url) 
        throw new Error('Va rugam sa folositi un url in momentul in care realizati un request');

    var requestUrl = '';
    if (!requestParams.ignoreDefaultBaseUrl) {
        requestUrl += envVars.apiBaseUrl;
    }
    requestUrl += requestParams.url;

    try {
        const reponse: AxiosResponse = await axios.get(
            requestUrl,
            {
                params: requestParams.queryParams ?? {},
                headers: {
                    "Content-Type": requestParams.contentType ?? ContentTypeEnum.ApplicationJson
                },
                ...requestParams.otherSettings
            }
        );

        return reponse;
    } catch(error: any) {
        throw error;
    }
}

export const postRequest = async (requestParams: PostRequestParamsType): Promise<AxiosResponse> => {
    if (!requestParams.url) 
        throw new Error('Va rugam sa folositi un url in momentul in care realizati un request');

    var requestUrl = '';
    if (!requestParams.ignoreDefaultBaseUrl) {
        requestUrl += envVars.apiBaseUrl;
    }
    requestUrl += requestParams.url;

    try {
        const response = await axios.post(
            requestUrl, 
            requestParams.body ?? {}, 
            {
              headers: { 
                "Content-Type": requestParams.contentType ?? ContentTypeEnum.ApplicationJson
              },
              params: requestParams.queryParams ?? {},
              ...requestParams.otherSettings
            }
        );

        return response;
    } catch(error: any) {
        throw error;
    }
}

export const deleteRequest = async (requestParams: DeleteRequestParamsType): Promise<AxiosResponse> => {
    if (!requestParams.url) 
        throw new Error('Va rugam sa folositi un url in momentul in care realizati un request');

    var requestUrl = '';
    if (!requestParams.ignoreDefaultBaseUrl) {
        requestUrl += envVars.apiBaseUrl;
    }
    requestUrl += requestParams.url;

    try {
        const response: AxiosResponse = await axios.delete(
            requestUrl,
            {
                params: requestParams.queryParams ?? {},
                headers: {
                    "Content-Type": requestParams.contentType ?? ContentTypeEnum.ApplicationJson
                },
                ...requestParams.otherSettings
            }
        );

        return response;
    } catch(error: any) {
        throw error;
    }
}

export const putRequest = async (requestParams: PutRequestParamsType): Promise<AxiosResponse> => {
    if (!requestParams.url) 
        throw new Error('Va rugam sa folositi un url in momentul in care realizati un request');

    var requestUrl = '';
    if (!requestParams.ignoreDefaultBaseUrl) {
        requestUrl += envVars.apiBaseUrl;
    }
    requestUrl += requestParams.url;

    try {
        const reponse = await axios.put(
            requestUrl,
            requestParams.body ?? {},
            {
                headers: {
                    "Content-Type": requestParams.contentType ?? ContentTypeEnum.ApplicationJson
                },
                params: requestParams.queryParams ?? {},
                ...requestParams.otherSettings
            }
        );

        return reponse;
    } catch (error: any) {
        throw error;
    }
}

export const formDataPostRequest = async (requestParams: PostRequestParamsType): Promise<AxiosResponse> => {
    if (!requestParams.url) 
        throw new Error('Va rugam sa folositi un url in momentul in care realizati un request');

    var requestUrl = '';
    if (!requestParams.ignoreDefaultBaseUrl) {
        requestUrl += envVars.apiBaseUrl;
    }
    requestUrl += requestParams.url;

    const formData = new FormData();
    Object.keys(requestParams.body ?? {}).forEach((key: string) => {
        formData.append(key, requestParams.body[key]);
    });

    try {
        const response = await axios.post(
            requestUrl, 
            formData, 
            {
              headers: { 
                "Content-Type": requestParams.contentType ?? ContentTypeEnum.FormData
              },
              params: requestParams.queryParams ?? {},
              ...requestParams.otherSettings
            }
        );

        return response;
    } catch(error: any) {
        throw error;
    }
}