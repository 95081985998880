import { observer } from "mobx-react-lite";
import CreateOrderPanelStyled, { ModalDialogStyled } from "./create-order-panel.component.styled";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { isEmpty } from "lodash";
import { StateFieldType } from "../../types/form.types";
import { displayCustomToast } from "../../utils/display-custom-toast";
// import { FormHelperText, Grid, LinearProgress } from "@mui/material";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import StockSelectComponent from "../stock-select/stock-select.component";
import ButtonComponent from "../button/button.component";
import { Add } from "@mui/icons-material";
import { PanelType } from "../../enums/panel-type.enum";
import SelectOrderProductsComponent from "./components/select-order-products/select-order-products.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { createOrderCommand, getFavoriteItems, getOrderDetails, getOrderPartnerSalePoints, getOrderPartners, updateOrderCommand } from "../../utils/requests";
import CloseIcon from '@mui/icons-material/Close';
import { themeVariables } from "../../theme/variables";
import TextFieldComponent from "../text-field/text-field.component";
import { formatDateRO } from "../../utils/utils";
import { OrderStatusDisplay, OrderStatusEnum } from "../../enums/order-status.enum";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import DescriptionIcon from '@mui/icons-material/Description';
import AddDescriptionModal from "./components/add-description-modal/add-description-modal.dialog";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";

export type StateType = {
    fields: {
        partner: StateFieldType<any>;
        salePoint: StateFieldType<any>;
        description: StateFieldType<string>;
        orderItems: StateFieldType<any[]>;

    };
    shouldDisplayError: boolean;
}

type CreateOrderPanelPropsType = {
    orderId?: number,
    defaultPartner?: {
        label: string,
        value: number
    },
    defaultSalePoint?: {
        label: string,
        value: number
    }
}

const CreateOrderPanel = observer(({
    orderId,
    defaultPartner,
    defaultSalePoint
}: CreateOrderPanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            partner: {
                value: null,
                isValid: false,
                noValidation: false
            },
            salePoint: {
                value: null,
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            orderItems: {
                value: [],
                isValid: false,
                noValidation: false
            }
        },
        shouldDisplayError: false
    })
    const [users, setUsers] = useState<any[]>([]);
    const [salePoints, setSalePoints] = useState<any[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [loadingSP, setLoadingSP] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const uiStore = useStore('uiStore');
    const [tableData, setTableData] = useState<any>()
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [editOrderDetails, setEditOrderDetails] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    useEffect(
        () => {
            if(!orderId) return;

            setLoadingData(() => true);
            getOrderDetails(orderId)
            .then(data => {
                updateState("orderItems", data.orderItems.map((m: any) => ({
                    ...m,
                    id: m.itemId,
                    name: m.itemName,
                    error: false,
                    description: m.description
                })));

                updateState('partner', {
                    label: data.partnerName,
                    value: data.partnerId
                })

                updateState('salePoint', {
                    label: data.salePointName,
                    value: data.salePointId
                })

                updateState('description', data.description)

                setEditOrderDetails(() => ({
                    orderStatusId: data.orderStatusId,
                    orderDate: formatDateRO(data.orderDate),
                    deliveryDate: formatDateRO(data.deliveryDate) 
                }))

                setLoadingData(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingData(() => false);
            })
        },
        [orderId]
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            setLoadingData(() => true)

            getOrderPartners()
            .then(data => {
                setUsers(() => data); 
                setLoadingData(() => false);
                if(defaultPartner)
                    updateState('partner', defaultPartner);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingData(() => false)
            })
        },
        []
    )

    useEffect(
        () => {
            if(!!orderId) return;
            updateState('salePoint', null);
            if(formGroup.fields.partner.value === null) return;

            setLoadingSP(() => true);
            getOrderPartnerSalePoints(formGroup.fields.partner.value.value)
            .then(data => {
                setSalePoints(() => data);
                setLoadingSP(() => false);
                if(defaultSalePoint)
                    updateState('salePoint', defaultSalePoint);

            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadingSP(() => false);
            })
        },
        [formGroup.fields.partner.value, orderId]
    )

    useEffect(
        () => {
            if(!!orderId) return;
            if(!formGroup.fields.salePoint.value) return;

            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getFavoriteItems({
                        getAllElements: true,
                        salePointId: formGroup.fields.salePoint.value.value
                    });
                    updateState('orderItems', fetchData.data.map((m: any) => ({
                        id: m.itemId,
                        name: m.itemName,
                        quantity: 0,
                        spoilageQuantity: 0,
                        error: false,
                        description: ""
                    })))
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }

            getData();
        },
        [formGroup.fields.salePoint.value, orderId]
    )

    const onChooseProducts = () => {
        uiStore.openPanel({
            key: PanelType.AddProductOrder,
            title: "Selecteaza produsele",
            panelWidth: '800px',
            component: <SelectOrderProductsComponent
                selectedItems={formGroup.fields.orderItems.value}
                setSelectedItems={(value) => updateState('orderItems', value.map((m: any) => ({
                    ...m,
                    quantity: formGroup.fields.orderItems.value?.find(f => f.id === m.id)?.quantity ?? 0,
                    spoilageQuantity: formGroup.fields.orderItems.value?.find(f => f.id === m.id)?.spoilageQuantity ?? 0,
                    error: false,
                    description: ""
                })))}
            />,
            onDismiss: (data) => {
                setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    pageIndex: 0
                }))
                if (!data) return;
            }
        })
    }
    
    useEffect (
        () => {
            if(!formGroup.fields.orderItems.value) setTableData(() => ({
                data: [],
                headers: []
            })) 

            setTableData (() => ({
                data: formGroup.fields.orderItems.value,
                headers: [
                //    {
                //        id: 'id',
                //        label: 'Id',
                //        headerType: HeaderTypeEnum.Numeric,
                //        alignment: 'left',
                //        sortable: false
                //    },
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'quantity',
                        label: 'Cantitate Produs',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    !users.find(f => f.id === formGroup.fields.partner?.value?.value)?.hasSpoilage ? undefined :
                   {
                        id: 'spoilageQuantity',
                        label: 'Cantitate Rebuturi',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                ].filter(f => f),
                totalElements: formGroup.fields.orderItems.value.length
            })) 
        },
        [formGroup.fields.orderItems.value]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                quantity: (row: any) => {
                    return (
                        <StockSelectComponent
                            size="small"
                            variant="standard"
                            maxWidth='100px'
                            value={row.quantity}
                            onTextChange={(value: string) => setFormGroup((state: any) => ({
                                ...state,
                                fields: {
                                    ...state.fields,
                                    orderItems: {
                                        ...state.fields.orderItems,
                                        value: state.fields.orderItems.value.map((m: any) => {
                                            if(m.id === row.id){
                                                return {
                                                    ...m,
                                                    quantity: value,
                                                    error: parseInt(value) > 0 ? false : true
                                                }
                                            }

                                            return m;
                                        })
                                    }
                                }
                            }))}
                        />
                    )
                },
                spoilageQuantity: (row: any) => {
                    return (
                        <StockSelectComponent
                            size="small"
                            variant="standard"
                            maxWidth='100px'
                            value={row.spoilageQuantity}
                            onTextChange={(value: any) => setFormGroup((state: any) => ({
                                ...state,
                                fields: {
                                    ...state.fields,
                                    orderItems: {
                                        ...state.fields.orderItems,
                                        value: state.fields.orderItems.value.map((m: any) => {
                                            if(m.id === row.id){
                                                return {
                                                    ...m,
                                                    spoilageQuantity: value
                                                }
                                            }

                                            return m;
                                        })
                                    }
                                }
                            }))}
                            error={formGroup?.shouldDisplayError && formGroup.fields.orderItems?.value[row.id].error}
                        />
                    )
                },
            }
        },
        []
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                editOrderDetails?.orderStatusId > 1 ? undefined :
                {
                    text: "Elimina produsul",
                    icon: <CloseIcon color="error"  />,
                    color: "red",
                    fOnClick: (row: any) => {
                        setFormGroup((state: any) => ({
                            ...state,
                            fields: {
                                ...state.fields,
                                orderItems: {
                                    ...state.fields.orderItems,
                                    value: state.fields.orderItems.value.filter((f: any) => f.id != row.id),
                                    isValid: isEmpty(state.fields.orderItems.value.filter((f: any) => f.id != row.id)) ? false : true
                                }
                            }
                        }))
                    }
                },
                {
                    text: `${editOrderDetails?.orderStatusId > 1 ? "Vezi descriere" : "Seteaza descriere"}` ,
                    icon: <DescriptionIcon  />,
                    color: "#ffab24",
                    fOnClick: (row: any) => {
                        uiStore.openModal({
                            title: `Seteaza descriere produs: ${row.name}`,
                            component: (
                                <AddDescriptionModal
                                    description={formGroup.fields.orderItems.value.find(f => f.id === row.id).description}
                                    setDescription={(value) => updateState('orderItems', formGroup.fields.orderItems.value.map((m: any) => ({
                                        ...m,
                                        description: m.id === row.id ? value : m.description
                                    })))}
                                    placeholder="Descriere produs"
                                    viewMode={editOrderDetails?.orderStatusId > 1}
                                /> 
                            ),
                        })
                    }
                }
            ].filter(f => f)
        },
        [actionItemsClick, formGroup]
    );

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide", true);
            return;
        };

        const hasValueQuantity = formGroup.fields.orderItems.value.every(f => {
            if(f.quantity === 0 && f.spoilageQuantity === 0)
                return false;
            return true;
        })

        if(!hasValueQuantity){
            const response = await uiStore.openDialog({
                title: "Produse fară cantitate",
                message: "Unele produse au cantitate 0 și vor fi ignorate la crearea comenzii, sunteți sigur că vreți să continutați?"
            })

            if(response.value === DialogResponseTypeEnum.Confirm) {
                try {
                    if(orderId != undefined){
                        let bodyUpdate = {
                            orderId: orderId,
                            description: formGroup.fields.description.value,
                            orderItems: formGroup.fields.orderItems.value.map(m => ({
                                itemId: m.id,
                                quantity: m.quantity,
                                description: m.description,
                                spoilageQuantity: m.spoilageQuantity
                            }))
                        }
                        await updateOrderCommand(bodyUpdate)
                    }
                    else {
                        let body = {
                            partnerId: formGroup.fields.partner.value.value,
                            salePointId: formGroup.fields.salePoint.value.value,
                            description: formGroup.fields.description.value,
                            orderItems: formGroup.fields.orderItems.value.map(m => ({
                                itemId: m.id,
                                quantity: m.quantity,
                                description: m.description,
                                spoilageQuantity: m.spoilageQuantity
                            }))
                        }

                        await createOrderCommand(body);
                    }

                    setIsSaving(() => false);
                    displayCustomToast("Datele au fost salvate cu succes!");
                    uiStore.dismissPanel({
                        data: {
                            refreshData: true
                        }
                    });
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsSaving(() => false);
                }

                return;
            } else {
                setIsSaving(() => false);
            }
        } else {
            try {
                if(orderId != undefined){
                    let bodyUpdate = {
                        orderId: orderId,
                        description: formGroup.fields.description.value,
                        orderItems: formGroup.fields.orderItems.value.map(m => ({
                            itemId: m.id,
                            quantity: m.quantity,
                            description: m.description,
                            spoilageQuantity: m.spoilageQuantity
                        }))
                    }
                    await updateOrderCommand(bodyUpdate)
                }
                else {
                    let body = {
                        partnerId: formGroup.fields.partner.value.value,
                        salePointId: formGroup.fields.salePoint.value.value,
                        description: formGroup.fields.description.value,
                        orderItems: formGroup.fields.orderItems.value.map(m => ({
                            itemId: m.id,
                            quantity: m.quantity,
                            description: m.description,
                            spoilageQuantity: m.spoilageQuantity
                        }))
                    }

                    await createOrderCommand(body);
                }

                setIsSaving(() => false);
                displayCustomToast("Datele au fost salvate cu succes!");
                uiStore.dismissPanel({
                    data: {
                        refreshData: true
                    }
                });
            } catch (error: any) {
                displayCustomToast(error.content, true);
                setIsSaving(() => false);
            }
        }

    } 

    return (
        <CreateOrderPanelStyled>
            {
                loadingData ? (
                    <LinearProgress sx={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Partneri *"
                            options={users?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.partner?.value}
                            onValueChange={e => updateState('partner', e)}
                            loading={loadingData}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.partner?.isValid}
                            disabled={!!orderId}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Puncte de vanzare *"
                            options={salePoints?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.salePoint?.value}
                            onValueChange={e => updateState('salePoint', e)}
                            loading={loadingSP}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.salePoint?.isValid}
                            disabled={!!orderId}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    {
                        !!orderId ?
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    label="Status comanda"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    value={OrderStatusDisplay[(editOrderDetails?.orderStatusId as OrderStatusEnum)]}
                                    disabled
                                />
                            </Grid>
                            :
                            <></>
                    }
                    {
                        !!orderId ?
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    label="Data comanda"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    value={editOrderDetails?.orderDate}
                                    disabled
                                />
                            </Grid>
                            :
                            <></>
                    }
                    {
                        !!orderId ?
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    label="Data livrare"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    value={editOrderDetails?.deliveryDate}
                                    disabled
                                />
                            </Grid>
                            :
                            <></>
                    }
                    
                    <Grid item xs={12}>
                        <label htmlFor="description">Descriere:</label>
                        <textarea
                            id="description"
                            className="text-area"
                            rows={3}
                            placeholder="Descriere"
                            value={formGroup.fields.description?.value ?? ""}
                            onChange={e => updateState('description', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {
                            !isEmpty(formGroup.fields.orderItems.value) ?
                                <TableComponent
                                    tableKey={TableTypeEnum.SelectedItems} 
                                    viewType="panel"
                                    tableData={tableData}
                                    searchBarPlaceholder="Cauta dupa nume"
                                    withoutSelect
                                    tableState={filtersTable}
                                    setTableState={setFilters}
                                    withoutSearchBar
                                    withoutDenseSwitch
                                    denseByDefault
                                    customRenderer={customRenderer}
                                    actionItems={actionItems}
                                    actionItemsClick={setActionItemsClick}
                                    noRequest
                                />
                                :
                                <FormHelperText 
                                    sx={{ 
                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                        marginLeft: 0,
                                        color: `${isEmpty(formGroup.fields.orderItems.value) && formGroup.shouldDisplayError ? '#cb5765' : 'rgba(0, 0, 0, 0.6)'}`
                                    }}
                                >
                                    Nu aveti produse selectate
                                </FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        {
                            editOrderDetails?.orderStatusId > 1 ?
                                <></>
                                :
                                <ButtonComponent
                                    variant="text"
                                    onClick={onChooseProducts}
                                    size="medium"
                                    startIcon={<Add />}
                                >
                                {
                                        isEmpty(formGroup.fields.orderItems?.value) ?
                                            "Selecteaza produse *"
                                            : 
                                            "Editeaza produsele *"
                                }
                                </ButtonComponent>
                        }
                    </Grid>

                    {
                        editOrderDetails?.orderStatusId > 1 ?
                            <></>
                            :
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "1rem"
                                    }}
                                >
                                    <ButtonComponent 
                                        onClick={createAction} 
                                        isLoading={isSaving}
                                        sx={{
                                            width: "5rem"
                                        }}
                                    >
                                        Salveaza
                                    </ButtonComponent>
                                </div>
                            </Grid>
                    }      

                </Grid>
            }
        </CreateOrderPanelStyled>
    )
})

export default CreateOrderPanel;