import { Button, ButtonProps } from "@mui/material";
import React from "react";
import LoaderComponent from "../loader/loader.component";
import {ButtonComponentStyled} from "./button.component.styled";

export type ButtonComponentPropsType = {
    children?: React.ReactNode;
    isLoading?: boolean;
} & ButtonProps;

const ButtonComponent = ({
    children,
    isLoading = false,
    fullWidth = false,
    ...rest
}: ButtonComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <ButtonComponentStyled fullWidth={fullWidth}>
            <Button
                size="small"
                fullWidth={fullWidth}
                disableFocusRipple={true}
                variant="contained"
                disabled={isLoading}
                {...rest} 
            >
                { isLoading ? <LoaderComponent loaderSize={17} /> : children  }
            </Button>
        </ButtonComponentStyled>
    )

}

export default ButtonComponent;