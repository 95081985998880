import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import CategoriesPageStyled from "./categories.page.styled";
import { observer } from 'mobx-react-lite';
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import CategoryPanelStyled from "../../../../components/category-panel/category-panel.component.styled";
import CategoryPanel from "../../../../components/category-panel/category-panel.component";
import { getAllCategories } from "../../../../utils/requests";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";

const CategoriesPage = observer(() => {

    // Categories
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [categories, setCategories] = useState<any>();
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });
    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createCategory = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateCategory,
                component: <CategoryPanel />,
                panelWidth: '700px',
                title: `Creare categorie`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setCategoriesLoading(() => true)
                    let fetchJobs = await getAllCategories({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setCategories(() => fetchJobs)
                    setCategoriesLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setCategoriesLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableDataCategories = useMemo(
        (): TableDataPropsType => {
            if(!categories) return {
                data: [],
                headers: []
            }

            return {
                data: categories.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'isActive',
                        label: 'Activ',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: categories.totalNumber
            }
        },
        [categories]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza Categorie",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditCategory,
                            component: <CategoryPanel 
                                categoryId={row.id}
                                categoryDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza categoria: ${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    /** define the state bellow */
    return (
        <CategoriesPageStyled>
            <PageTemplateComponent 
                title="Categorii"
                buttons={[
                    {
                        fOnClick: () => {
                            createCategory()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllCategories} 
                    viewType="normal"
                    tableData={tableDataCategories}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={categoriesLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </CategoriesPageStyled>
    )

})

export default CategoriesPage;