import { observer } from "mobx-react-lite";
import SalePointsPartnerPanelActionStyled from "./sale-points-partner-panel-action.component.styled";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import TextFieldComponent from "../../text-field/text-field.component";
import BasicSelect from "../../dropdown-table-component/dropdown-table-component.component";
import ButtonComponent from "../../button/button.component";
import { isEmpty } from "lodash";
import { useStore } from "../../../hooks/store.hook";
import { useEffect, useState } from "react";
import { createPartnerSalePointCommand, getDeliveryRoutes, updatePartnerSalePointCommand } from "../../../utils/requests";
import { displayCustomToast } from "../../../utils/display-custom-toast";
import { StateFieldType } from "../../../types/form.types";
import { themeVariables } from "../../../theme/variables";

type SalePointsPartnerPanelActionPropsType = {
    partnerId?: number,
    salePointId?: number,
    salePoinsDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        address: StateFieldType<string>;
        description: StateFieldType<string>;
        phone: StateFieldType<string>;
        deliveryRoute: StateFieldType<any>;
        isActive?: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}


const SalePointsPartnerPanelAction = observer(({
    partnerId,
    salePointId,
    salePoinsDetails
}: SalePointsPartnerPanelActionPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            address: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            phone: {
                value: "",
                isValid: false,
                noValidation: false
            },
            deliveryRoute: {
                value: null,
                isValid: false,
                noValidation: false
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    });
    const uiStore = useStore('uiStore');

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [loadDR, setLoadDR] = useState<boolean>(false);

    useEffect(
        () => {
            if(!salePoinsDetails) return;

            updateState('name', salePoinsDetails.name);
            updateState('address', salePoinsDetails.address);
            updateState('description', salePoinsDetails.description);
            updateState('phone', salePoinsDetails.phone);
            updateState('isActive', salePoinsDetails.isActive);
            updateState('deliveryRoute', {
                label: salePoinsDetails.deliveryRoute.name,
                value: salePoinsDetails.deliveryRoute.id
            });
        },
        [salePoinsDetails]
    )

    useEffect(
        () => {
            setLoadDR(() => true);

            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
                setLoadDR(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setLoadDR(() => false);
            })
        },
        []
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            address: formGroup.fields.address.value,
            phone: formGroup.fields.phone.value,
            description: formGroup.fields.description.value,
            deliveryRouteId: formGroup.fields.deliveryRoute.value.value,
            partnerId: partnerId
        }

        try {
            if(salePointId != undefined){
                let bodyUpdate = {
                    id: salePointId,
                    isActive: formGroup.fields.isActive?.value,
                    ...body
                }
                await updatePartnerSalePointCommand(salePointId, bodyUpdate)
            }
            else
                await createPartnerSalePointCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <SalePointsPartnerPanelActionStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Descriere"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.description?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.description?.isValid}
                        onTextChange={(value: any) => updateState('description', value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Adresa"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.address?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.address?.isValid}
                        onTextChange={(value: any) => updateState('address', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Numar de telefon"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.phone?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.phone?.isValid}
                        onTextChange={(value: any) => updateState('phone', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Ruta de livrare *"
                        options={deliveryRoutes?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        })) ?? []}
                        selectFilterValue={formGroup.fields.deliveryRoute?.value}
                        onValueChange={e => updateState('deliveryRoute', e)}
                        loading={loadDR}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.deliveryRoute?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                {
                    !(salePointId === undefined) ?
                        <Grid item xs={12}>
                            <FormControl
                                error={!formGroup.fields.isActive?.value}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formGroup.fields.isActive?.value}
                                        onChange={e => updateState('isActive', e.target.checked)}
                                    />}
                                    label="Activ"
                                />
                                {
                                    !formGroup.fields.isActive?.value ?
                                        <FormHelperText 
                                            sx={{ 
                                                fontSize: `${themeVariables.fontSizes.normal}`,
                                                marginLeft: 0
                                            }}>
                                                Punctul de vanzare nu o sa mai fie activ
                                            </FormHelperText>
                                        :
                                        <></>
                                }
                            </FormControl>
                        </Grid>
                        :
                        <></>
                }

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "1rem"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </SalePointsPartnerPanelActionStyled>
    )
})

export default SalePointsPartnerPanelAction;