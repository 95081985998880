import styled from "styled-components";
import { themeVariables } from "../../../../theme/variables";

const ProductionZonePageStyled = styled.div`

    .item-card {
        color: white;
        padding: 1rem;
        border-radius: 8px;
    }

    .item-card-undone {
        border: 1px solid ${themeVariables.colors.error};
        background-color: ${themeVariables.colors.error};
    }

    .item-card-done {
        border: 1px solid ${themeVariables.colors.success};
        background-color: ${themeVariables.colors.success};
    }

    .divider-style {
        margin-bottom: 1rem;
        background-color: #b8b8b8;
        border-width: 2px;
        border-radius: 16px;
    }

    .container-styled {
        text-align: center;
        align-items: center;
        padding: 0;
    }

    .link-styled {
        cursor: pointer;
        color: white;
    }

`;

export default ProductionZonePageStyled;