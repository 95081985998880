import styled from "styled-components";

export type ButtonComponentStyledPropsType = {
    fullWidth?: boolean;
}

export const ButtonComponentStyled = styled.div<ButtonComponentStyledPropsType>`
    display: flex;
    align-items: center;
    width: ${props => props.fullWidth ? '100%' : 'initial'};

    .MuiButton-root {
        text-transform: none; 
        font-weight: 600;
    }

    .MuiButton-contained {
        padding: 0.7rem 1rem;
        border-radius: 10px; 
    }

    .MuiButton-outlined {
        border-radius: 10px; 
    }
`;
