import { observer } from "mobx-react-lite";
import ItemsPageStyled from "./items.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import ItemPanel from "../../../../components/item-panel/item-panel.component";
import { getAllItems, getItemExportOrder, updateItemExportOrder } from "../../../../utils/requests";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import ItemIngredientPanel from "../../../../components/Item-ingredients-panel/Item-ingredients-panel";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import UpdateImageItem from "../../../../components/Item-ingredients-panel/components/update-image-item/update-image-item.component";
import PhotoIcon from '@mui/icons-material/Photo';
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import DraggableListComponent from "../../../../components/draggable-list/draggable-list.component";
import SaveIcon from '@mui/icons-material/Save';

const ItemsPage = observer(() => {
   
    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateItem,
                component: <ItemPanel />,
                panelWidth: '700px',
                title: `Creare produs`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllItems({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'isActive',
                        label: 'Activ',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Ingrediente produs",
                    icon: <SettingsIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.ItemIgredients,
                            component: <ItemIngredientPanel
                                itemId={row.id}
                            />,
                            panelWidth: '900px',
                            title: `${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                    
                                    if(data?.refreshData)
                                        setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Editeaza produs",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItem,
                            component: <ItemPanel itemId={row.id} />,
                            panelWidth: '700px',
                            title: `Editeaza produsul: ${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Seteaza imagine",
                    icon: <PhotoIcon />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.UpdateImageItem,
                            component: <UpdateImageItem 
                                itemId={row.id} 
                            />,
                            panelWidth: '700px',
                            title: `Seteaza imagine: ${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    const sortItemsExport = async () => {
        let itemsExport = await getItemExportOrder();

        uiStore.openModal({
            component: <DraggableListComponent
                data={itemsExport}
            />,
            actionItems: [
                {
                    icon: <SaveIcon />,
                    onClick: async () => {
                        let newData = uiStore.sortOrderItmes.map((item, index) => ({
                            ...item,
                            order: index
                        }));

                        try {
                            await updateItemExportOrder({
                                items: newData
                            });
                            displayCustomToast("Datele s-au salvat cu succes!");
                            uiStore.setSortOrderItems([]);
                            uiStore.dismissModal();
                        } catch (error: any) {
                            displayCustomToast(`${error.content}`, true)
                        }

                    },
                    tooltipText: "Salveaza",
                }
            ]
        })
    }

    return (
        <ItemsPageStyled>
            <PageTemplateComponent 
                title="Produse"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    },
                    {
                        text: "Sorteaza produse",
                        fOnClick: sortItemsExport
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllItems} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </ItemsPageStyled>
    )
})

export default ItemsPage;