import { useRoutes, Navigate } from 'react-router';
import PrivateRoute from './components/auth-guard/auth-guard.component';
import AuthModule from './modules/auth/auth.module';
import MainModule from './modules/main/main.module';
import NotFoundPage from './modules/not-found/not-found.page';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getLicense } from './utils/requests';
import { useStore } from './hooks/store.hook';

const AppRouter = observer(() => {

    const globalStore = useStore('globalStore');

    useEffect(
        () => {
            getLicense()
            .then(data => {
                globalStore.setLicense(data);
            })
        },
        []
    )

    let element = useRoutes([
        {
            path: 'auth/*',
            element: <AuthModule />
        },
        {
            path: 'app/*',
            element: <PrivateRoute element={<MainModule />}/>
        },
        {
            path: '/',
            element: <Navigate to="/app" />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]);

    return element;

})

export default AppRouter;