import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const NotFoundPageStyled = styled.div`

    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-container {
        padding: 5rem;
        border: 1px solid lightgray;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .image-section {
            width: 45vw;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .content-section {
            margin-top: 2rem;
            text-align: center;

            .page-title {
                font-weight: ${themeVariables.fontWeights.semibold};
                color: ${themeVariables.textColors.gray};
                font-size: ${themeVariables.fontSizes.medium};
                margin-bottom: 1rem;
            }

            .button-component {
                
            }
        }

        img {

        }
    }

`;

export default NotFoundPageStyled;