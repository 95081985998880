import styled, { keyframes } from "styled-components";
import { themeVariables } from "../../theme/variables";

export type ModalComponentStyledPropsType = {
    isOpen?: boolean;
    fullScreen?: boolean;
}

const fullScreenEnterAnimation = keyframes`
    0% {
        transform: translateY(500px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;   
    }
`;

const enterAnimation = keyframes`
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;   
    }
`;

const ModalComponentStyled = styled.div<ModalComponentStyledPropsType>`

    position: fixed;
    top: ${props => props.isOpen ? 0 : 'unset'};
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${props => props.isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
    backdrop-filter: blur(1px);
    transition: background-color 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    

    .inner-modal-contents {
        margin: 0;
        width: ${props => props.fullScreen ? '100vw' : '50vw'};
        height: ${props => props.fullScreen ? '100vh' : '61vh'};
        border-radius: ${props => props.fullScreen ? '0' : '12px'};
        background: #fff;
        animation-name: ${props => props.fullScreen ? fullScreenEnterAnimation : enterAnimation};
        animation-duration: 125ms;
        display: flex;
        flex-direction: column;
        overflow: auto;
        

        .modal-header {

            .modal-header-contents {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-weight: ${themeVariables.fontWeights.semibold};
            }

            .modal-buttons {
                display: flex;
                align-items: center;
            }

        }

        .modal-body {
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;
            flex: 1;
        }
    }

`;

export default ModalComponentStyled;