import styled, { css } from "styled-components";
import { themeVariables } from "../../theme/variables";

export type AvatarComponentStyledPropsType = {
    clickable?: boolean;
}

const clickableStyles = css`

    transition: all 250ms;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

`;

const AvatarComponentStyled = styled.div<AvatarComponentStyledPropsType>`

    background: ${themeVariables.colors.primary};
    color: #ffffff;
    font-weight: ${themeVariables.fontWeights.medium} !important;
    font-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;

    ${props => props.clickable ? clickableStyles : ''}

    .profile-avatar-default-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

`;

export default AvatarComponentStyled;