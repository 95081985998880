import { useEffect, useRef, useState } from "react"
import DropzoneUploadComponent from "../../../dropzone-upload/dropzone-upload.component"
import UpdateImageItemStyled from "./update-image-item.component.styled"
import { deleteImageItem, getImageItem, saveImageItem } from "../../../../utils/requests"
import { displayCustomToast } from "../../../../utils/display-custom-toast"

type UpdateImageItemPropsType = {
    itemId?: number
}

const UpdateImageItem = ({
    itemId
}: UpdateImageItemPropsType) => {

    const [image, setImage] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [blobImage, setBlobImage] = useState<any>()
    const inputRef = useRef(null);

    useEffect(
        () => {
            getImageItem(itemId)   
            .then(data => {
                if(data.hasValue)
                    setImage(() => [data]);
                setIsLoading(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setIsLoading(() => false);
            })
        },
        [itemId]
    )

    const saveImageAction = async () => {

        try {
            const formData = new FormData();
            formData.append("image", blobImage);
            formData.append("itemId", itemId as any);

            let resp =  await saveImageItem(formData);
            
            setImage([resp])
            displayCustomToast("Fișierul a fost încărcat cu succes!");
            setIsLoading(() => false);
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    useEffect(
        () => {
            if(!blobImage) return;

            saveImageAction()
        },
        [blobImage]
    )

    async function deleteImage(): Promise<any> {
        if(!setIsLoading) return;
        try {
            setIsLoading(() => true);

            await deleteImageItem({
                itemId: itemId
            })

            /** we do this so we can reupload the same file (might not work in all the major browsers. Only tested in Microsoft Edge) */
            if (inputRef.current) {
                (inputRef.current as any).value = ''
            }

            setImage([]);
            displayCustomToast("Fișierul a fost sters cu succes!");
            setIsLoading(() => false);
        } catch(error: any) {
            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    return (
        <UpdateImageItemStyled>
            <DropzoneUploadComponent 
                value={image}
                setBlobImage={setBlobImage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                deleteImage={deleteImage}
                inputRef={inputRef}
            />
        </UpdateImageItemStyled>
    )
}

export default UpdateImageItem;