import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { RoleEnum } from "../../enums/role.enum";
import { StateFieldType } from "../../types/form.types";
import { UpdateSelfType, UpdateUserType, UserType } from "../../types/user.type";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import RoleComponent from "../role/role.component";
import TextFieldComponent from "../text-field/text-field.component";
import ProfileGeneralDetailsComponentStyled from "./profile-general-details.component.styled";

type StateType = {
    fields: {
        email: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

export type ProfileGeneralDetailsComponentPropsType = {
    user: UserType | null,
    isSelf: boolean; 
    isLoading?: boolean;
    onSave: (userDetails: UpdateSelfType | UpdateUserType) => void
}

const ProfileGeneralDetailsComponent = ({
    user,
    isLoading,
    isSelf,
    onSave
}: ProfileGeneralDetailsComponentPropsType) => {

    const [formState, setFormState] = useState<StateType>({
        fields: {
            email: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o adresa de email valida'
            },
            firstName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un prenume valid'
            },
            lastName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume valid'
            }
        },
        shouldDisplayError: false
    });
    const [roles, setRoles] = useState<RoleEnum[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!event?.currentTarget) return;
            setAnchorEl(() => event.currentTarget);
        },
        []
    );
    
    const handleClose = React.useCallback(
        () => {
            setAnchorEl(() =>null);
        },
        []
    );

    useEffect(
        () => {
            setFormState(formState => ({
                ...formState,
                fields: {
                    email: {
                        ...formState.fields.email,
                        value: user?.email ?? '',
                        isValid: formState.fields.email.validator ? (formState.fields.email.validator)(user?.email ?? '') : !!(user?.email ?? '')
                    },
                    firstName: {
                        ...formState.fields.firstName,
                        value: user?.firstName ?? '',
                        isValid: formState.fields.firstName.validator ? (formState.fields.firstName.validator)(user?.firstName ?? '') : !!(user?.firstName ?? '')
                    },
                    lastName: {
                        ...formState.fields.lastName,
                        value: user?.lastName ?? '',
                        isValid: formState.fields.lastName.validator ? (formState.fields.lastName.validator)(user?.lastName ?? '') : !!(user?.lastName ?? '')
                    }
                }
            }));

            setRoles(() => user?.roles ?? []);
        },
        [user]
    )

    const updateState = useCallback(
        (field: 'firstName' | 'lastName', newValue: string) => {
            setFormState(formState => ({
                ...formState,
                fields: {
                    ...formState.fields,
                    [field]: {
                        ...formState.fields[field],
                        isValid: formState.fields[field].validator ? (formState.fields[field].validator as any)(newValue) : !!newValue,
                        value: newValue
                    }
                }
            }))
        },
        []
    )

    const updateUserDetails = useCallback(
        () => {
            setFormState(formState => ({
                ...formState,
                shouldDisplayError: true
            }));

            let userDetails: UpdateSelfType | UpdateUserType = {
                firstName: formState.fields.firstName.value,
                lastName: formState.fields.lastName.value,
            }

            if (!isSelf)
                (userDetails as UpdateUserType).roles = roles;

            if (!userDetails.firstName || !userDetails.lastName) return;

            onSave(userDetails);
        },
        [onSave, roles, formState, isSelf]
    );

    const onRoleDelete = useCallback(
        (role: RoleEnum) => {
            setRoles(currentRoles => currentRoles.filter(currentRole => currentRole !== role));
        },
        []
    )

    const onAddRole = useCallback(
        (role: RoleEnum) => {
            setRoles(currentRoles => [...currentRoles, role]);
        },
        []
    )

    const getAvailableOptions = useCallback(
        (): RoleEnum[] => {
            var allRoles = Object.values(RoleEnum);
            return allRoles.filter( role => !roles.includes(role) );
        },
        [roles]
    )


    /** define the return statement bellow */
    return (
        <ProfileGeneralDetailsComponentStyled>

            <FormSectionComponent title="Detalii generale">
                <Grid container spacing={2}>  

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Adresa de email" 
                            variant="outlined" 
                            fullWidth={true}
                            disabled={true}
                            value={formState.fields.email.value}
                            error={formState.shouldDisplayError && !formState.fields.email.isValid}
                            helperText={formState.shouldDisplayError && !formState.fields.email.isValid && formState.fields.email.errorMessage}
                        />
                    </Grid>

                    <Grid item xs={6}>

                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent 
                            label="Prenume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={formState.fields.firstName.value}
                            error={formState.shouldDisplayError && !formState.fields.firstName.isValid}
                            helperText={formState.shouldDisplayError && !formState.fields.firstName.isValid && formState.fields.firstName.errorMessage}
                            onTextChange={(e: string) => updateState('firstName', e)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent 
                            label="Nume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={formState.fields.lastName.value}
                            error={formState.shouldDisplayError && !formState.fields.lastName.isValid}
                            helperText={formState.shouldDisplayError && !formState.fields.lastName.isValid && formState.fields.lastName.errorMessage}
                            onTextChange={(e: string) => updateState('lastName', e)}
                        />
                    </Grid>

                </Grid>

                {
                        isSelf ?
                        <div className="button-container">
                            <ButtonComponent onClick={updateUserDetails} isLoading={isLoading}>
                                Salveaza
                            </ButtonComponent>
                        </div>: ''
                }
            </FormSectionComponent>

            <FormSectionComponent title="Roluri">
                <RoleComponent 
                    anchorEl={anchorEl} 
                    getAvailableOptions={getAvailableOptions} 
                    handleClick={handleClick}
                    handleClose={handleClose}
                    onRoleAdd={onAddRole}
                    roles={roles}
                    onRoleDelete={onRoleDelete}
                    isSelf={isSelf}
                />
                {
                    !isSelf ?
                    <div className="button-container">
                        <ButtonComponent onClick={updateUserDetails} isLoading={isLoading}>
                            Salveaza
                        </ButtonComponent>
                    </div> :
                    ''
                }

            </FormSectionComponent>

        </ProfileGeneralDetailsComponentStyled>
    )

}

export default ProfileGeneralDetailsComponent;