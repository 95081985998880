import { IndexRouteProps, LayoutRouteProps, PathRouteProps, Navigate } from "react-router-dom";
import { StorageEnum } from "../../enums/storage.enum";

const PrivateRoute = (props: JSX.IntrinsicAttributes & (PathRouteProps | LayoutRouteProps | IndexRouteProps)) => {
  const token = localStorage.getItem(StorageEnum.Token);
  return <>{token ? props.element  : <Navigate to="/auth/login" />}</>;
};

export default PrivateRoute;






