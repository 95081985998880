import React, { useCallback } from "react";
import NotFoundPageStyled from "./not-found.page.styled";
import imageSrc from '../../assets/images/404_page_illustration.png';
import ButtonComponent from "../../components/button/button.component";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {

    /** inject navigator to navigate to other routes */
    const navigate = useNavigate();

    const onButtonClick = useCallback(
        () => {
            navigate('/app/dashboard');
        },
        [navigate]
    )


    /** define the return logic bellow */
    return (
        <NotFoundPageStyled>
            <div className="inner-container">

                <div className="image-section">
                    <img src={imageSrc} alt="Not found" />
                </div>

                <div className="content-section">
                    <div className="page-title">
                        Pagina cautata nu exista :(
                    </div>
                    
                    <ButtonComponent onClick={onButtonClick}>
                        Inapoi la dashboard
                    </ButtonComponent>
                </div>

            </div>
        </NotFoundPageStyled>
    )

}

export default NotFoundPage;