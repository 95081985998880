import { observer } from "mobx-react-lite";
import DailyObservationsDeliveryRoutePageStyled from "./daily-observations-delivery-route.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { addDailyObservationDeliveryRoute, getDailyObservationsDeliveryRoutes, getDeliveryRoutes } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { EditOutlined } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { Box } from "@mui/material";
import { formatDate } from "../../../../utils/utils";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import AddDescriptionModal from "../../../../components/create-order-panel/components/add-description-modal/add-description-modal.dialog";

const DailyObservationsDeliveryRoutePage = observer(() => {

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        selectFilterValue: null
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [targetDate, setTargetDate] = useState<any>(null);
    const uiStore = useStore('uiStore');

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)
                    let fetchData = await getDailyObservationsDeliveryRoutes({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        deliveryRouteId: filtersTable.selectFilterValue?.value,
                        targetDate: isEmpty(targetDate) ? null : formatDate(targetDate)
                    });
                    setData(() => fetchData)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error?.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
            
        },
        [filtersTable, refreshData]
    )

    useEffect(
        () => {
            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error, true);
            })
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.items,
                headers: [
                   {
                        id: 'deliveryRouteName',
                        label: 'Ruta de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'observations',
                        label: 'Observații',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'targetDate',
                        label: 'Zi',
                        headerType: HeaderTypeEnum.Date,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Editează",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openModal({
                            title: `Editează observațiile pentru ruta: ${row?.deliveryRouteName}`,
                            component: (
                                <AddDescriptionModal
                                    description={row?.observations}
                                    setDescription={() => {}}
                                    action={async (value: string) => {
                                        
                                        if(isEmpty(value?.trim())) {
                                            displayCustomToast("Câmpul nu poate fi salvat daca este liber", true);
                                            return;
                                        }
                
                                        try {
                                            setIsLoading(() => true);
                                            await addDailyObservationDeliveryRoute({
                                                dailyObservationDeliveryRouteId: row?.id,
                                                deliveryRouteId: row?.deliveryRouteId,
                                                observations: value?.trim()
                                            });
                                            displayCustomToast("Detaliile au fost salvate!");
                                            setIsLoading(() => false);
                                            setRefreshData(true);
                                            uiStore.dismissModal();
                                            
                                        } catch (error: any) {
                                            setIsLoading(() => false);
                                            displayCustomToast(error, true);
                                        }
                                    }}
                                    placeholder="Observații pe ruta de livrare"
                                /> 
                            ),
                        })
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                observations: (row: any) => {
                    return (
                        <TooltipComponent title={row.observations ?? ''} />
                    )
                }
            }
        },
        []
    )

    return (
        <DailyObservationsDeliveryRoutePageStyled>
            <PageTemplateComponent 
                title="Observații zilnice - Rute de livrare"
            >
                <Box
                    width="100%"
                    padding="0.5rem"
                >
                    <DatePicker
                        value={targetDate}
                        renderInput={(params) => <TextFieldComponent 
                            {...params} 
                            fullWidth={true} 
                            variant="outlined" 
                            label="Selectează zi"
                        />}
                        onChange={newValue =>{ 
                            setTargetDate(() => newValue)
                            setRefreshData(() => true);
                        }}
                        mask="__.__.____"
                        
                    />
                </Box>
                <TableComponent
                    tableKey={TableTypeEnum.DailyObservations} 
                    viewType="normal"
                    tableData={tableData}
                    dropdownPlaceholder="Rute de livrare"
                    dropdownOptions={deliveryRoutes?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    // searchBarPlaceholder="Cauta dupa nume"
                    withoutSearchBar
                    withoutSelect
                    isLoading={isLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    actionItems={actionItems}
                /> 
            </PageTemplateComponent>
        </DailyObservationsDeliveryRoutePageStyled>
    )
})

export default DailyObservationsDeliveryRoutePage;