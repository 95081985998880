import { useEffect, useState } from "react";
import ButtonComponent from "../button/button.component";
import CategoryPanelStyled from "./category-panel.component.styled";
import { observer } from 'mobx-react-lite';
import TextFieldComponent from "../text-field/text-field.component";
import { isEmpty } from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { createCategoryCommand, updateCategoryCommand } from "../../utils/requests";
import { themeVariables } from "../../theme/variables";
import { displayCustomToast } from "../../utils/display-custom-toast";

export type CategoryPanelPropsTypes = {

    categoryId?: number,
    categoryDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        description: StateFieldType<string>;
        isActive?: StateFieldType<any>;

    };
    shouldDisplayError: boolean;
}

const CategoryPanel = observer(({
    categoryId,
    categoryDetails
}: CategoryPanelPropsTypes) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    // Editare
    useEffect(
        () => {
            if(categoryId === undefined) return;
            
            setFormGroup(
                () => ({
                    fields: {
                        name: {
                            value: categoryDetails?.name,
                            isValid: true,
                            noValidation: false
                        },
                        description: {
                            value: categoryDetails?.description,
                            isValid: true,
                            noValidation: true
                        },
                        isActive: {
                            value: categoryDetails?.isActive,
                            isValid: true,
                        }
                    },
                    shouldDisplayError: false
                })
            )
        },
        [categoryDetails, categoryId]
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createCateogryAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            description: formGroup.fields.description.value,
        }

        try {
            if(categoryId != undefined){
                let bodyUpdate = {
                    id: categoryId,
                    name: formGroup.fields.name.value,
                    description: formGroup.fields.description.value,
                    isActive: formGroup.fields.isActive?.value
                }
                await updateCategoryCommand(categoryId, bodyUpdate)
            }
            else
                await createCategoryCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <CategoryPanelStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="description">Descriere:</label>
                    <textarea
                        id="description"
                        className="text-area"
                        rows={3}
                        placeholder="Descriere"
                        value={formGroup.fields.description?.value ?? ""}
                        onChange={e => updateState('description', e.target.value)}
                    />
                </Grid>

                {
                    !(categoryId === undefined) ?
                        <Grid item xs={12}>
                            <FormControl
                                error={!formGroup.fields.isActive?.value}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formGroup.fields.isActive?.value}
                                        onChange={e => updateState('isActive', e.target.checked)}
                                    />}
                                    label="Activ"
                                />
                                {
                                    !formGroup.fields.isActive?.value ?
                                        <FormHelperText 
                                            sx={{ 
                                                fontSize: `${themeVariables.fontSizes.normal}`,
                                                marginLeft: 0
                                            }}>
                                                Ingredientul nu o sa mai apara in lista unui produs
                                            </FormHelperText>
                                        :
                                        <></>
                                }
                            </FormControl>
                        </Grid>
                        :
                        <></>
                }

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createCateogryAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </CategoryPanelStyled>
    )

    }
)

export default CategoryPanel;