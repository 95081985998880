export enum DictionaryTypeEnum {
    MeasurementUnitTypes = 1,
    OrderStatus = 2,
    MeasurementUnits = 3,
    Categories = 4,
    Items = 5,
    Ingredients = 6,
    IngredientMeasurementUnit = 7,
    ItemIngredients = 8,
    SalePoints = 9,
    DeliveryRoutes = 10,
    Users = 11,
    UserSalePoints = 12,
    AdminNewOrderPartners = 13,
    AdminNewOrderSalePoints = 14,
    Partners = 15,
    PartnerSalesPoints = 16,
}