import { observer } from "mobx-react-lite";
import PartnerPanelStyled from "./partner-panel.component.styled";
import { useEffect, useState } from "react";
import { StateFieldType } from "../../types/form.types";
import { isEmpty } from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { themeVariables } from "../../theme/variables";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { useStore } from "../../hooks/store.hook";
import ButtonComponent from "../button/button.component";
import { createPartnerCommand, updatePartnerCommand } from "../../utils/requests";


export type StateType = {
    fields: {
        name: StateFieldType<string>;
        vatNumber: StateFieldType<string>;
        regNumber: StateFieldType<string>;
        email: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        hasSpoilageOption: StateFieldType<boolean>;
        isActive?: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

type PartnerPanelPropsType = {
    partnerId?: number,
    partnerDetails?: any
}

const PartnerPanel = observer(({
    partnerId,
    partnerDetails
}: PartnerPanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            vatNumber: {
                value: "",
                isValid: false,
                noValidation: false
            },
            regNumber: {
                value: "",
                isValid: false,
                noValidation: false
            },
            email: {
                value: "",
                isValid: false,
                noValidation: false
            },
            phoneNumber: {
                value: "",
                isValid: false,
                noValidation: false
            },
            hasSpoilageOption: {
                value: false,
                isValid: true,
                noValidation: true
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    });
    const uiStore = useStore('uiStore');

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(
        () => {
            if(!partnerDetails) return;

            updateState('name', partnerDetails.name);
            updateState('vatNumber', partnerDetails.vatNumber);
            updateState('regNumber', partnerDetails.regNumber);
            updateState('email', partnerDetails.email);
            updateState('phoneNumber', partnerDetails.phoneNumber);
            updateState('hasSpoilageOption', partnerDetails.hasSpoilageOption);
            updateState('isActive', partnerDetails.isActive);
        },
        [partnerDetails]
    )

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            vatNumber: formGroup.fields.vatNumber.value,
            regNumber: formGroup.fields.regNumber.value,
            email: formGroup.fields.email.value,
            phoneNumber: formGroup.fields.phoneNumber.value,
            hasSpoilageOption: formGroup.fields.hasSpoilageOption.value
        }

        try {
            if(partnerId != undefined){
                let bodyUpdate = {
                    id: partnerId,
                    isActive: formGroup.fields.isActive?.value,
                    ...body
                }
                await updatePartnerCommand(partnerId, bodyUpdate)
            }
            else
                await createPartnerCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <PartnerPanelStyled>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Numar TVA"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.vatNumber?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.vatNumber?.isValid}
                        onTextChange={(value: any) => updateState('vatNumber', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Numar de inregistrare"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.regNumber?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.regNumber?.isValid}
                        onTextChange={(value: any) => updateState('regNumber', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Email"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.email?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.email?.isValid}
                        onTextChange={(value: any) => updateState('email', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Numar de telefon"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.phoneNumber?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.phoneNumber?.isValid}
                        onTextChange={(value: any) => updateState('phoneNumber', value)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={formGroup.fields.hasSpoilageOption?.value}
                            onChange={e => updateState('hasSpoilageOption', e.target.checked)}
                        />}
                        label="Permite rebuturi"
                    />
                </Grid>

                {
                    !(partnerId === undefined) ?
                        <Grid item xs={12}>
                            <FormControl
                                error={!formGroup.fields.isActive?.value}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formGroup.fields.isActive?.value}
                                        onChange={e => updateState('isActive', e.target.checked)}
                                    />}
                                    label="Activ"
                                />
                                {
                                    !formGroup.fields.isActive?.value ?
                                        <FormHelperText 
                                            sx={{ 
                                                fontSize: `${themeVariables.fontSizes.normal}`,
                                                marginLeft: 0
                                            }}>
                                                Partenerul nu o sa mai fie activ
                                            </FormHelperText>
                                        :
                                        <></>
                                }
                            </FormControl>
                        </Grid>
                        :
                        <></>
                }

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "1rem"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
                
            </Grid>
        </PartnerPanelStyled>
    )
})

export default PartnerPanel;