import { isEmpty } from "lodash";
import React, { useCallback } from "react";
import AvatarComponentStyled from "./avatar.component.styled";
import defaultImgSrc from '../../assets/images/default-profile.jpg'

export type AvatarComponentPropsType = {
    name: string;
    size?: string;
    clickable?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const AvatarComponent = ({
    name = '',
    size = '2.8rem',
    clickable,
    onClick = () => {}
}: AvatarComponentPropsType) => {

    const getInitialsFromName = useCallback(
        (): string => {
            return name.toUpperCase().split(' ').map(word => word[0]).join('');
        },
        [name]
    )


    /** define the return statement bellow */
    return (
        <AvatarComponentStyled 
            role='img' 
            aria-label={name} 
            style={{width: size, height: size}}
            clickable={clickable}
            onClick={(e) => clickable && onClick(e)}
        >
            {
                isEmpty(name) ?
                    <img className="profile-avatar-default-img" alt="profile default avatar" src={defaultImgSrc} /> :
                    getInitialsFromName()
            }
        </AvatarComponentStyled>
    )

}

export default AvatarComponent;