import { observer } from "mobx-react-lite";
import ProductionZonePageStyled from "./production-zone.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useEffect, useState } from "react";
import { getDailyProduction, processOrders } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { Box, Button, Container, Divider, Grid, LinearProgress, Link, Typography } from "@mui/material";
import { DailyProductionDto } from "./production-zone.page.types";
import { themeVariables } from "../../../../theme/variables";
import { useStore } from "../../../../hooks/store.hook";
import AddDoneQuantityModal from "../../../../components/add-done-quantity-modal/add-done-quantity-modal.component";
import { PanelType } from "../../../../enums/panel-type.enum";
import GetItemObservationsModal from "../../../../components/get-item-observations-modal/get-item-observations-modal.component";
import ButtonComponent from "../../../../components/button/button.component";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { formatDate } from "../../../../utils/utils";

const ProductionZonePage = observer(() => {

    const [dailyProductionItems, setDailyProductionItems] = useState<DailyProductionDto[]>([]);
    const [confirmedOrdersNo, setConfirmedOrdersNo] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const uiStore = useStore('uiStore');
    const [targetDate, setTargetDate] = useState<any>(new Date());

    useEffect(
        () => {
            setIsLoading(() => true);

            getDailyProduction({
                targetDate: formatDate(targetDate)
            })
            .then(data => {
                setDailyProductionItems(() => data.dailyProductionItems);
                setConfirmedOrdersNo(() => data.confirmedOrdersNo);
                setIsLoading(() => false)
            })
            .catch((error) => {
                displayCustomToast(error, true);
            })
        },
        []
    );
    
    useEffect(
        () => {
            if(!uiStore.refreshProductionZone) return;
            setIsLoading(() => true);

            getDailyProduction({
                targetDate: formatDate(targetDate)
            })
            .then(data => {
                setDailyProductionItems(() => data.dailyProductionItems);
                setConfirmedOrdersNo(() => data.confirmedOrdersNo);
                setIsLoading(() => false)
                uiStore.setRefreshProductionZone(false);
            })
            .catch((error) => {
                displayCustomToast(error, true);
            })
        },
        [uiStore.refreshProductionZone]
    );

    const addDoneQuantity = (item: DailyProductionDto, minusQuantity: boolean = false) => {
        uiStore.openModal({
            title: `Adauga cantitatea terminata pe produsul: ${item.itemName}`,
            component: (
                <AddDoneQuantityModal
                    dailyProductionId={item.id}
                    maxQuantity={minusQuantity ? item.doneQuantity : item.remainedQuantity}
                    minusQuantity={minusQuantity}
                />
            )
        })
    }

    const seeObservations = (item: DailyProductionDto) => {
        uiStore.openPanel({
            title: `Observatii pentru produsul: ${item.itemName}`,
            component: (
                <GetItemObservationsModal
                    itemId={item.itemId} 
                />
            ),
            key: PanelType.SeeItemObservations,
            panelWidth: "700px"
        })
    }

    const processOrdersAction = async () => {

        setIsLoading(() => true);
        try {
            await processOrders({
                targetDate: formatDate(targetDate)
            });
            setIsLoading(() => false);
            displayCustomToast("Comenzile au fost procesate cu succes");
            uiStore.setRefreshProductionZone(true);
            
        } catch (error: any) {
            setIsLoading(() => false);
            displayCustomToast(error, true);
        }
    }

    return (
        <ProductionZonePageStyled>
            <PageTemplateComponent 
                title="Zona de producție"
            >
                <Box
                    width="100%"
                    padding="0.5rem"
                >
                    <DatePicker
                        value={targetDate}
                        renderInput={(params) => <TextFieldComponent 
                            {...params} 
                            fullWidth={true} 
                            variant="outlined" 
                            label="Selectează zi"
                        />}
                        onChange={newValue =>{ 
                            setTargetDate(() => newValue)
                            uiStore.setRefreshProductionZone(true);
                        }}
                        mask="__.__.____"
                        
                    />
                </Box>
                {
                    isLoading ?
                        <LinearProgress style={{ borderRadius: "10px" }} />
                    :
                    <Box
                        marginTop="1rem"
                    >
                        <Typography
                            variant="h5"
                            fontWeight={themeVariables.fontWeights.bold}
                            marginBottom="1rem"
                        >
                            Cantități în curs de procesare:
                        </Typography>
                        <Grid 
                            container 
                            spacing={{ xs: 2, md: 3 }} 
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            marginBottom="1rem"
                        >
                            {
                                dailyProductionItems?.filter(f => f.remainedQuantity > 0)
                                .length === 0 ?
                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "2rem"
                                        }}
                                    >
                                        {
                                                confirmedOrdersNo === 0 ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "1rem"
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                    >
                                                        Nu există comenzi în curs de procesare
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "1rem"
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            variant="h5"
                                                            fontWeight={themeVariables.fontWeights.bold}
                                                        >
                                                            Nu mai sunt alte cantități în lucru. Pentru a continua trebuie să procesați comenzile:
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginTop: "2rem"
                                                            }}
                                                        >
                                                            <ButtonComponent 
                                                                onClick={processOrdersAction}
                                                            > 
                                                                Procesează comenzile
                                                            </ButtonComponent>
                                                        </Box>
                                                            
                                                    </Box>
                                                        
                                                </Box>
                                        }
                                    </Box>
                                    :
                                    dailyProductionItems
                                    .filter(f => f.remainedQuantity > 0)
                                    .map((item, index) => (
                                        <Grid 
                                            item 
                                            xs={6} 
                                            sm={3} 
                                            md={2} 
                                            key={index}
                                        >
                                            <Container
                                                className="item-card item-card-undone"
                                            >
                                                <Container
                                                    className="container-styled"
                                                    sx={{
                                                        marginBottom: "1rem",                                                        
                                                        marginTop: "0.5rem",
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={themeVariables.fontSizes.smaller}
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                        sx={{
                                                            textAlign: "start"
                                                        }}
                                                    >
                                                        {item.itemName}
                                                    </Typography>
                                                    <Container
                                                        sx={{
                                                            padding: "0 !important",
                                                            width: "fit-content",
                                                            margin: 0
                                                        }}          
                                                    >
                                                        <Container
                                                            sx={{
                                                                padding: "0 !important"
                                                            }}
                                                        >
                                                            <Typography
                                                                fontSize={themeVariables.fontSizes.smaller}
                                                            >
                                                                Cantitate: {item.remainedQuantity}
                                                            </Typography>
                                                        </Container>
                                                    </Container>
                                                </Container>

                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Button
                                                        startIcon={<InfoIcon fontSize="small" />}
                                                        onClick={() => {
                                                            if(!item.hasObservations) return;
                                                            seeObservations(item);
                                                        }}
                                                        sx={{
                                                            opacity: `${item.hasObservations ?  1 : 0}`,
                                                            cursor: `${item.hasObservations ? "pointer" : "default !important"}`,
                                                            marginBottom: "0.5rem",
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Observații
                                                    </Button>
                                                    <Button
                                                        startIcon={<AddIcon fontSize="small" />}
                                                        onClick={() => addDoneQuantity(item)}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                    >
                                                        Cantitate
                                                    </Button>
                                                </Box>
                                            </Container>
                                        </Grid>
                                    ))
                            }
                        </Grid>
                        <Divider
                            component="div" 
                            role="presentation"
                            className="divider-style"
                        />
                        <Typography
                            variant="h5"
                            fontWeight={themeVariables.fontWeights.bold}
                            marginBottom="1rem"
                        >
                            Cantități procesate:
                        </Typography>
                        <Grid 
                            container 
                            spacing={{ xs: 2, md: 3 }} 
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            marginTop="1rem"
                        >
                            {
                                dailyProductionItems
                                .filter(f => f.doneQuantity !=0)
                                .length === 0 ?
                                    <Container
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "1rem"
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            fontWeight={themeVariables.fontWeights.bold}
                                        >
                                            Nu există produse finalizate
                                        </Typography>
                                    </Container>
                                    :
                                    dailyProductionItems
                                    .filter(f => f.doneQuantity !=0)
                                    .map((item, index) => (
                                        <Grid 
                                            item 
                                            xs={6} 
                                            sm={3} 
                                            md={2} 
                                            key={index}
                                        >
                                            <Container
                                                className="item-card item-card-done"
                                            >
                                                <Container
                                                    className="container-styled"
                                                    sx={{
                                                        marginBottom: "1rem",
                                                        marginTop: "0.5rem",
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={themeVariables.fontSizes.smaller}
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                    >
                                                        {item.itemName}
                                                    </Typography>
                                                    <Container
                                                        sx={{
                                                            padding: "0 !important",
                                                            width: "fit-content",
                                                            margin: 0
                                                        }}          
                                                    >
                                                        <Container
                                                            sx={{
                                                                padding: "0 !important"
                                                            }}
                                                        >
                                                            <Typography
                                                                fontSize={themeVariables.fontSizes.smaller}
                                                            >
                                                                Cantitate: {item.doneQuantity}
                                                            </Typography>
                                                        </Container>
                                                    </Container>
                                                </Container>
                                                
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Button
                                                        startIcon={<InfoIcon fontSize="small" />}
                                                        onClick={(event) => {
                                                            if(!item.hasObservations) return;
                                                            seeObservations(item);
                                                        }}
                                                        sx={{
                                                            opacity: `${item.hasObservations ? 1 : 0}`,
                                                            cursor: `${item.hasObservations ? "pointer" : "default !important"}`,
                                                            marginBottom: "0.5rem",
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Observații
                                                    </Button>
                                                    <Button
                                                        startIcon={<AddIcon fontSize="small" />}
                                                        onClick={() => {
                                                            if(confirmedOrdersNo === 0) return;
                                                            addDoneQuantity(item, true)
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`,
                                                            opacity: `${confirmedOrdersNo ?? 0 > 0 ?  1 : 0}`,
                                                            cursor: `${confirmedOrdersNo ?? 0 > 0 ? "pointer" : "default !important"}`
                                                        }}
                                                    >
                                                        Cantitate
                                                    </Button>
                                                </Box>
                                            </Container>

                                        </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                }
            </PageTemplateComponent>
        </ProductionZonePageStyled>
    )
})

export default ProductionZonePage;