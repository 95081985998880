import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const NoDataComponentStyled = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1rem 4rem 1rem;

`;

export const ImgStyled = styled.img`

  width: 7rem; 
  height: 7rem;
`

export const FontStyled = styled.div`
    font-size: ${themeVariables.fontSizes.medium}; 
    font-weight: ${themeVariables.fontWeights.bold};
    padding-top: 1rem;
`

export default NoDataComponentStyled;